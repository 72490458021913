<template>

    <div data-cy="chat-full-name">

        <ChatInputComponent :component="component"
                            :active-step="activeStep"
                            :disabled="disabled"
                            :prevent-submit="preventSubmit"
                            :input-properties="inputProperties"
                            @toggle-tcpa-modal="toggleTcpaModal"
                            @submit="submit">

            <template #input-icon>

                <UserIcon/>

            </template>

        </ChatInputComponent>

    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType, ref } from 'vue'
    import { FullName } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import ChatInputComponent from './ChatInputComponent.vue'
    import UserIcon from '../icons/UserIcon.vue'
    import { ChatInputProperties } from '../../types/ChatComponents'

    export default defineComponent({
        name: 'ChatFullName',
        components: {
            ChatInputComponent,
            UserIcon,
        },
        props: {
            component: { type: Object as PropType<FullName>, required: true },
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            disabled: { type: Boolean, required: false },
            preventSubmit: { type: Boolean, required: false },
        },
        emits: [ 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            const inputProperties = ref<ChatInputProperties>({
                placeholder: props.component.data.inputPlaceholder,
                required: props.component.data.required,
                inputMode: 'text',
                name: 'full_name',
                autocomplete: 'full_name',
            })

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            function submit(value: string) {
                emit('submit', value)
            }

            return {
                inputProperties,
                submit,
                toggleTcpaModal,
            }

        },
    })
</script>

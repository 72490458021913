import { AudioPlayer } from './AudioPlayer'

export class AudioManager {

    constructor() {
        this.player = new AudioPlayer('audio')
    }

    /**
     * @param {?string} url
     */
    async autoplayStepAudio(url) {
        await this.player.autoplay(url)
    }

    /**
     * @param {?string} url
     * @param {boolean} loop
     */
    async playCompletedWebFormAudio(url, loop = false) {
        return this.player.stop().then(() => this.player.autoplay(url))
    }

    /**
     * @param {?string} url
     */
    primeCompletionAudio(url) {
        return this.playCompletedWebFormAudio(url, true)
    }

    destroyEventListeners() {
        this.player.destroyListeners()
    }

    pause() {
        this.player.pause()
    }

}

<template>

    <div class="flex flex-col">

        <div class="flex-shrink-0 rounded-t-xl overflow-hidden">
            <img class="h-48 w-full object-cover" :src="sbCdnUrl(blok.image.filename)" :alt="blok.image.alt">
        </div>

        <div class="rounded-b-xl p-6 bg-white h-full flex flex-col md:shadow-2xl">

            <div class="space-y-2 mb-4">

                <div class="text-sm leading-5 text-gray-500 flex items-center space-x-2">
                    <span v-if="computedPublishDate">{{ computedPublishDate }}</span>
                    <span v-if="computedPublishDate && computedReadTime"> | </span>
                    <span v-if="computedReadTime">{{ computedReadTime }} min read</span>
                </div>

                <div class="text-2xl leading-7 font-bold text-gray-900 line-clamp-2">
                    {{ blok.title }}
                </div>

                <div class="text-base leading-6 text-gray-500 line-clamp-3">
                    {{ blok.description }}
                </div>

            </div>

            <div v-if="blok.url.url" class="mt-auto">

                <a :href="blok.url.url"
                   target="_blank"
                   rel="noopener"
                   class="flex items-center space-x-3 mt-auto cursor-pointer"
                   :style="{ color: 'var(--primary-color)' }">

                    <span class="text-base leading-6 font-bold">Read More</span>

                    <svg class="mt-1"
                         width="28"
                         height="11"
                         viewBox="0 0 28 11"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <line x1="1"
                              y1="5.69983"
                              x2="25"
                              y2="5.69983"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"/>
                        <path d="M21.9819 1.5L26.0001 5.51818L21.9819 9.53636"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"/>
                    </svg>

                </a>

            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Asset } from '../../interfaces/Asset'
    import { Url } from '../../interfaces/Url'
    import { format } from 'date-fns'
    import { sbCdnUrl } from '../../../../../lib/Helpers'

    interface Block {
        image: Asset,
        title: string,
        description: string,
        url: Url,
        publishedAt: string,
        readTime: string,
    }

    export default defineComponent({
        name: 'BigMediaCard',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup(props) {

            const computedPublishDate = computed(() => props.blok.publishedAt?.length
                ? format(new Date(props.blok.publishedAt), 'MMM dd, yyyy')
                : null)

            const computedReadTime = computed(() => props.blok.readTime?.length && parseInt(props.blok.readTime) !== 0
                ? props.blok.readTime
                : null)

            return {
                computedPublishDate,
                computedReadTime,
                sbCdnUrl,
            }

        },
    })

</script>

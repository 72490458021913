<template>

    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <Text :data="data" :class="{ 'text-center': !data.alignment.length }" :content="blok.text"/>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import Text from '../../Dynamic/Components/Text.vue'

    interface DynamicTitleBlock {
        text: string,
        fontAlignment: string[],
        fontColor: {
            color: string,
        },
        fontWeight: string[],
        fontSize: string[],
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
    }

    export default defineComponent({
        name: 'DynamicTitleBlock',
        components: {
            Text,
        },
        props: {
            blok: { type: Object as PropType<DynamicTitleBlock>, required: true },
        },
        setup({ blok }) {

            return {
                data: computed(() => ({
                    size: blok.fontSize,
                    alignment: blok.fontAlignment,
                    weight: blok.fontWeight,
                    color: blok.fontColor.color,
                })),
            }

        },
    })

</script>

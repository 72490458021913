<template>

    <RingbaTagOverride :data="data"/>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { isFilled } from '../../../../lib/Helpers'
    import RingbaTagOverride from '../../Dynamic/Components/RingbaTagOverride.vue'

    interface DynamicRingbaTagOverrideBlock {
        tags: {
            keys: Record<string, string>[],
        },
    }

    export default defineComponent({
        name: 'DynamicRingbaTagOverrideBlock',
        components: {
            RingbaTagOverride,
        },
        props: {
            blok: { type: Object as PropType<DynamicRingbaTagOverrideBlock>, required: true },
        },
        setup({ blok }) {

            const tags = blok.tags.keys
                .filter((tag: Record<string, string>) => isFilled(tag.key))
                .map(({ key, value }) => ({ [ key ]: value }))

            return {
                data: {
                    tags: Object.assign({}, ...tags),
                },
            }

        },
    })

</script>

<template>

    <div v-editable="blok" class="py-10 md:py-20 bg-slate-100" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid>

            <div class="w-full space-y-4 md:space-y-4 md:text-center md:max-w-lg md:mx-auto">

                <h1 :class="[ blok.titleFontSize, blok.titleSpacingBottom ]"
                    :style="{ color: 'var(--primary-color)' }">
                    {{ blok.title }}
                </h1>

                <p class="text-base lg:text-xl leading-6 lg:leading-8 text-gray-500">
                    {{ blok.description }}
                </p>

            </div>

            <div class="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">

                <div v-for="cardBlok of blok.cards" :key="cardBlok._uid">

                    <StoryblokComponent :blok="cardBlok"/>

                </div>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import Grid from '../components/Grid.vue'
    import type { SbBlokData } from '@storyblok/js'
    import { FontSize } from '../interfaces/FontSize'

    interface Block {
        title: string,
        titleFontSize: FontSize,
        titleSpacingBottom: string[],
        description: string,
        backgroundColor: string,
        cards: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block3',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

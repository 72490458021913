<template>

    <div class="text-wrapper"
         :class="[ data.size, data.weight, data.alignment ]"
         :style="{ color: data.color }"
         @click.prevent="onClick($event)"
         v-html="text"/>

</template>

<script lang="ts">

    import { computed, ComputedRef, defineComponent, PropType } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { replaceTokens, smoothScrollTo } from '../../../../lib/Helpers'
    import { RouteKey } from '../../../../lib/enums/RouteKey'
    import { Ringba } from '../../../../lib/Ringba'
    import { FontType } from '../../../../types/Landing/FontAware'
    import { state } from '../../../../types/State'
    import { WebFormTemplate } from '../../../../lib/enums/WebFormTemplate'
    import { Action as ActionEnum } from '../Enums/Action'
    import { useAction } from './Compositions/useActions'
    import { ClickActionAware } from '../../../../types/Landing/ClickActionAware'

    export default defineComponent({
        name: 'Text',
        props: {
            data: { type: Object as PropType<FontType>, default: () => ({}) },
            content: { type: String, required: true },
        },
        setup(props) {

            const router = useRouter()
            const route = useRoute()
            const phoneNumber = new Ringba().getPhoneNumber()

            const text = computed(() => replaceTokens(props.content, {
                ringbaPhone: phoneNumber.dashed,
            }))

            const routerLinkTo = computed(() => {

                if (state.webForm.template === WebFormTemplate.Chat) {

                    return {
                        name: RouteKey.Chat,
                        params: route.params,
                        query: route.query,
                    }

                }

                return {
                    name: RouteKey.WebForm,
                    params: { ...route.params, step: 1 },
                    query: route.query,
                }

            })

            function onClick({ target }: MouseEvent) {

                if (target instanceof HTMLAnchorElement) {

                    if (target.hasAttribute('action')) {

                        const action = resolveAction(target)

                        if (action.value) {
                            useAction(action.value).run()
                        }

                    }

                    if (target.href.endsWith('#start-web-form')) {

                        smoothScrollTo()

                        return router.replace(routerLinkTo.value)

                    }

                    if (target.href.endsWith('#ringba')) {

                        phoneNumber.onClick()

                        return window.open(phoneNumber.link, '_self')

                    }

                    window.open(target.href, '_blank')

                }

            }

            function resolveAction(target: HTMLAnchorElement): ComputedRef<ClickActionAware | null> {

                return computed(() => {

                    const actionType = target.getAttribute('action')

                    if (actionType === ActionEnum.TriggerDialer) {

                        return {
                            type: ActionEnum.TriggerDialer,
                            data: {
                                phoneNumber: target.getAttribute('phone'),
                            },
                        } as ClickActionAware

                    }

                    return null

                })

            }

            return {
                onClick,
                text,
            }

        },
    })

</script>

<style lang="scss">

    .text-wrapper {

        a,
        span.dynamic-paragraph {

            @apply font-bold text-blue-600 underline;

            &:hover {
                @apply text-blue-500;
            }

        }

    }

</style>

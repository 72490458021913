<template>

    <div class="multiple-steps py-8 md:py-16 mt-8 md:mt-0 w-full" :style="{ '--accent-color': data.accentColor }" data-cy="multiple-steps-ty">

        <TransitionLeftToRight>

            <div v-if="selectedOption === data.button1Value">

                <WebFormCardWithProgress :content="{ header: data.button1BoxTitle }">

                    <div class="mb-2 w-full max-w-md">

                        <div class="mb-6 font-medium text-center">

                            <h1 class="text-2xl my-1">
                                <PhoneAware :text="data.button1Header"/>
                            </h1>

                        </div>

                        <ul class="space-y-8">

                            <li v-for="({ data: { icon, title } }, index) of data.steps" :key="index" class="relative">

                                <div class="flex items-center">

                                    <div class="icon flex items-center min-w-[35px]" v-html="icon"/>

                                    <div class="ml-4 min-w-0 flex flex-col">

                                        <div class="tracking-wide font-medium text-base">
                                            <PhoneAware :text="title"/>
                                        </div>

                                    </div>

                                </div>

                            </li>

                        </ul>

                    </div>

                </WebFormCardWithProgress>

            </div>

            <Layout v-if="selectedOption === data.button2Value">
                <MediaAlpha :data="data.mediaAlpha"/>
            </Layout>

            <Layout v-if="selectedOption === undefined">

                <WebFormCardWithProgress :content="{ header: data.boxTitle }">

                    <div class="w-full max-w-md">

                        <div class="mb-6 font-medium text-center">

                            <h1 class="text-2xl my-1">
                                <PhoneAware :text="data.header"/>
                            </h1>

                        </div>

                        <StepButtonGroup ref="buttonReference" :component="button" @progress="onProgress"/>

                    </div>

                </WebFormCardWithProgress>

            </Layout>

        </TransitionLeftToRight>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType, ref } from 'vue'
    import Layout from '../../components/layout/Layout.vue'
    import WebFormCardWithProgress from '../../components/web_forms/CardWithProgress.vue'
    import StepButtonGroup from '../steps/StepButtonGroup.vue'
    import { MultipleStepTemplate } from '../../types/resources/ThankYouPageResource'
    import { Api } from '../../lib/Api'
    import { state } from '../../types/State'
    import MediaAlpha from './MediaAlpha.vue'
    import { minimum } from '../../lib/Helpers'
    import TransitionLeftToRight from '../../components/transitions/TransitionLeftToRight.vue'
    import { useRoute } from 'vue-router'
    import PhoneAware from '../format/PhoneAware.vue'

    export default defineComponent({
        name: 'MultipleSteps',
        components: { PhoneAware, TransitionLeftToRight, MediaAlpha, StepButtonGroup, Layout, WebFormCardWithProgress },
        props: {
            data: { type: Object as PropType<MultipleStepTemplate['data']>, required: true },
        },
        setup({ data }) {

            const route = useRoute()

            route.meta.center.value = false

            const buttonReference = ref<InstanceType<typeof StepButtonGroup>>()
            const selectedOption = ref<string>()

            async function onProgress() {

                const formData: Record<string, unknown> = {}
                const instance = buttonReference.value
                const api = new Api()
                const visitorId = state.visitorId

                if (instance) {

                    await instance.fill(formData)

                    await minimum(
                        api.updateLeadData(visitorId, formData), 1000,
                    )

                    selectedOption.value = formData[ data.buttonAttribute ] as string

                }

            }

            return {
                selectedOption,
                buttonReference,
                onProgress,
                button: {
                    type: 'button_group',
                    data: {
                        name: data.buttonAttribute,
                        label: data.buttonAttribute,
                        vertical: false,
                        options: [
                            { label: data.button1Text, value: data.button1Value },
                            { label: data.button2Text, value: data.button2Value },
                        ],
                    },
                },
            }

        },
    })

</script>

<style lang="scss">

    .multiple-steps {

        .icon {
            min-height: 35px;
            color: var(--accent-color);

            svg {
                width: 35px;
                height: 35px;
            }
        }

        strong, a.primary-text {
            color: var(--accent-color);
        }

    }

</style>

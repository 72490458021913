import Landing from './pages/Landing.vue'
import WebForm from './pages/WebForm.vue'
import Loading from './pages/Loading.vue'
import ThankYou from './pages/ThankYou.vue'
import Chat from './pages/Chat.vue'
import { RouteRecordRaw } from 'vue-router'
import { RouteKey } from './lib/enums/RouteKey'
import { ref } from 'vue'

const hasLandingPage = !!window.__STATE.webForm.landingPage

export const routes: RouteRecordRaw[] = [
    {
        name: RouteKey.Chat,
        path: '/:slug/chat',
        component: Chat,
        meta: {
            center: ref(false),
            useBanner: true,
            isChat: true,
        },
    },
    {
        name: RouteKey.LandingPreview,
        path: '/old/landing-page-preview/:slug',
        component: Landing,
        meta: {
            center: ref(false),
            useBanner: true,
        },
    },
    {
        name: RouteKey.ThankYouPreview,
        path: '/old/thank-you-page-preview/:slug',
        component: ThankYou,
        meta: {
            useBanner: false,
            center: ref(true),
        },
    },
    {
        name: RouteKey.Landing,
        path: '/:link?/:slug',
        // @todo deprecate 'links' match once it's out of rotation
        alias: [ '/:link(links|campaigns|es)/:slug' ],
        component: hasLandingPage ? Landing : WebForm,
        meta: {
            center: ref(false),
            useBanner: true,
        },
    },
    {
        name: RouteKey.InlineWebForm,
        path: '/:link?/:slug/step/:step(\\d+)',
        component: hasLandingPage ? Landing : WebForm,
        meta: {
            center: ref(false),
            useBanner: true,
        },
    },
    {
        name: RouteKey.WebForm,
        // @todo deprecate 'links' match once it's out of rotation
        path: '/:link(links|campaigns)?/:slug/:step(\\d+)',
        component: WebForm,
        meta: {
            center: ref(false),
            useBanner: true,
        },
    },
    {
        name: RouteKey.Loading,
        // @todo deprecate 'links' match once it's out of rotation
        path: '/:link(links|campaigns)?/:slug/loading',
        component: Loading,
        meta: {
            center: ref(true),
            useBanner: false,
        },
    },
    {
        name: RouteKey.ThankYou,
        // @todo deprecate 'links' match once it's out of rotation
        path: '/:link(links|campaigns)?/:slug/thank-you',
        component: ThankYou,
        meta: {
            useBanner: false,
            center: ref(true),
        },
    },

]

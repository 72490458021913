<template>

    <div class="space-y-2 w-[360px]">

        <a :href="phoneNumber.link"
           class="w-full bg-red-500 rounded text-white font-medium py-5 flex justify-center items-center hover:scale-105 transition-transform will-change-transform"
           @click="phoneNumber.onClick">

            <svg width="20"
                 height="18"
                 viewBox="0 0 20 22"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 class="mr-4">
                <path d="M18.5912 14.586C17.3021 14.586 16.055 14.3507 14.8917 13.927C14.5249 13.7858 14.1162 13.8917 13.8332 14.2095L12.1879 16.5276C9.22202 14.939 6.44482 11.9384 4.96714 8.49063L7.01074 6.53729C7.2937 6.20781 7.37754 5.7489 7.26226 5.33705C6.8745 4.0309 6.67538 2.63061 6.67538 1.18325C6.67538 0.547831 6.20378 0.0183105 5.63786 0.0183105H2.01177C1.44585 0.0183105 0.764648 0.300721 0.764648 1.18325C0.764648 12.1149 8.86571 21.1991 18.5912 21.1991C19.3352 21.1991 19.6287 20.4578 19.6287 19.8106V15.7509C19.6287 15.1155 19.1571 14.586 18.5912 14.586Z"
                      fill="white"/>
            </svg>

            <span class="text-xl leading-7 font-semibold">{{ phoneNumber.dashed }} (TTY {{ phoneNumber.tty }})</span>

        </a>

        <div v-if="blok.text" class="flex items-center justify-center leading-7">

            <div class="w-2 h-2 rounded mr-2 mt-1" :style="{ backgroundColor: blok.color }"/>

            <div class="text-base font-medium" :style="{ color: blok.color }">
                {{ blok.text }}
            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Ringba } from '../../../../../lib/Ringba'

    interface Block {
        text: string,
        color: string,
    }

    export default defineComponent({
        name: 'RingbaButton',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup() {

            const phoneNumber = new Ringba().getPhoneNumber()

            return {
                phoneNumber,
            }

        },
    })

</script>

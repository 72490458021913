<template>

    <div v-if="error"
         class="text-red-500 bg-red-50 py-2 text-center">
        {{ error }}
    </div>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'ChatError',
        props: {
            error: {
                type: String,
                required: false,
                default: '',
            },
        },
    })

</script>

declare global {
    interface Window {
        LeadiD: _Jornaya,
    }

    interface _Jornaya {
        token: string,
    }
}

export class Jornaya {

    public createScript(): void {

        const existingScripts = document.getElementsByTagName('script')[ 0 ]
        const LeadiDscript: HTMLScriptElement = document.createElement('script')

        LeadiDscript.id = 'LeadiDscript'
        LeadiDscript.type = 'text/javascript'
        LeadiDscript.async = true
        LeadiDscript.src = '//create.lidstatic.com/campaign/de50081f-de27-40d5-bb9f-2b27fc601d18.js?snippet_version=2'

        if (existingScripts.parentNode) {
            existingScripts.parentNode.insertBefore(LeadiDscript, existingScripts)
        } else {

            // Fallback to appending to the body
            document.body.appendChild(LeadiDscript)

        }

    }

    public getLeadiDToken(): string | undefined {

        const element: HTMLInputElement | null = document.querySelector('#leadid_token') ||
            document.querySelector('[name="universal_leadid"]')

        if (element && element.value) {
            return element.value
        }

        if (typeof window.LeadiD === 'object' && window.LeadiD.token) {
            return window.LeadiD.token
        }

        return undefined

    }

}

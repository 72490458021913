export function encrypt(plaintext: string, password: string): string {

    const v = new Array(2),
        k = new Array(4)

    let s = '',
        index

    plaintext = encodeURI(plaintext)

    for (index = 0; index < 4; index++) {
        k[ index ] = string4ToLong(password.slice(index * 4, (index + 1) * 4))
    }

    for (index = 0; index < plaintext.length; index += 8) {

        v[ 0 ] = string4ToLong(plaintext.slice(index, index + 4))
        v[ 1 ] = string4ToLong(plaintext.slice(index + 4, index + 8))
        code(v, k)
        s += longToString4(v[ 0 ]) + longToString4(v[ 1 ])

    }

    return escCtrlCh(s)

}

export function decrypt(ciphertext: string, password: string): string {

    const v = new Array(2),
        k = new Array(4)

    let s = '',
        index

    for (index = 0; index < 4; index++) {
        k[ index ] = string4ToLong(password.slice(index * 4, (index + 1) * 4))
    }

    ciphertext = unescCtrlCh(ciphertext)

    for (index = 0; index < ciphertext.length; index += 8) {

        v[ 0 ] = string4ToLong(ciphertext.slice(index, index + 4))
        v[ 1 ] = string4ToLong(ciphertext.slice(index + 4, index + 8))
        decode(v, k)
        s += longToString4(v[ 0 ]) + longToString4(v[ 1 ])

    }

    s = s.replace(/\0+$/, '')

    try {
        return decodeURI(s)
    } catch (error) {
        return ''
    }

}

function code(v: string[], k: string[]): void {

    let y = v[ 0 ],
        z = v[ 1 ],
        sum = 0

    const delta = 0x9E3779B9,
        limit = delta * 32

    while (sum !== limit) {

        y += (z << 4 ^ z >>> 5) + z ^ sum + k[ sum & 3 ]
        sum += delta
        z += (y << 4 ^ y >>> 5) + y ^ sum + k[ sum >>> 11 & 3 ]

    }

    v[ 0 ] = y
    v[ 1 ] = z

}

function decode(v: string[], k: string[]): void {

    const delta = 0x9E3779B9

    let y = v[ 0 ],
        z = v[ 1 ],
        sum = delta * 32

    while (sum !== 0) {

        z -= (y << 4 ^ y >>> 5) + y ^ sum + k[ sum >>> 11 & 3 ]
        sum -= delta
        y -= (z << 4 ^ z >>> 5) + z ^ sum + k[ sum & 3 ]

    }

    v[ 0 ] = y
    v[ 1 ] = z

}

function string4ToLong(s: string): number {

    let v = 0,
        index

    for (index = 0; index < 4; index++) {
        v |= s.charCodeAt(index) << index * 8
    }

    return isNaN(v) ? 0 : v

}

function longToString4(v: number): string {
    return String.fromCharCode(v & 0xFF, v >> 8 & 0xFF, v >> 16 & 0xFF, v >> 24 & 0xFF)
}

function escCtrlCh(string: string): string {
    return string.replace(/[\0\t\n\v\f\r\xa0'"!]/g, c => `!${ c.charCodeAt(0) }!`)
}

function unescCtrlCh(string: string): string {
    return string.replace(/!\d\d?\d?!/g, c => String.fromCharCode(c.slice(1, -1)))
}

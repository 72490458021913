<template>

    <div class="w-1/1 sm:w-1/2 lg:w-1/3 px-3">

        <div class="px-5 py-4 md:py-6 bg-slate-100 rounded-xl items-left md:flex-col md:text-center md:space-y-2 h-full ">

            <div v-if="blok.image"
                 class="w-20 h-20 bg-white rounded-full flex items-center justify-center p-0">
                <img :src="sbCdnUrl(blok.image.filename)" :alt="blok.image.alt" class="w-12 h-12 object-cover ">
            </div>

            <div class="md:space-y-2 pt-4">

                <div class="text-lg md:text-xl leading-7 font-semibold tracking-wide text-gray-900 text-left">
                    {{ blok.title }}
                </div>

                <div class="text-normal md:text-xl leading-11 text-gray-800 text-left pt-4">
                    {{ blok.description }}
                </div>

            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Asset } from '../../interfaces/Asset'
    import { sbCdnUrl } from '../../../../../lib/Helpers'

    interface Block {
        title: string,
        description: string,
        image: Asset,
    }

    export default defineComponent({
        name: 'Card3',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup() {

            return {
                sbCdnUrl,
            }

        },
    })

</script>

<template>

    <div class="relative z-10 flex items-center justify-center rounded-full transition-all duration-200"
         :class="[
             width.circle,
             {
                 'primary-bg': isComplete,
                 'bg-white border-2 border-gray-300 delay-300': isIncomplete,
                 'border-2 primary-border bg-white': isActive,
             },
         ]">

        <div v-if="icon && isActive" class="w-full h-full border-2 primary-border rounded-full animate-ping"/>

        <div class="text-white absolute"
             :class="[
                 width.icon,
                 {
                     'text-white': isComplete,
                     'primary-text': isActive,
                     'text-gray-300': isIncomplete,
                 },
             ]"
             v-html="icon"/>

        <div v-if="!icon && (isActive || isIncomplete)" class="w-2.5 h-2.5 relative">

            <div class="h-2.5 w-2.5 rounded-full absolute transition-colors delay-300 duration-200"
                 :class="{ 'primary-bg': isActive }"
                 aria-hidden="true"/>

            <div class="h-2.5 w-2.5 rounded-full absolute animate-ping transition-colors delay-300 duration-200"
                 :class="{ 'primary-bg': isActive }"
                 aria-hidden="true"/>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { FlowCircleSize, FlowCircleState } from '../../lib/enums/Flow'

    const sizes: Record<FlowCircleSize, Record<string, string>> = {
        [ FlowCircleSize.Base ]: {
            circle: 'w-8 h-8',
            icon: 'w-4 h-4',
        },
        [ FlowCircleSize.Large ]: {
            circle: 'w-10 h-10',
            icon: 'w-6 h-6',
        },
    }

    export default defineComponent({
        name: 'FlowCircle',
        props: {
            size: { type: String as PropType<FlowCircleSize>, default: FlowCircleSize.Base },
            state: { type: String as PropType<FlowCircleState>, default: null },
            icon: { type: String, default: null },
        },
        setup(props) {

            const width = computed<Record<string, string>>(() => sizes[ props.size ])
            const isComplete = computed<boolean>(() => props.state === FlowCircleState.Complete)
            const isIncomplete = computed<boolean>(() => !props.state || props.state === FlowCircleState.Incomplete)
            const isActive = computed<boolean>(() => props.state === FlowCircleState.Active)

            return {
                width,
                isComplete,
                isIncomplete,
                isActive,
            }

        },
    })

</script>

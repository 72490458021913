export enum ApiCode {

    /**
     * Success
     */
    Success = 200,
    Created = 201,
    NoContent = 204,

    /**
     * Errors
     */
    NotFound = 404,
    Validation = 412,
    Unauthorized = 401,
    ServerError = 500,

    /**
     * Custom
     */
    HasMoreSteps = 2000,
    NoMoreGroups = 2001,
    AppendGroup = 2003,
}

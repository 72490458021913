export enum LandingDynamicComponent {
    Title = 'title',
    Paragraph = 'paragraph',
    Image = 'image',
    Button = 'button',
    ButtonGroup = 'button_group',
    Vimeo = 'vimeo',
    Highlight = 'highlight',
    ClickToCall = 'click_to_call',
    RingbaTagOverride = 'ringba_tag_override',
    InlineWebForm = 'inline_web_form',
}

<template>

    <div v-editable="blok" class="py-10 lg:py-20" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid>

            <div class="space-y-6 md:flex md:space-x-8 md:space-y-0 justify-between">

                <div class="flex-1">

                    <div v-if="blok.links?.length" class="flex flex-col space-y-4 items-center md:flex-row md:space-x-24 md:space-y-0">

                        <StoryblokComponent v-for="link of blok.links"
                                            :key="link._uid"
                                            :blok="link"/>

                    </div>

                </div>

                <div class="hidden flex-1 text-right md:block">

                    <span v-if="blok.copyrightNotes?.length" class="text-gray-500">
                        {{ blok.copyrightNotes }}
                    </span>

                </div>

            </div>

            <div v-if="blok.footerText?.length" class="mt-6 md:mt-12 md:pt-8 md:border-t md:border-gray-300">

                <span class="text-sm text-gray-500 md:text-base md:text-gray-400 md:leading-8">
                    {{ blok.footerText }}
                </span>

            </div>

        </Grid>

        <div v-if="blok.copyrightNotes?.length" class="md:hidden text-center bg-gray-200 py-8 px-6 mt-6 -mb-10">

            <span class="text-gray-500">
                {{ blok.copyrightNotes }}
            </span>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import type { SbBlokData } from '@storyblok/js'
    import Grid from '../components/Grid.vue'

    interface Block {
        backgroundColor: string,
        links: SbBlokData[],
        copyrightNotes?: string,
        footerText?: string,
    }

    export default defineComponent({
        name: 'Block8',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

<template>

    <div v-if="!isPast && timerEndDate" class="mt-8 max-w-md mx-auto">

        <h1 v-if="blok.timerTitle" class="uppercase tracking-widest text-gray-800 text-lg font-semibold text-center">
            {{ blok.timerTitle }}
        </h1>

        <div class="border-t border-gray-300 my-2"/>

        <FormatCountDown :date="timerEndDate"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { TimerComponent } from '../../../../types/Landing/Components'
    import FormatCountDown from '../../../format/FormatCountDown.vue'
    import { TimerType } from '../../../../lib/enums/TimerType'
    import { addMinutes, isPast, parseISO } from 'date-fns'

    export default defineComponent({
        name: 'DynamicTimerBlock',
        components: { FormatCountDown },
        props: {
            blok: { type: Object as PropType<TimerComponent>, required: true },
        },
        setup({ blok }) {

            let timerEndDate: Date | null = null

            if (blok.timerType === TimerType.Fixed) {
                timerEndDate = parseISO(blok.timerEndDate)
            }

            if (blok.timerType === TimerType.Recurring) {
                timerEndDate = addMinutes(new Date(), blok.timerDuration)
            }

            return {
                timerEndDate,
                isPast: timerEndDate ? isPast(timerEndDate) : null,
            }

        },
    })

</script>

<template>

    <div class="flex justify-center items-center">

        <Component :is="components[ landingPage.template ]" v-if="landingPage" :data="landingPage.data"/>

        <div v-else class="bg-white rounded-lg border p-8 text-4xl text-3xl">
            Landing page hasn't been setup yet.
        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { state } from '../types/State'
    import Standard from '../components/landing/Standard.vue'
    import ClassicC2C from '../components/landing/ClassicC2C.vue'
    import Dynamic from '../components/landing/Dynamic.vue'
    import { LandingPageTemplate } from '../lib/enums/LandingPageTemplate'
    import StoryBlok from '../components/landing/StoryBlok.vue'

    type Components = typeof Standard | typeof ClassicC2C | typeof Dynamic | typeof StoryBlok

    const components: Record<LandingPageTemplate, Components> = {
        [ LandingPageTemplate.Standard ]: Standard,
        [ LandingPageTemplate.ClassicC2C ]: ClassicC2C,
        [ LandingPageTemplate.Dynamic ]: Dynamic,
        [ LandingPageTemplate.StoryBlok ]: StoryBlok,
    }

    export default defineComponent({
        name: 'Landing',
        setup() {

            return {
                components,
                landingPage: state.webForm.landingPage,
            }

        },
    })

</script>

<template>

    <div class="flex flex-col space-y-4 max-w-sm mx-auto mb-4">

        <PushButtonSpinner v-for="option of component.data.options"
                           :key="option.label"
                           :theme="option.theme ? option.theme : undefined"
                           :size="option.size ? option.size : undefined"
                           :font-size="option.fontSize ? option.fontSize : undefined"
                           :state="getState(option.value)"
                           @click="setValue(option.value)">

            {{ option.label }}

        </PushButtonSpinner>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import PushButtonSpinner from '../buttons/PushButtonSpinner.vue'
    import { ButtonGroup } from '../../types/Components'
    import { PushButtonState } from '../../lib/enums/PushButton'
    import { restoreState } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepButtonGroup',
        components: { PushButtonSpinner },
        props: {
            component: { type: Object as PropType<ButtonGroup>, required: true },
        },
        emits: [ 'hide-button', 'progress' ],
        setup({ component }, { emit }) {

            const model = ref<string | null>()
            const loading = ref(false)

            emit('hide-button', true)
            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            function getState(option: string): PushButtonState {

                if (loading.value) {
                    return PushButtonState.Loading
                }

                if (option === model.value) {
                    return PushButtonState.Selected
                }

                return PushButtonState.Active

            }

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function isValid(): boolean {
                return true
            }

            function setValue(value: string) {

                loading.value = true
                model.value = value
                emit('progress')

            }

            return {
                PushButtonState,
                model,
                getState,
                fill,
                isValid,
                setValue,
            }

        },
    })

</script>

<template>

    <div data-cy="chat-phone-number">

        <ChatInputComponent :component="component"
                            :active-step="activeStep"
                            :disabled="disabled"
                            :prevent-submit="preventSubmit"
                            :input-properties="inputProperties"
                            @toggle-tcpa-modal="toggleTcpaModal"
                            @submit="submit">

            <template #input-icon>

                <PhoneIcon/>

            </template>

        </ChatInputComponent>

    </div>

</template>

<script lang="ts">
    import { computed, defineComponent, PropType } from 'vue'
    import { PhoneNumber } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import ChatInputComponent from './ChatInputComponent.vue'
    import PhoneIcon from '../icons/PhoneIcon.vue'
    import { ChatInputProperties } from '../../types/ChatComponents'

    export default defineComponent({
        name: 'ChatPhoneNumber',
        components: {
            ChatInputComponent,
            PhoneIcon,
        },
        props: {
            component: { type: Object as PropType<PhoneNumber>, required: true },
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            disabled: { type: Boolean, required: false },
            preventSubmit: { type: Boolean, required: false },
        },
        emits: [ 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            const inputProperties = computed<ChatInputProperties>(() => {

                const inputProperties: ChatInputProperties = {
                    name: 'phone',
                    required: props.component.data.required,
                    placeholder: props.component.data.inputPlaceholder,
                    mask: '(###) ###-####',
                    inputMode: 'tel',
                    autocomplete: 'tel',
                }

                if (props.component.data.useFreeFormText) {

                    inputProperties.mask = null
                    inputProperties.inputMode = 'text'

                } else {
                    inputProperties.placeholder = inputProperties.mask
                }

                return inputProperties

            })

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            function submit(value: string) {
                emit('submit', value)
            }

            return {
                inputProperties,
                submit,
                toggleTcpaModal,
            }

        },
    })
</script>

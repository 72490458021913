<template>
    <div v-show="loading" class="inline-block origin-bottom text-gray-400 my-auto">
        Typing
        <span class=" animate-flash">
            .
        </span>
        <span class=" animate-flash [animation-delay:0.2s]">
            .
        </span>
        <span class=" animate-flash [animation-delay:0.4s]">
            .
        </span>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'ChatLoading',
        props: {
            loading: { type: Boolean, required: true },
        },
    })
</script>

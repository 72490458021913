import { computed, Ref } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { Api } from './Api'
import { languageMap } from '../types/Languages'
import { renderRichText, ISbRichtext } from '@storyblok/vue'

export function isValidEmail(value: string): boolean {
    return /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/.test(value)
}

export function isBlank(value: unknown): boolean {

    if (value === undefined || value === null) {
        return true
    }

    if (Number.isNaN(value)) {
        return true
    }

    if (Array.isArray(value)) {
        return value.length === 0
    }

    if (typeof value === 'object') {
        return isBlank(Object.keys(value as object))
    }

    return value === '' || value === 'NaN' || value === '0'

}

export function isFilled(value: unknown): boolean {
    return !isBlank(value)
}

export function range(start: number, end: number): number[] {
    return Array.from({ length: (end - start) + 1 }, (value, key) => key + start)
}

export function delay(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export function isValidPhoneNumber(phone: string | undefined): boolean {

    if (isBlank(phone) || phone === undefined) {
        return false
    }

    return phone.length === 17

}

export function loadScript<T>(property: string, callback: (value: T) => void): void {

    if (property in window) {
        return callback(window[ property as 'document' ] as unknown as T)
    }

    const script = document.createElement('script')

    script.async = true
    script.defer = true
    script.src = '//insurance.mediaalpha.com/js/serve.js'
    script.onload = () => callback(window[ property as 'document' ] as unknown as T)
    document.body.appendChild(script)

}

export function replaceTokens(string: string, tokens: Record<string, unknown> = {}): string {

    for (const token in tokens) {
        string = string.replace(new RegExp(`{${ token }}`, 'g'), String(tokens[ token ]))
    }

    return string

}

/**
 * Get percentage between a max/min value
 */
export function getPercent(min: number, max: number): (value: number) => number {
    return (value: number) => 100 * (value - min) / (max - min)
}

export function smoothScrollTo(top = 0): void {

    try {
        window.scrollTo({ top, left: 0, behavior: 'smooth' })
    } catch {
        window.scrollTo(0, 0)
    }

}

export function responsiveValue<T extends string>(values: (string)[], defaultValue?: T) {

    const breakpoints = useBreakpoints(breakpointsTailwind)

    return computed<T | undefined>(() => {

        const mobile = values.find(size => !size.includes(':'))
        const medium = values.find(size => size.startsWith('md:'))
        const larger = values.find(size => size.startsWith('lg:'))

        if (breakpoints.lg.value && larger) {
            return larger.split(':')[ 1 ] as T
        }

        if (breakpoints.md.value && medium) {
            return medium.split(':')[ 1 ] as T
        }

        if (breakpoints.sm.value && mobile) {
            return mobile as T
        }

        return mobile as T || defaultValue

    })

}

export function wrap<T>(object: T): T[] {
    return Array.isArray(object) ? object : [ object ]
}

export function restoreState(model: Ref<unknown>, name: string | string[]) {

    const api = new Api()
    const consolidated = api.visitor.value as Record<string, unknown>

    const value = wrap(name).reduce<unknown>(
        (accumulated, current) => typeof accumulated === 'object' ? accumulated[ current as string ] : null,
        consolidated,
    ) ?? model.value

    model.value = typeof model.value === 'object' && model.value !== null
        ? Object.assign(model.value, value || {})
        : value

}

export function postMessage(event: string, data?: object) {
    window.parent.postMessage({ event, data }, '*')
}

export function toDecimalAge(date: Date): number {

    /*
     * In order to better approximate the decimal age we use an average of 365.25 days to account for leap years.
     * (3 * 365 + 366) / 4 = 365.25 (31557600000 milliseconds)
     */
    const averageYearlyMilliseconds = 31557600000
    const age = (Date.now() - date.getTime()) / averageYearlyMilliseconds

    /*
     * Since we are rounding to two decimal places, we will lose a bit of precision.
     * The resulting age may be off by +/- 2 days. This is fine because the value calculated here is
     * not used to calculate the exact Date of Birth.
     */
    return parseFloat(age.toFixed(2))

}

export function minimum<T>(promise: Promise<T>, milliseconds: number): Promise<T> {
    return Promise.all([ promise, delay(milliseconds) ]).then(([ first ]) => first)
}

export function isObject(item: object): boolean {
    return (item && typeof item === 'object')
}

export function mergeDeep(target: object, source: object): object {

    const output: object = Object.assign({}, target)

    for (const key in source) {

        if (isObject(source[ key ])) {

            !(key in target)
                ? Object.assign(output, { [ key ]: source[ key ] })
                : output[ key ] = mergeDeep(target[ key ], source[ key ])

        } else {
            Object.assign(output, { [ key ]: source[ key ] })
        }

    }

    return output

}

export function getLanguage(): string | null {

    const languageCode: string = navigator.language.slice(0, 2)

    return languageMap[ languageCode ] || null

}

export function getParametersByName(name: string, url: string = window.location.href): string | null {

    // The unnecessary escape characters are removed.
    name = name.replace(/[[\]]/g, '\\$&')

    const regex = new RegExp(`[?&]${ name }(=([^&#]*)|&|#|$)`),
        results: RegExpExecArray | null = regex.exec(url)

    if (!results) {
        return null
    }

    if (!results[ 2 ]) {
        return ''
    }

    return decodeURIComponent(results[ 2 ].replace(/\+/g, ' '))

}

export function cloneFormData(formData: Record<string, unknown>): Record<string, unknown> {

    const clonedFormData = Object.assign({}, formData)

    // Remove step so we don't send it to Third Parties
    delete clonedFormData.step

    return clonedFormData

}

export function sbCdnUrl(url: string): string {

    const cdnHostname = import.meta.env.VITE_SB_CDN_HOSTNAME || ''

    if (!cdnHostname.length) {
        return url
    }

    return url.replace('a.storyblok.com', cdnHostname)

}

export function renderSbRichText(content: ISbRichtext): string {

    const text = renderRichText(content)

    return sbCdnUrl(text)

}

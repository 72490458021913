import { isBlank, isFilled, smoothScrollTo } from '../../../../../lib/Helpers'
import { RouteKey } from '../../../../../lib/enums/RouteKey'
import { useRoute, useRouter } from 'vue-router'
import { ClickActionAware } from '../../../../../types/Landing/ClickActionAware'
import { Action } from '../../Enums/Action'
import { Ringba } from '../../../../../lib/Ringba'
import { state } from '../../../../../types/State'
import { WebFormTemplate } from '../../../../../lib/enums/WebFormTemplate'

export function useAction(action?: ClickActionAware) {

    const router = useRouter()
    const route = useRoute()

    function run() {

        if (action?.type && action.data.audioComponent instanceof HTMLAudioElement) {
            action.data.audioComponent.play()
        }

        if (action?.type === Action.ExternalLink) {
            window.open(action.data.url, '_blank')
        }

        if (action?.type === Action.StartWebForm) {

            smoothScrollTo()

            if (state.webForm.template === WebFormTemplate.Chat) {

                router.replace({
                    name: RouteKey.Chat,
                    params: route.params,
                    query: route.query,
                })

                return

            }

            router.replace({
                name: RouteKey.WebForm,
                params: { ...route.params, step: action.data.step },
                query: route.query,
            })

        }

        if (action?.type === Action.TriggerDialer) {

            if (isFilled(action.data.phoneNumber)) {
                window.open(`tel:${ action.data.phoneNumber }`, '_self')
            }

            if (isBlank(action.data.phoneNumber)) {

                const phoneNumber = new Ringba().getPhoneNumber()

                phoneNumber.onClick()
                window.open(phoneNumber.link, '_self')

            }

        }

    }

    return {
        run,
    }

}

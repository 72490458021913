<template>

    <div v-editable="blok"
         class="dynamic-page flex flex-col items-center w-full py-8"
         :class="[ `text-${ blok.headerFontFamily }` ]">

        <div class="flex flex-col items-center w-full" :class="mainViewportClass">

            <StoryblokComponent v-for="block of aboveFoldBlocks"
                                :key="block._uid"
                                :blok="block"/>

        </div>

        <StoryblokComponent v-for="block of belowFoldBlocks"
                            :key="block._uid"
                            :blok="block"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType, onMounted, computed } from 'vue'
    import { StoryblokComponent } from '@storyblok/vue'
    import { isStoryBlokLandingActive, state } from '../../../../types/State'
    import { FontFamily } from '../interfaces/FontSize'

    interface Block {
        headerFontFamily: FontFamily,
        blocks: typeof StoryblokComponent[],
    }

    export default defineComponent({
        name: 'DynamicPage',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup({ blok }) {

            /**
             * Partition the blocks into two groups, above the fold and below the fold based on the first occurrence
             * of `belowInitialViewport`
             */
            const result = computed(() => blok.blocks?.reduce((groups, item) => {

                if (item.component === 'belowInitialViewport') {

                    groups.breakOccurred = true

                    return groups

                }

                groups.breakOccurred
                    ? groups.belowFoldBlocks.push(item)
                    : groups.aboveFoldBlocks.push(item)

                return groups

            }, { breakOccurred: false, aboveFoldBlocks: [], belowFoldBlocks: [] }))

            const aboveFoldBlocks = computed(() => result.value?.aboveFoldBlocks || [])
            const belowFoldBlocks = computed(() => result.value?.belowFoldBlocks || [])
            const isHeaderLess = computed(() => state.webForm.content.headerless || state.domain.content.headerless)

            /**
             * The class to fill the initial viewport is contigent on whether the page is headerless or not
             */
            const mainViewportClass = computed(() => ({
                'fill-initial-viewport-with-header': !isHeaderLess.value,
                'fill-initial-viewport-no-header': isHeaderLess.value,
            }))

            onMounted(() => {
                isStoryBlokLandingActive.value = true
            })

            return {
                mainViewportClass,
                aboveFoldBlocks,
                belowFoldBlocks,
            }

        },

    })

</script>

<style lang="scss">

    $fonts: arial, verdana, tahoma, trebuchet-ms, times-new-roman, georgia, garamond, helvetica, calibri;

    @each $font in $fonts {

        .dynamic-page.text-font-#{$font} {

            * {
                @apply font-#{$font};
            }

        }

    }

</style>

<style scoped>
    .fill-initial-viewport-with-header{
        min-height: calc(100dvh - 6rem);
    }
    .fill-initial-viewport-no-header{
        min-height: 100dvh;
    }
</style>

<template>
    <div v-if="show"
         class="m-5 text-xs text-center text-gray-400"
         :class="forMobile ? 'sm:hidden' : 'hidden sm:block'">
        <label for="leadid_tcpa_disclosure">
            <input id="leadid_tcpa_disclosure" type="hidden">
            <span>
                By submitting your phone number, you agree to be contacted about Medicare Advantage Plans, and agree to our <span class="font-bold text-gray-700 underline"
                                                                                                                                  @click="toggleModal">TCPA Disclosure</span>
            </span>
        </label>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'ChatTcpaLabel',
        props: {
            show: { type: Boolean, required: true },
            forMobile: { type: Boolean, required: false },
        },
        emits: [ 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            function toggleModal(): void {
                emit('toggle-tcpa-modal')
            }

            return {
                toggleModal,
            }

        },
    })

</script>

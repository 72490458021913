<template>

    <Teleport to="#root">

        <div v-editable="blok"
             class="page"
             :class="[ `header-${ theme.headerFontFamily }` ]"
             :style="{ '--primary-color': theme.primaryColor }">

            <StoryblokComponent v-for="block of blok.blocks"
                                :key="block._uid"
                                :brand="blok.brand"
                                :theme="theme"
                                :blok="block"/>

        </div>

    </Teleport>

</template>

<script lang="ts">

    import { defineComponent, PropType, onMounted, computed } from 'vue'
    import { StoryblokComponent } from '@storyblok/vue'
    import { isStoryBlokLandingActive } from '../../../../types/State'
    import { BrandTheme } from '../interfaces/BrandTheme'
    import { PushButtonTheme } from '../../../../lib/enums/PushButton'

    interface Block {
        brand: string,
        blocks: typeof StoryblokComponent[],
    }

    export default defineComponent({
        name: 'BrandedPage',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup(props) {

            onMounted(() => {
                isStoryBlokLandingActive.value = true
            })

            function resolveThemeFromBrand(brand: string): BrandTheme {

                if (brand === 'humana') {

                    return {
                        backgroundColor: '#FFFFFF',
                        primaryColor: '#79BD47',
                        headerFontFamily: 'garamond',
                        name: 'humana',
                        buttonTheme: PushButtonTheme.RedDark,
                    }

                }

                return {
                    backgroundColor: '#FFFFFF',
                    primaryColor: '#000000', // <-- Can we infer from domain theme?
                    headerFontFamily: 'garamond',
                    name: 'default',
                }

            }

            const theme = computed<BrandTheme>(() => resolveThemeFromBrand(props.blok.brand))

            return {
                theme,
            }

        },
    })

</script>

<style lang="scss">

    main {
        padding: 0 !important;
    }

    $fonts: arial, verdana, tahoma, trebuchet-ms, times-new-roman, georgia, garamond, helvetica, calibri;

    @each $font in $fonts {

        .page.header-font-#{$font} {

            h1, h2, h3, h4, h5, h6 {
                @apply font-#{$font};
            }

        }

    }

</style>

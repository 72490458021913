<template>

    <div class="pt-24" data-cy="ma-ty">

        <h1 class="text-xl font-medium">
            <PhoneAware :text="data.header"/>
        </h1>

        <div :id="id" class="mt-6 pt-8 border-t border-gray-200">

            <div class="p-12 flex items-center justify-center">
                <Icon icon="gg:spinner-two" class="w-12 h-12 animate-spin primary-text"/>
            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import { Icon } from '@iconify/vue'
    import { loadScript } from '../../lib/Helpers'
    import { MediaAlphaTemplate } from '../../types/resources/ThankYouPageResource'
    import PhoneAware from '../format/PhoneAware.vue'
    import toSnakeCase from 'snakecase-keys'

    export default defineComponent({
        name: 'MediaAlpha',
        components: { PhoneAware, Icon },
        props: {
            data: { type: Object as PropType<MediaAlphaTemplate['data']>, required: true },
        },
        setup(props) {

            const id = ref(Math.random().toString(36).substring(2))

            onMounted(() => {

                loadScript<(id: string) => void>('MediaAlphaExchange__load', load => {

                    window.MediaAlphaExchange = toSnakeCase(props.data.tokens)
                    load(id.value)

                })

            })

            return {
                id,
            }

        },
    })

</script>

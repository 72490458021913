<template>

    <ModalBase @close="destroy">

        <div class="max-w-md mx-auto">

            <h1 class="text-lg text-center font-semibold">
                How can we help you?
            </h1>

            <p class="py-6 text-justify">
                Let's get you the answers you need. Just let us know how we can help and one of our friendly customer
                care specialists will get right back to you.
            </p>

            <div class="flex flex-col space-y-4">

                <Component :is="components[ component.type ]"
                           v-for="(component, index) of componentsData"
                           :key="index"
                           :ref="element => references[ component.data.name ] = element"
                           :component="component"
                           @progress="progress"/>

                <PushButton :theme="PushButtonTheme.Indigo"
                            class="self-end"
                            :class="{ 'bg-red-600 hover:bg-red-700 focus:ring-red-500': failed }"
                            @click="progress">

                    <Icon v-if="loading" icon="gg:spinner-two" class="w-6 h-6 animate-spin text-white mr-2.5"/>

                    <template v-if="loading">
                        Submitting...
                    </template>

                    <template v-else-if="failed">

                        <Icon icon="gg:danger" class="w-6 h-6 text-white mr-2.5"/>

                        Server Error

                    </template>

                    <template v-else-if="submitted">
                        Submitted
                    </template>

                    <template v-else>
                        Submit
                    </template>

                </PushButton>

            </div>

        </div>

    </ModalBase>

</template>

<script lang="ts">

    import { defineComponent, reactive, ref } from 'vue'
    import ModalBase from './ModalBase.vue'
    import PushButton from '../buttons/PushButton.vue'
    import { Icon } from '@iconify/vue'
    import { PushButtonTheme } from '../../lib/enums/PushButton'
    import { StepComponent } from '../../types/Components'
    import { InputComponent } from '../../lib/enums/components/InputComponent'
    import StepZipcode from '../steps/StepZipcode.vue'
    import StepTextInput from '../steps/StepTextInput.vue'
    import StepPhoneNumber from '../steps/StepPhoneNumber.vue'
    import StepSelect from '../steps/StepSelect.vue'
    import { Api } from '../../lib/Api'
    import { delay } from '../../lib/Helpers'
    import StepSmartyAddress from '../steps/StepSmartyAddress.vue'

    type Components = ((value: StepComponent) => Components | void)
        | typeof StepTextInput
        | typeof StepSelect
        | typeof StepSmartyAddress
        | typeof StepPhoneNumber

    const components: Partial<Record<InputComponent, Components>> = {
        [ InputComponent.Text ]: StepTextInput,
        [ InputComponent.Select ]: StepSelect,
        [ InputComponent.SmartyAddress ]: StepSmartyAddress,
        [ InputComponent.PhoneNumber ]: StepPhoneNumber,
    }

    export default defineComponent({
        name: 'ModalContactUs',
        components: { Icon, PushButton, ModalBase },
        emits: {
            close: () => true,
        },
        setup(props, { emit }) {

            const loading = ref(false)
            const submitted = ref(false)
            const failed = ref(false)
            const references: Record<string, InstanceType<typeof StepZipcode>> = reactive({})
            const api = new Api()

            const componentsData: StepComponent[] = [
                {
                    type: InputComponent.Select,
                    data: {
                        label: 'Reason for your enquiry',
                        placeholder: 'Select an option',
                        name: 'reason',
                        required: true,
                        options: [
                            { label: 'Do Not Contact', value: 'do_not_contact' },
                            { label: 'Customer Service Enquiry', value: 'customer_service_enquiry' },
                            { label: 'Business Enquiry', value: 'business_enquiry' },
                        ],
                    },
                },
                {
                    type: InputComponent.Text,
                    data: {
                        type: 'text',
                        placeholder: 'Subject',
                        label: 'Subject',
                        name: 'subject',
                        required: true,
                    },
                },
                {
                    type: InputComponent.Text,
                    data: {
                        type: 'text',
                        placeholder: 'First Name',
                        label: 'First Name',
                        name: 'firstName',
                        required: true,
                    },
                },
                {
                    type: InputComponent.Text,
                    data: {
                        type: 'text',
                        placeholder: 'Last Name',
                        label: 'Last Name',
                        name: 'lastName',
                        required: true,
                    },
                },
                {
                    type: InputComponent.Text,
                    data: {
                        type: 'email',
                        placeholder: 'Email',
                        label: 'Email',
                        name: 'email',
                        required: true,
                    },
                },
                {
                    type: InputComponent.PhoneNumber,
                    data: {
                        placeholder: 'Phone Number',
                        name: 'phoneNumber',
                        label: 'Phone Number',
                        required: false,
                    },
                },
                {
                    type: InputComponent.SmartyAddress,
                    data: {
                        placeholder: 'Address',
                        name: 'address',
                        label: 'Address',
                        required: false,
                    },
                },
                {
                    type: InputComponent.Text,
                    data: {
                        type: 'textarea',
                        placeholder: 'Comments or Questions',
                        label: 'Comments or Questions',
                        name: 'message',
                        required: true,
                    },
                },
            ]

            async function progress(): Promise<void> {

                loading.value = true

                const formData: Record<string, unknown> = {}

                for (const key in references) {

                    const instance = references[ key ]

                    if (instance.isValid() === false || await instance.fill(formData) === false) {

                        loading.value = false

                        if ('focus' in instance) {
                            instance.focus()
                        }

                        return

                    }

                }

                const response = await api.contactUs(formData)

                if (response.isErr()) {
                    failed.value = true
                } else {

                    submitted.value = true
                    delay(2000).then(destroy)

                }

            }

            function destroy(): void {
                emit('close')
            }

            async function submit(): Promise<void> {

                if (loading.value && submitted.value) {
                    return
                }

                loading.value = true
                failed.value = false
                loading.value = false

            }

            return {
                PushButtonTheme,
                componentsData,
                components,
                references,
                loading,
                submitted,
                failed,
                destroy,
                submit,
                progress,
            }

        },
    })

</script>

<template>

    <div class="flex flex-col space-y-4 w-full">

        <FormInputComponent ref="inputReference"
                            v-model="model"
                            v-maska="'#####'"
                            :component="component"
                            :extra-attributes="{
                                type: 'number',
                                min: '00501',
                                max: '99999',
                                inputmode: 'numeric',
                                pattern: '[0-9]*',
                            }"/>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
    import { Api } from '../../lib/Api'
    import { Zipcode } from '../../types/Components'
    import { maska } from 'maska'
    import FormInputComponent from '../form/FormInputComponent.vue'

    export default defineComponent({
        name: 'StepZipcode',
        components: { FormInputComponent },
        directives: { maska },
        props: {
            component: { type: Object as PropType<Zipcode>, required: true },
        },
        setup({ component }) {

            const api = new Api()
            const inputReference = ref<HTMLInputElement>()
            const model = ref<string>()
            const correctedZipcode = computed<string | undefined>(() => model.value?.toString().padStart(5, '0'))

            onMounted(() => {

                const { address = null, addressInput = null, smartyAddress = null } = api.visitor.value

                if (smartyAddress || addressInput || address) {
                    model.value = smartyAddress?.components?.zipcode ?? addressInput?.zipcode ?? address?.zip
                }

            })

            async function fill(formData: Record<string, unknown>): Promise<void | boolean> {

                if (model.value && correctedZipcode.value) {

                    const response = await api.getZipcode(correctedZipcode.value)

                    if (response.isErr()) {
                        return false
                    }

                    if (response.isOk()) {

                        const { data } = response.value

                        formData[ component.data.name ] = {
                            zip: data.code,
                            city: data.city,
                            state: data.state,
                        }

                        return true

                    }

                }

            }

            function focus(): void {
                inputReference.value?.input?.focus()
            }

            function isValid(): boolean {

                if (model.value) {
                    return model.value.toString().length <= 5
                }

                return component.data.required === false

            }

            return {
                inputReference,
                model,
                focus,
                fill,
                isValid,
            }

        },
    })

</script>

<template>

    <TransitionScaleIn appear>

        <a :href="phoneNumber.link"
           class="text-white shadow-lg relative sm:max-w-lg w-full flex"
           :class="{ 'sm:self-end': !isLegacyChat }"
           @click="phoneNumber.onClick">

            <div class="w-full flex items-center gap-4 justify-center py-3 px-8 bg-[#DC2626] sm:bg-[#DC2647] rounded-lg"
                 :style="{ backgroundColor: backgroundColor }">

                <Icon icon="mdi:phone-in-talk" class="w-12 h-12"/>

                <div class="flex flex-col items-center justify-center whitespace-nowrap">

                    <div class="tracking-widest font-semibold opacity-60 uppercase" v-html="text"/>

                    <span class="text-lg font-semibold">
                        <span>{{ phoneNumber.dashed }}</span>
                        <span>&nbsp;</span>
                        <span>(TTY {{ phoneNumber.tty }})</span>
                    </span>

                </div>

            </div>

        </a>

    </TransitionScaleIn>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { Icon } from '@iconify/vue'
    import { Ringba } from '../../lib/Ringba'
    import TransitionScaleIn from '../transitions/TransitionScaleIn.vue'

    export default defineComponent({
        name: 'ChatClickToCall',
        components: { TransitionScaleIn, Icon },
        props: {
            text: { type: String, default: 'Click to call toll free' },
            backgroundColor: { type: String, default: null },
            isLegacyChat: { type: Boolean },
        },
        setup() {

            const phoneNumber = new Ringba().getPhoneNumber()

            return {
                phoneNumber,
            }

        },
    })

</script>

<template>

    <div class="container sm:pt-8"
         :class="{ 'sm:max-w-xl sm:w-full sm:mx-auto': !isLegacyChat }">

        <form class="h-full" @submit.prevent>

            <div class="flex flex-col justify-between h-full"
                 :class="{ 'sm:justify-start': !isLegacyChat }">

                <ChatMessageList ref="messageList"
                                 data-cy="chat-messages"
                                 :messages="messages"
                                 :is-typing="loading">
                    <ChatClickToCall v-if="chatCompleted" :is-legacy-chat="isLegacyChat"/>
                </ChatMessageList>

                <div data-cy="chat-components">

                    <ChatError :error="error"/>

                    <TransitionScaleIn appear>
                        <ChatTcpaLabel :show="activeStep.content.showTcpa && !chatCompleted"
                                       :for-mobile="true"
                                       @toggle-tcpa-modal="toggleModal"/>
                    </TransitionScaleIn>

                    <template v-if="isLegacyChat">

                        <div key="legacy-chat-components" class="-mx-4 sm:mx-0">

                            <ChatButtonGroup v-if="buttonGroup"
                                             :component="buttonGroup"
                                             :loading="loading"
                                             @scroll-chat="scrollChat"
                                             @submit="submit"/>

                            <div v-else>

                                <ChatInput ref="chatInput"
                                           :disabled="loading || chatCompleted || !!error?.length"
                                           :prevent-submit="loading"
                                           :active-step="activeStep"
                                           @submit="submit"/>

                                <ChatTcpaLabel :show="activeStep.content.showTcpa && !chatCompleted"
                                               @toggle-tcpa-modal="toggleModal"/>

                            </div>

                        </div>

                    </template>

                    <template v-else>

                        <ChatComponents v-show="!loading"
                                        key="chat-components"
                                        :active-step="activeStep"
                                        :loading="loading"
                                        :chat-completed="chatCompleted"
                                        :has-error="!!error?.length"
                                        @submit="submit"
                                        @scroll-chat="scrollChat"
                                        @toggle-tcpa-modal="toggleModal"/>

                    </template>

                </div>

            </div>

        </form>

        <ModalTcpa v-if="showTcpaModal" @close="toggleModal">
            <TCPA :content="activeStep.content"/>
        </ModalTcpa>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, inject, nextTick, onMounted, Ref, ref, watch } from 'vue'
    import { isStoryBlokLandingActive, state, WebFormStep } from '../types/State'
    import { useFormPlanner } from '../lib/compositions/useFormPlanner'
    import ChatClickToCall from '../components/chat/ChatClickToCall.vue'
    import { ChatMessage as TChatMessage, ChatMessages } from '../types/Chat'
    import { Api } from '../lib/Api'
    import { ChatRole } from '../lib/enums/ChatRole'
    import { ApiCode } from '../lib/enums/ApiCode'
    import ModalTcpa from '../components/modals/ModalTcpa.vue'
    import TCPA from '../components/web_forms/content/TCPA.vue'
    import ChatButtonGroup from '../components/chat/ChatButtonGroup.vue'
    import ChatTcpaLabel from '../components/chat/ChatTcpaLabel.vue'
    import ChatMessageList from '../components/chat/ChatMessageList.vue'
    import { StepComponent } from '../types/Components'
    import TransitionScaleIn from '../components/transitions/TransitionScaleIn.vue'
    import ChatInput from '../components/chat/ChatInput.vue'
    import ChatError from '../components/chat/ChatError.vue'
    import { ModalLinkHandlerKey } from '../types/InjectKeys'
    import { afterChatConversationUpdated, beforeChatConversationUpdated, onWebFormStart } from '../lib/DomainHooks'
    import { StepType } from '../lib/enums/StepType'
    import ChatComponents from '../components/chat/ChatComponents.vue'

    export default defineComponent({
        name: 'Chat',
        components: {
            ChatError,
            ChatInput,
            TransitionScaleIn,
            ChatMessageList,
            ChatTcpaLabel,
            ChatButtonGroup,
            TCPA,
            ModalTcpa,
            ChatClickToCall,
            ChatComponents,
        },
        props: {},
        setup() {

            isStoryBlokLandingActive.value = false

            let pollIntervalId: ReturnType<typeof setInterval> | undefined
            let counter = 0

            const metadata = { ...state.domain.metadata, ...state.webForm.metadata }
            const slowResponseTimeThresholdSeconds = 20
            const messageContent = ref<string | null>('')
            const loading = ref<boolean>(false)
            const { currentStep } = useFormPlanner()
            const messages = ref<ChatMessages>([])
            const chatInput = ref<typeof ChatInput | null>()
            const messageList = ref<typeof ChatMessageList | null>()
            const api = new Api()
            const latestConversationId = computed(() => messages.value[ messages.value.length - 1 ]?.id)
            const chatCompleted = ref<boolean>(false)
            const showTcpaModal = ref<boolean>(false)
            const disableButton = computed(() => loading.value || chatCompleted.value || messageContent.value?.trim() === '')
            const buttonGroup = ref<StepComponent | null>(null)
            const activeStep = ref<WebFormStep>(currentStep.value)
            const error = ref<string | undefined>()

            const isLegacyChat = computed(() => !!state.webForm.formPlanner.groups
                .flatMap(group => group.steps)
                .filter(step => step.type === StepType.Form)
                .length)

            const handleModalLinkClickEvents = inject(ModalLinkHandlerKey, () => {
                console.error('ModalLinkHandlerKey not provided')
            })

            onMounted(() => {

                // Run the local Hook logic before the conversation starts
                onWebFormStart()
                setLoading(true)

                setTimeout(async() => {
                    await showWelcomeMessage()
                }, getRandomDelayValue())

            })

            async function submit(message: string) {

                const formData = {
                    content: message,
                }

                // Add additional content to the data before submitting it.
                beforeChatConversationUpdated(formData, metadata)

                const data: TChatMessage = {
                    id: messages.value.length + 1,
                    key: randomKey(),
                    role: ChatRole.User,
                    hasError: false,
                    ...formData,
                }

                messages.value.push(data)

                const response = await api.updateConversation(state.visitorId, formData)

                if (response.isOk()) {

                    data.id = response.value.data.message.id
                    // Reset error in case we were showing the long wait time message
                    error.value = undefined

                    setTimeout(() => {

                        setLoading(true)
                        pollForMessages(formData, latestConversationId.value)

                    }, getRandomDelayValue())

                } else {

                    // If the response failed we will show this error for 4 seconds and then allow them to try again
                    error.value = 'Looks like we are having some trouble right now. Please try again in a few seconds.'

                    setTimeout(() => {

                        // We need to reset the input and check if the current step is a button group step
                        checkStepForButtonGroup(activeStep)
                        chatInput.value?.setInputProperties()
                        setLoading(false)
                        error.value = undefined

                    }, 4000)

                }

            }

            function pollForMessages(formData: Record<string, unknown>, fromMessageId: number) {

                clearInterval(pollIntervalId)

                pollIntervalId = setInterval(async() => {

                    const response = await api.getConversationData(state.visitorId, fromMessageId)

                    if (response.isOk()) {

                        // If there is no content, we want to keep polling
                        if (response.value === null || response.value.code === ApiCode.NoContent) {
                            counter++
                        } else {

                            const newMessages = response.value.data.messages
                            const latestAnswer = response.value.data.latest_visitor_answer_data

                            afterChatConversationUpdated(formData, latestAnswer)
                            handleIncomingMessages(newMessages)

                            if (response.value.data.completed_at) {
                                chatCompleted.value = true
                            }

                            // We can stop polling now and reset everything for the next message
                            clearInterval(pollIntervalId)
                            counter = 0
                            setLoading(false)
                            error.value = undefined

                        }

                    }

                    if (counter >= slowResponseTimeThresholdSeconds) {
                        error.value = 'Oops, it\'s taking us a little longer to respond...'
                    }

                }, 1000)

            }

            function handleIncomingMessages(newMessages: ChatMessages): void {

                for (const message of newMessages) {

                    message.key = randomKey()
                    messages.value.push(message)

                }

            }

            async function showWelcomeMessage() {

                const welcomeMessage: TChatMessage = {
                    id: messages.value.length + 1,
                    key: randomKey(),
                    role: ChatRole.Assistant,
                    hasError: false,
                    content: state.webForm.content.welcomeMessage || 'Let\'s get started!',
                }

                messages.value.push(welcomeMessage)

                setTimeout(async() => {

                    const nextMessage: TChatMessage = {
                        id: messages.value.length + 1,
                        key: randomKey(),
                        role: ChatRole.Assistant,
                        hasError: false,
                        content: currentStep.value.content.headline,
                        step: currentStep.value,
                    }

                    messages.value.push(nextMessage)
                    setLoading(false)
                    await checkStepForButtonGroup(activeStep)

                }, getRandomDelayValue())

            }

            function setLoading(value: boolean) {
                loading.value = value
            }

            function toggleModal() {

                // Remove click events while the content still exists to prevent memory leaks
                handleModalLinkClickEvents(false)

                nextTick(() => {

                    showTcpaModal.value = !showTcpaModal.value
                    // Re-handle the click events, if there are any on the new modal they will be processed here
                    setTimeout(() => handleModalLinkClickEvents(true), 400)

                })

            }

            watch(messages, (): void => {

                if (messages.value.length) {

                    const lastMessage = messages.value[ messages.value.length - 1 ]

                    if (lastMessage.step && lastMessage.step.components) {

                        activeStep.value = lastMessage.step
                        checkStepForButtonGroup(activeStep)

                    } else {
                        buttonGroup.value = null
                    }

                }

            }, { deep: true })

            async function checkStepForButtonGroup(step: Ref<WebFormStep>): Promise<void> {

                await nextTick()

                const buttonGroupComponent = step.value.components.find(component => [ 'button_group', 'chat_button_group' ].includes(component.type))

                if (buttonGroupComponent) {
                    buttonGroup.value = buttonGroupComponent
                }

            }

            // This allows us to mimic a more realistic "chat" experience so that the wait times are dynamic
            function getRandomDelayValue(min = 500, max = 750) {
                return Math.floor(Math.random() * (max - min + 1) + min)
            }

            /*
             * This is so that we can have a key that does not get changed for each message and cause a re-render when
             * we update the message content from the response.
             */
            function randomKey() {
                return Math.floor(Math.random() * 1000000)
            }

            function scrollChat() {
                messageList.value?.scrollToBottom()
            }

            return {
                activeStep,
                buttonGroup,
                chatCompleted,
                disableButton,
                loading,
                messageContent,
                messages,
                showTcpaModal,
                error,
                submit,
                toggleModal,
                chatInput,
                scrollChat,
                messageList,
                isLegacyChat,
            }

        },
    })
</script>

<style scoped>

    .container {
        height: calc(100dvh - 62px);
    }

</style>

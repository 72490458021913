<template>

    <div class="text-lg flex items-center justify-center space-x-4">

        <div v-for="unit of TimeUnit" :key="unit">

            <div class="primary-text font-semibold text-2xl">
                <FormatNumber :value="time[ unit ]" :pad="pad(unit)"/>
            </div>

            <div class="text-xs uppercase primary-text-dark tracking-wider">
                {{ unit }}
            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onUnmounted, reactive } from 'vue'
    import FormatNumber from './FormatNumber.vue'
    import { TimeUnit } from '../../lib/enums/TimeUnit'
    import { differenceInDays, formatDuration, intervalToDuration, isPast } from 'date-fns'

    export default defineComponent({
        name: 'FormatCountDown',
        components: { FormatNumber },
        props: {
            date: { type: Date, required: true },
        },
        setup({ date: then }) {

            const options = {
                format: [ 'hours', 'minutes', 'seconds' ],
                delimiter: ',',
                zero: true,
            }

            const time = reactive({
                [ TimeUnit.Days ]: 0,
                [ TimeUnit.Hours ]: 0,
                [ TimeUnit.Minutes ]: 0,
                [ TimeUnit.Seconds ]: 0,
            })

            let stopTick = false

            function tick(): void {

                if (stopTick || isPast(then)) {
                    return
                }

                const duration = intervalToDuration({ start: new Date(), end: then })
                const durationString = formatDuration(duration, options)
                const [ hours, minutes, seconds ] = durationString.split(',').map(value => parseInt(value))

                time[ TimeUnit.Days ] = differenceInDays(then, new Date())
                time[ TimeUnit.Hours ] = hours
                time[ TimeUnit.Minutes ] = minutes
                time[ TimeUnit.Seconds ] = seconds
                window.setTimeout(tick, 1000)

            }

            tick()

            function pad(unit: TimeUnit): number {
                return [ TimeUnit.Seconds, TimeUnit.Minutes ].includes(unit) ? 2 : 0
            }

            onUnmounted(() => stopTick = true)

            return {
                TimeUnit,
                time,
                pad,
            }

        },
    })

</script>

<template>
    <svg class="w-4 h-4"
         viewBox="0 0 20 17"
         fill="currentColor">
        <path d="M2.03213 4.23732L3.91985 7.49793C4.20295 7.98691 4.3445 8.23141 4.3445 8.5C4.3445 8.76859 4.20295 9.01309 3.91985 9.50207L2.03213 12.7627C0.780018 14.9254 0.153961 16.0068 0.634198 16.5469C1.11443 17.0869 2.26157 16.5916 4.55584 15.6009L4.55586 15.6009L16.7479 10.3361C18.5677 9.55033 19.4775 9.15743 19.4775 8.5C19.4775 7.84257 18.5677 7.44967 16.7479 6.66387L4.55585 1.39912C2.26157 0.408407 1.11443 -0.0869489 0.634198 0.453128C0.153961 0.993206 0.780018 2.07458 2.03213 4.23732Z"
              fill="white"
              aria-hidden="true"/>
    </svg>
</template>

<script lang="ts">
    export default {
        name: 'ChatIcon',
    }
</script>

<template>

    <div class="flex flex-col rounded-lg relative"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth, { 'shadow-lg': blok.showPhoneBox } ]">

        <div class="flex flex-col space-y-8 z-10"
             :class="{
                 'bg-white border p-8': blok.checklist.length <= 0,
                 'rounded-t-lg': blok.showPhoneBox,
                 'rounded-lg': !blok.showPhoneBox,
             }">

            <div class="text-2xl max-w-md" v-html="body"/>

            <div v-if="blok.checklist.length" class="p-4 bg-white rounded-lg border ">

                <ul class="flex flex-col space-y-4">

                    <li v-for="item of blok.checklist" :key="item" class="flex items-center space-x-4">

                        <Icon icon="mdi:check-bold" class="i-mdi-check-bold primary-text w-6 h-6"/>

                        <span>{{ item.text }}</span>

                    </li>

                </ul>

            </div>

            <RouterLink :to="routerLinkTo" replace>

                <PushButton :size="PushButtonSize.Largest" class="w-full text-xl">

                    <div class="flex items-center justify-between w-full">

                        <div class="w-full">
                            {{ blok.buttonText }}
                        </div>

                        <Icon icon="mdi:arrow-right" class="w-6 h-6 text-white"/>

                    </div>

                </PushButton>

            </RouterLink>

        </div>

        <PhoneBox v-if="blok.showPhoneBox"
                  class="bg-gray-200 bg-opacity-50 text-center pt-5 pb-3 px-2 text-gray-800 rounded-b-lg -mt-2 border"/>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Icon } from '@iconify/vue'
    import { StandardComponent } from '../../../../../client/types/Landing/Components'
    import { RouteKey } from '../../../../lib/enums/RouteKey'
    import { PushButtonSize } from '../../../../lib/enums/PushButton'
    import PushButton from '../../../buttons/PushButton.vue'
    import PhoneBox from '../../../web_forms/content/PhoneBox.vue'
    import { useRoute } from 'vue-router'
    import { state } from '../../../../types/State'
    import { WebFormTemplate } from '../../../../lib/enums/WebFormTemplate'
    import { renderSbRichText } from '../../../../lib/Helpers'

    export default defineComponent({
        name: 'StandardBlock',
        components: { PhoneBox, PushButton, Icon },
        props: {
            blok: { type: Object as PropType<StandardComponent>, required: true },
        },
        setup({ blok }) {

            const route = useRoute()

            const routerLinkTo = computed(() => {

                if (state.webForm.template === WebFormTemplate.Chat) {

                    return {
                        name: RouteKey.Chat,
                        params: route.params,
                        query: route.query,
                    }

                }

                return {
                    name: RouteKey.WebForm,
                    params: { ...route.params, step: 1 },
                    query: route.query,
                }

            })

            return {
                RouteKey,
                PushButtonSize,
                body: computed(() => renderSbRichText(blok.body)),
                routerLinkTo,
            }

        },
    })

</script>

<template>

    <div v-if="data.header || data.description" class="flex items-center justify-center">

        <div class="w-full mx-2 sm:mx-0">

            <div class="rounded-lg overflow-auto relative shadow-[0_0px_5px_rgba(0,0,0,0.15)]">

                <Text v-if="data.header"
                      :data="headerFont"
                      class="bg-gray-100 py-3 px-2"
                      :content="data.header"/>

                <div class="bg-white py-4 px-4">

                    <div class="px-4 py-6 flex flex-col items-center justify-center">

                        <ClickToCallButton :text="data.buttonText" :background-color="data.backgroundColor"/>

                        <Text :data="data" class="text-center mt-8" :content="description" @click="onClick"/>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <ClickToCallButton v-else :text="data.buttonText" :background-color="data.backgroundColor"/>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { ClickToCallComponent } from '../../../../types/Landing/Components'
    import ClickToCallButton from '../../../../components/buttons/ClickToCall.vue'
    import { Ringba } from '../../../../lib/Ringba'
    import { replaceTokens } from '../../../../lib/Helpers'
    import Text from './Text.vue'
    import { FontType } from '../../../../types/Landing/FontAware'

    export default defineComponent({
        name: 'ClickToCall',
        components: { Text, ClickToCallButton },
        props: {
            data: {
                type: Object as PropType<ClickToCallComponent>,
                required: true,
            },
        },
        setup(props) {

            const phoneNumber = new Ringba().getPhoneNumber()

            function onClick(event: { target: HTMLElement }) {

                if (event?.target?.hasAttribute('phone-number')) {
                    phoneNumber.onClick()
                }

            }

            const description = computed<string>(() => replaceTokens(
                props.data.description, {
                    number: `<a href="${ phoneNumber.link }" phone-number>${ phoneNumber.dashed }</a>`,
                },
            ))

            const headerFont = computed<FontType>(() => ({
                size: props.data.headerSize,
                weight: props.data.headerWeight,
                alignment: props.data.headerAlignment,
                color: props.data.headerColor,
            }))

            return {
                headerFont,
                description,
                onClick,
            }

        },
    })

</script>

<template>

    <nav aria-label="progress">

        <ol role="list">

            <li v-for="step of currentSteps" :key="step.title" class="relative" :class="{ 'pb-10': step !== last }">

                <div v-if="step !== last"
                     class="-ml-px absolute mt-0.5 top-4 left-5 w-0.5 h-full bg-gray-300"
                     aria-hidden="true">

                    <div class="primary-bg transition-height duration-500"
                         :class="{
                             'h-full': step.state === FlowCircleState.Complete,
                             'h-0': !step.state || step.state !== FlowCircleState.Complete,
                         }"/>

                </div>

                <a href="#" class="relative flex items-start group">

                    <div class="h-9 flex items-center" aria-hidden="true">
                        <FlowCircle :state="step.state" :icon="step.icon" :size="FlowCircleSize.Large"/>
                    </div>

                    <div class="ml-4 min-w-0 flex flex-col">

                        <div class="tracking-wide uppercase transform transition-transform duration-500 text-base"
                             :class="{
                                 'primary-text': step.state === FlowCircleState.Active,
                                 'font-semibold': step.state,
                                 'translate-y-2': !step.state,
                             }">

                            {{ step.title }}

                        </div>

                        <div v-if="step.subtitle"
                             class="text-gray-500 transition-opacity duration-250 text-base"
                             :class="{
                                 'opacity-0': !step.state || step.state === FlowCircleState.Incomplete,
                                 'opacity-100': step.state === FlowCircleState.Complete,
                                 'animate-pulse': step.state === FlowCircleState.Active,
                             }">

                            {{ step.subtitle }}

                        </div>

                    </div>

                </a>

            </li>

        </ol>

    </nav>

</template>

<script lang="ts">

    import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from 'vue'
    import FlowCircle from './FlowCircle.vue'
    import { delay } from '../../lib/Helpers'
    import { FlowCircleSize, FlowCircleState } from '../../lib/enums/Flow'
    import { State } from '../../types/State'
    import { useRoute, useRouter } from 'vue-router'
    import { RouteKey } from '../../lib/enums/RouteKey'

    export default defineComponent({
        name: 'FlowLoading',
        components: { FlowCircle },
        props: {
            time: { type: Number, default: 6 },
            steps: { type: Object as PropType<State[ 'domain' ][ 'content' ][ 'loadingSteps' ]>, required: true },
        },
        setup(props) {

            const index = ref(0)
            const route = useRoute()
            const router = useRouter()

            const currentSteps = ref((props.steps ?? []).map((step, index) => ({
                ...step.data,
                state: index === 0 ? FlowCircleState.Active : FlowCircleState.Incomplete,
            })))

            const last = currentSteps.value[ currentSteps.value.length - 1 ]
            const previous = computed(() => index.value === 0 ? null : currentSteps.value[ index.value - 1 ])
            const current = computed(() => currentSteps.value[ index.value ])

            let interval: number | null = null

            async function start() {

                await delay(500)
                await progress()
                interval = window.setInterval(progress, props.time * 1000 / (currentSteps.value.length || 1))

            }

            function clearTimer() {

                if (interval) {
                    clearInterval(interval)
                }

            }

            async function progress(): Promise<void> {

                if (index.value === currentSteps.value.length) {

                    if (previous.value) {
                        previous.value.state = FlowCircleState.Complete
                    }

                    clearTimer()
                    await delay(1000)

                    await router.replace({
                        name: RouteKey.ThankYou,
                        params: route.params,
                        query: route.query,
                    })

                    return

                }

                if (previous.value) {
                    previous.value.state = FlowCircleState.Complete
                }

                if (current.value) {
                    current.value.state = FlowCircleState.Active
                }

                index.value++

            }

            onMounted(start)
            onUnmounted(clearTimer)

            return {
                FlowCircleState,
                FlowCircleSize,
                currentSteps,
                last,
            }

        },
    })

</script>

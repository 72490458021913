<template>

    <div class="flex items-center space-x-2">

        <LayoutIcon class="h-10"/>

        <div class="hidden md:block">

            <h1 class="text-2xl font-semibold">
                <span class="secondary-text">{{ logo.a }}</span>
                <span class="primary-text">{{ logo.b }}</span>
                <span class="secondary-text">{{ logo.c }}</span>
            </h1>

            <h2 class="text-xs uppercase font-semibold tracking-widest primary-text">
                {{ logo.tagline }}
            </h2>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import LayoutIcon from './LayoutIcon.vue'
    import { State } from '../../types/State'

    export default defineComponent({
        name: 'LayoutDynamicLogo',
        components: { LayoutIcon },
        props: {
            logo: {
                type: Object as PropType<State[ 'domain' ][ 'content' ][ 'logo' ]>,
                required: true,
            },
        },
    })

</script>

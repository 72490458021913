<template>

    <iframe :src="videoUrl"
            width="640"
            height="320"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            data-ready="true"
            title="vimeo-iframe"/>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { VimeoComponent } from '../../../../types/Landing/Components'

    export default defineComponent({
        name: 'Vimeo',
        props: {
            data: { type: Object as PropType<VimeoComponent>, required: true },
        },
        setup({ data }) {

            const queryParameters = new URLSearchParams({
                autoplay: data.autoplay ? '1' : '0',
                loop: data.loop ? '1' : '0',
                muted: data.autoplay ? '1' : '0',
                controls: data.controls ? '1' : '0',
            })

            const videoUrl = `https://player.vimeo.com/video/${ data.videoId }?${ queryParameters.toString() }`

            return {
                videoUrl,
            }

        },
    })

</script>

<style lang="scss">

    iframe {
        @apply aspect-video w-full h-full bg-gray-200
    }

</style>

<template>

    <TransitionBottomToTopFull>

        <div v-if="show" class="fixed inset-x-0 bottom-0">

            <div class="bg-white border-t">

                <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">

                    <div class="flex items-center justify-between flex-wrap">

                        <div class="w-0 flex-1 flex items-center">

                            <a :href="phoneNumber.link"
                               class="flex p-2 rounded-lg primary-bg"
                               @click="phoneNumber.onClick">

                                <Icon icon="mdi:phone-in-talk" class="h-6 w-6 text-white"/>

                            </a>

                            <a :href="phoneNumber.link"
                               class="ml-3 font-bold lg:text-xl primary-text-dark w-full text-center"
                               @click="phoneNumber.onClick">

                                <span>{{ phoneNumber.dashed }}</span>
                                <span>&nbsp;</span>
                                <span>(TTY {{ phoneNumber.tty }})</span>

                            </a>

                        </div>

                        <a :href="phoneNumber.link"
                           class="hidden md:block order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto"
                           @click="phoneNumber.onClick">

                            <PushButton>Call Now</PushButton>

                        </a>

                        <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-3">

                            <button type="button"
                                    class="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400 sm:-mr-2"
                                    @click="show = false">

                                <span class="sr-only">Dismiss</span>

                                <Icon icon="mdi:close" class="h-6 w-6 primary-text"/>

                            </button>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </TransitionBottomToTopFull>

</template>

<script lang="ts">

    import TransitionBottomToTopFull from '../transitions/TransitionBottomToTopFull.vue'
    import PushButton from '../buttons/PushButton.vue'
    import { Icon } from '@iconify/vue'
    import { defineComponent, onMounted, ref } from 'vue'
    import { delay } from '../../lib/Helpers'
    import { Ringba } from '../../lib/Ringba'

    export default defineComponent({
        name: 'LayoutBanner',
        components: { Icon, PushButton, TransitionBottomToTopFull },
        setup() {

            const show = ref(false)
            const phoneNumber = new Ringba().getPhoneNumber()

            onMounted(async() => {

                await delay(200)
                show.value = true

            })

            return {
                show,
                phoneNumber,
            }

        },
    })

</script>

import { computed, ref, Ref } from 'vue'
import { state as globalState, State, thankYouPage, WebFormStep } from '../../types/State'
import { useRoute } from 'vue-router'
import { Api } from '../Api'
import { HookRunner } from '../HookRunner'
import { Hook } from '../enums/Hook'
import { err, ok, Result } from 'neverthrow'
import { ErrorResource } from '../../types/resources/ErrorResource'
import { ApiCode } from '../enums/ApiCode'
import { getPercent } from '../Helpers'

const state: Ref<State[ 'webForm' ][ 'formPlanner' ]> = ref(window.__STATE.webForm.formPlanner)

export enum Actions {
    RedirectToThankYouPage,
    MoveToNextStep,
}

type PossibleResponses =
    { action: Actions.MoveToNextStep }
    | { action: Actions.RedirectToThankYouPage }

export interface Group {
    id: number,
    weight: number,
    total: number,
    steps: WebFormStep[],
}

function groupAt(step: number): { group: Group, step: WebFormStep, weight: number, localStep: number } {

    let bag = 0
    let counter = step
    let progress = 0

    for (const group of state.value.groups) {

        bag += group.steps.length

        if (bag >= step) {

            const localStep = counter - 1

            return {
                group,
                weight: progress + ((group.weight / group.steps.length) * (localStep)),
                step: group.steps[ localStep ],
                localStep,
            }

        }

        counter -= group.steps.length
        progress += group.weight

    }

    throw new Error('No group was found at the given step.')

}

export function useFormPlanner() {

    const api = new Api()
    const runner = new HookRunner()
    const route = useRoute()
    const percentageFunction = getPercent(-10, 100)
    const urlStep = computed(() => +route.params.step || 1)
    const groupAtStep = computed(() => groupAt(urlStep.value))
    const currentGroup = computed(() => groupAtStep.value.group)
    const currentStep = computed(() => groupAtStep.value.step)
    const localStep = computed(() => groupAtStep.value.localStep)
    const progress = computed(() => percentageFunction(groupAtStep.value.weight * 100))
    const statistics = computed(() => state.value.statistics)
    const metadata = { ...globalState.domain.metadata, ...globalState.webForm.metadata }

    async function answerStep(visitorId: string, formData: Record<string, unknown>): Promise<Result<PossibleResponses, ErrorResource>> {

        runner.run(Hook.BeforeStepSubmission, formData, metadata)

        /**
         * Firstly persist the data to the visitor.
         */
        const response = await api.updateVisitor(visitorId, currentGroup.value.id, formData)

        runner.run(Hook.AfterStepSubmission, formData, metadata)

        if (response.isErr()) {
            return err(response.error)
        }

        if (response.isOk()) {

            const { code, data } = response.value

            if (code === ApiCode.HasMoreSteps) {
                return ok({ action: Actions.MoveToNextStep })
            }

            /**
             * If there is no further groups/steps redirect to the thank-you page.
             */
            if (code === ApiCode.NoMoreGroups) {

                /**
                 * Then finally the visitor has finished the form, redirect to the thank-you page
                 */
                runner.run(Hook.BeforeFormSubmission, formData, metadata)

                const response = await api.completeWebForm(visitorId)

                if (response.isErr()) {
                    return err(response.error)
                }

                runner.run(Hook.AfterFormSubmission, formData, metadata)

                if (response.isOk()) {

                    thankYouPage.value = data

                    return ok({
                        action: Actions.RedirectToThankYouPage,
                    })

                }

            }

            /**
             * Refresh groups, and move to the next step.
             */
            if (code === ApiCode.AppendGroup) {

                state.value.groups = data.groups
                state.value.statistics = data.statistics

            }

            return ok({ action: Actions.MoveToNextStep })

        }

    }

    return {
        currentGroup,
        currentStep,
        localStep,
        progress,
        statistics,
        answerStep,
    }

}

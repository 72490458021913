<template>

    <div class="flex flex-col space-y-4 mx-auto mb-8">

        <PushButtonSpinner v-for="option of buttonOptions"
                           :key="option.label"
                           :state="getState(option.value)"
                           :theme="option.theme ?? null"
                           @click="setValue(option.value)">

            {{ option.label }}

        </PushButtonSpinner>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import PushButtonSpinner from '../buttons/PushButtonSpinner.vue'
    import { ButtonGroup } from '../../types/Components'
    import { PushButtonState } from '../../lib/enums/PushButton'
    import { restoreState } from '../../lib/Helpers'
    import format from 'date-fns/format'
    import fromUnixTime from 'date-fns/fromUnixTime'

    export default defineComponent({
        name: 'StepDynamicTimes',
        components: { PushButtonSpinner },
        props: {
            component: { type: Object as PropType<ButtonGroup>, required: true },
        },
        emits: [ 'hide-button', 'progress' ],
        setup({ component }, { emit }) {

            const model = ref<string | null>()
            const loading = ref(false)

            const buttonOptions = component.data.times.map(time => {

                const localTime = format(fromUnixTime(time), 'EEEE do, h aa')

                return { value: time, label: localTime }

            })

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            function getState(option: string): PushButtonState {

                if (loading.value) {
                    return PushButtonState.Loading
                }

                if (option === model.value) {
                    return PushButtonState.Selected
                }

                return PushButtonState.Active

            }

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function isValid(): boolean {
                return true
            }

            function setValue(value: string) {

                loading.value = true
                model.value = value
                emit('progress')

            }

            return {
                PushButtonState,
                buttonOptions,
                model,
                getState,
                fill,
                isValid,
                setValue,
            }

        },
    })

</script>

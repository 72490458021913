<template>

    <div class="flex items-center justify-center">

        <div class="w-full max-w-2xl mx-2 sm:mx-0">

            <div class="rounded-lg relative shadow-[0_0px_5px_rgba(0,0,0,0.15)]">

                <TransitionTopToBottom>

                    <div :key="currentStep.id">
                        <Header :content="content" class="bg-gray-100 text-center py-3 px-2 text-gray-800"/>
                    </div>

                </TransitionTopToBottom>

                <div class="bg-white">

                    <div v-if="statistics.steps > 1" class="relative h-3">

                        <div class="absolute left-0 top-0 bottom-0 bg-gray-200 w-full"/>
                        <div class="absolute left-0 top-0 bottom-0 rounded-r-md primary-bg transition-all duration-200"
                             :style="{ width: `${ progress }%` }"/>

                    </div>

                    <TransitionLeftToRight>

                        <div :key="currentStep.id" class="px-4 py-6 flex flex-col items-center justify-center">
                            <slot/>
                        </div>

                    </TransitionLeftToRight>

                </div>

            </div>

            <TransitionLeftToRight>

                <div :key="currentStep.id">

                    <PhoneBox v-if="content.showPhoneBox"
                              :text="content.phoneBoxText || undefined"
                              class="bg-gray-200 bg-opacity-50 text-center pt-5 pb-3 px-2 text-gray-800 rounded-b-lg -mt-2 shadow-[0_0_5px_rgba(0,0,0,0.15)]"/>

                    <RequestImmediateAssistance :content="content"/>

                    <TCPA :content="content"/>

                </div>

            </TransitionLeftToRight>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { WebFormStep } from '../../types/State'
    import Header from './content/Header.vue'
    import TCPA from './content/TCPA.vue'
    import RequestImmediateAssistance from './content/RequestImmediateAssistance.vue'
    import PhoneBox from './content/PhoneBox.vue'
    import TransitionLeftToRight from '../transitions/TransitionLeftToRight.vue'
    import TransitionTopToBottom from '../transitions/TransitionTopToBottom.vue'
    import { useFormPlanner } from '../../lib/compositions/useFormPlanner'

    export default defineComponent({
        name: 'CardWithProgress',
        components: {
            TransitionLeftToRight,
            TransitionTopToBottom,
            Header,
            TCPA,
            RequestImmediateAssistance,
            PhoneBox,
        },
        props: {
            progress: { type: Number, required: true },
            content: { type: Object as PropType<WebFormStep[ 'content' ]>, required: true },
        },
        setup() {

            const { currentStep, statistics } = useFormPlanner()

            return {
                statistics,
                currentStep,
            }

        },
    })

</script>

<template>

    <div class="bullet-points py-8 md:py-16 mt-8 md:mt-0 w-full" :style="{ '--accent-color': data.accentColor }" data-cy="bullet-points-ty">

        <WebFormCardWithProgress :content="{ header: data.boxTitle }">

            <div class="mb-2 w-full max-w-md">

                <div class="mb-6 font-medium text-center">

                    <h1 class="text-2xl my-1">
                        <PhoneAware :text="data.header"/>
                    </h1>

                </div>

                <ul class="space-y-8">

                    <li v-for="({ data: { icon, title } }, index) of data.steps" :key="index" class="relative">

                        <div class="flex items-center">

                            <div class="icon flex items-center min-w-[35px]" v-html="icon"/>

                            <div class="ml-4 min-w-0 flex flex-col">

                                <div class="tracking-wide font-medium text-base">
                                    <PhoneAware :text="title"/>
                                </div>

                            </div>

                        </div>

                    </li>

                </ul>

            </div>

        </WebFormCardWithProgress>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import WebFormCardWithProgress from '../../components/web_forms/CardWithProgress.vue'
    import { BulletPointsTemplate } from '../../types/resources/ThankYouPageResource'
    import { useRoute } from 'vue-router'
    import PhoneAware from '../format/PhoneAware.vue'

    export default defineComponent({
        name: 'BulletPoints',
        components: { PhoneAware, WebFormCardWithProgress },
        props: {
            data: { type: Object as PropType<BulletPointsTemplate['data']>, required: true },
        },
        setup() {

            const route = useRoute()

            route.meta.center.value = false

        },
    })

</script>

<style lang="scss">

    .bullet-points {

        .icon {
            min-height: 35px;
            color: var(--accent-color);

            svg {
                width: 35px;
                height: 35px;
            }
        }

        strong, a.primary-text {
            color: var(--accent-color);
        }

    }

</style>

<template>

    <div v-if="thankYouPage" class="flex justify-center items-center -mt-16">
        <Component :is="components[ thankYouPage.template ]" :key="thankYouPage.key" :data="thankYouPage.data"/>
    </div>

</template>

<script lang="ts">

    import { defineComponent, inject, onMounted, onUnmounted } from 'vue'
    import { useRoute } from 'vue-router'
    import { RouteKey } from '../lib/enums/RouteKey'
    import MediaAlpha from '../components/thankyou/MediaAlpha.vue'
    import Standard from '../components/thankyou/Standard.vue'
    import MultipleSteps from '../components/thankyou/MultipleSteps.vue'
    import BulletPoints from '../components/thankyou/BulletPoints.vue'
    import Dynamic from '../components/landing/Dynamic.vue'
    import StoryBlok from '../components/thankyou/StoryBlok.vue'
    import { isStoryBlokLandingActive, state, thankYouPage } from '../types/State'
    import { Api } from '../lib/Api'
    import { ThankYouPageTemplate } from '../lib/enums/ThankYouPage'
    import { HookRunner } from '../lib/HookRunner'
    import { Hook } from '../lib/enums/Hook'
    import * as Sentry from '@sentry/vue'
    import { Result } from 'neverthrow'
    import { ThankYouPageResource } from '../types/resources/ThankYouPageResource'
    import { ErrorResource } from '../types/resources/ErrorResource'
    import { AudioManager } from '../../js/AudioManager'

    type Components = typeof Standard
        | typeof MediaAlpha
        | typeof MultipleSteps
        | typeof BulletPoints
        | typeof Dynamic
        | typeof StoryBlok

    const components: Record<ThankYouPageTemplate, Components> = {
        [ ThankYouPageTemplate.Standard ]: Standard,
        [ ThankYouPageTemplate.MediaAlpha ]: MediaAlpha,
        [ ThankYouPageTemplate.MultipleSteps ]: MultipleSteps,
        [ ThankYouPageTemplate.BulletPoints ]: BulletPoints,
        [ ThankYouPageTemplate.Dynamic ]: Dynamic,
        [ ThankYouPageTemplate.StoryBlok ]: StoryBlok,
    }

    export default defineComponent({
        name: 'ThankYou',
        setup() {

            const api = new Api()
            const runner = new HookRunner()
            const route = useRoute()
            const audioManager = inject<AudioManager>('audioManager')

            isStoryBlokLandingActive.value = false

            async function resolveThankYouPage(promisse: Promise<Result<ThankYouPageResource, ErrorResource>>) {

                const response = await promisse

                if (response.isOk()) {

                    const { data } = response.value

                    if (data) {

                        thankYouPage.value = data
                        runner.run(Hook.AfterEnterThankYouPage, data)
                        Sentry.setContext('thankYouPage', { id: data.key })
                        playAudio()
                        await api.confirmThankYouPage(state.visitorId, data.key)

                    }

                }

            }

            onMounted(() => {

                if (route.name === RouteKey.ThankYouPreview) {

                    thankYouPage.value = window.__TY_PAGE
                    playAudio()

                    return

                }

                if (!thankYouPage.value) {
                    resolveThankYouPage(api.getThankYouPage(state.visitorId))
                } else {

                    if (thankYouPage.value) {
                        runner.run(Hook.AfterEnterThankYouPage, thankYouPage.value)
                    }

                }

                setInterval(() => resolveThankYouPage(api.rotateThankYouPage(state.visitorId)), 5 * 1000)

            })

            onUnmounted(() => {
                audioManager.destroyEventListeners()
            })

            function playAudio() {

                if (thankYouPage.value && thankYouPage.value.completedAudio) {
                    audioManager.playCompletedWebFormAudio(thankYouPage.value.completedAudio)
                }

            }

            return {
                ThankYouPageTemplate,
                thankYouPage,
                components,
            }

        },

    })

</script>

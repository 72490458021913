<template>

    <div id="smart-address" class="relative">

        <SimpleTypeahead :id="component.data.name"
                         ref="inputReference"
                         :default-item="model"
                         :items="suggestions"
                         :item-projection="itemProjectionFunction"
                         @select-item="onSelectItem"
                         @on-focus="onFocus"
                         @on-blur="onBlur"
                         @on-input="onInput($event), model = $event.input"/>

        <label :for="component.data.name"
               :class="{
                   'translate-y-[8px] translate-x-[12px] scale-[0.8]': model || isSelected,
                   'translate-y-[18px] translate-x-[16px]': !model && !isSelected,
               }"
               class="
                   absolute top-0 pointer-events-none origin-top-left text-gray-500
                   whitespace-nowrap transition-transform duration-200
                   peer-focus:translate-y-[8px] peer-focus:translate-x-[12px] peer-focus:scale-[0.8]
               ">

            {{ component.data.placeholder }}

        </label>

    </div>

</template>

<script lang="ts">

    import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'
    import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
    import { SmartyAddress } from '../../types/Components'
    import SmartySDK from 'smartystreets-javascript-sdk'
    import SimpleTypeahead from 'vue3-simple-typeahead'
    import { useDebounceFn } from '@vueuse/core'
    import { isBlank, restoreState } from '../../lib/Helpers'
    import { Api } from '../../lib/Api'

    const key = import.meta.env.VITE_SMARTY_LICENSE
    const SmartyCore = SmartySDK.core
    const Lookup = SmartySDK.usAutocompletePro.Lookup
    const credentials = new SmartyCore.SharedCredentials(key)

    const clientBuilder = new SmartyCore.ClientBuilder(credentials)
        .withLicenses([ 'us-autocomplete-pro-cloud' ])

    const client = clientBuilder.buildUsAutocompleteProClient()

    type Suggestion = Partial<{
        city: string,
        state: string,
        streetLine: string,
        zipcode: string,
    }>

    export default defineComponent({
        name: 'StepSmartyAddress',
        components: { SimpleTypeahead },
        props: {
            component: { type: Object as PropType<SmartyAddress>, required: true },
        },
        setup({ component }) {

            const suggestions = ref([])
            const model = ref<Suggestion>()
            const inputReference = ref<InstanceType<typeof SimpleTypeahead>>()
            const isSelected = ref(false)

            onMounted(() => {

                const modelValue = ref<string>()

                watch(modelValue, async value => {

                    const response = await client.send(new Lookup(value))

                    if (response?.result?.length) {
                        inputReference.value.selectItem(response.result[ 0 ])
                    } else {

                        model.value = { streetLine: modelValue.value }
                        inputReference.value.selectItem(model.value)

                    }

                })

                restoreState(modelValue, [ 'data', component.data.name ])

                /**
                 * If failed to store from query string, user/data try to restore from previous address inputs
                 */
                if (isBlank(modelValue.value)) {

                    const api = new Api()
                    const { address = null, addressInput = null } = api.visitor.value

                    if (addressInput?.street || address?.address) {

                        inputReference.value.selectItem({
                            ...addressInput, streetLine: addressInput?.street ?? address?.address,
                        })

                    }

                }

            })

            const onInput = useDebounceFn(async({ input }) => {

                try {

                    const response = await client.send(new Lookup(input))

                    suggestions.value = response.result

                } catch {
                    console.log('ignore')
                }

            }, 200)

            const onSelectItem = async(value: Suggestion) => {
                model.value = value
            }

            function itemProjectionFunction(suggestion: Suggestion) {

                return [ suggestion.streetLine, suggestion.city, suggestion.state, suggestion.zipcode ]
                    .filter(Boolean)
                    .join(', ')

            }

            function onFocus() {
                isSelected.value = true
            }

            function onBlur() {
                isSelected.value = false
            }

            async function fill(formData: Record<string, unknown>): Promise<void> {

                formData[ component.data.name ] = {
                    city: model.value?.city,
                    state: model.value?.state,
                    address: model.value?.streetLine,
                    zip: model.value?.zipcode,
                }

            }

            function focus(): void {

                const element = document.querySelector(`#${ component.data.name }`) as HTMLInputElement

                if (element) {
                    element.focus()
                }

            }

            function isValid(): boolean {

                if (model.value) {
                    return true
                }

                return component.data.required === false

            }

            return {
                fill,
                focus,
                isValid,
                onFocus,
                onBlur,
                onSelectItem,
                itemProjectionFunction,
                onInput,
                suggestions,
                isSelected,
                inputReference,
                model,
            }

        },
    })

</script>

<style lang="scss">

    #smart-address {

        @apply z-auto;

        input.simple-typeahead-input {

            @apply pt-6 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-r-md bg-white;
            border-radius: 0.375rem;

        }

        .simple-typeahead {

            .simple-typeahead-list, .simple-typeahead-list-footer {
                @apply shadow-2xl text-sm text-gray-500;
            }

            .simple-typeahead-list-item-active {
                @apply bg-gray-100 text-black;
            }

            .simple-typeahead-list-item {
                @apply border border-gray-300 border-t-0;
            }

        }

    }

</style>

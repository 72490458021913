<template>

    <div class="flex flex-col space-y-4 w-full">

        <FormInputComponent ref="inputReference"
                            v-model="model"
                            v-maska="'##/##/####'"
                            :component="component"
                            :extra-attributes="{
                                maxlength: 10,
                                minlength: 10,
                                autocomplete: 'birthday',
                                inputmode: 'numeric',
                            }"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
    import { maska } from 'maska'
    import { DateOfBirth } from '../../types/Components'
    import { format, isValid as isValidDate, parse as parseDate } from 'date-fns'
    import FormInputComponent from '../form/FormInputComponent.vue'
    import { restoreState, toDecimalAge } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepDateOfBirthText',
        components: { FormInputComponent },
        directives: { maska },
        props: {
            component: { type: Object as PropType<DateOfBirth>, required: true },
            birthday: { type: String, default: null },
        },
        emits: [ 'changed' ],
        setup({ component, birthday }, { emit }) {

            const dateFormat = 'yyyy-MM-dd'
            const dateFormatDisplay = 'MM/dd/yyyy'
            const inputReference = ref<InstanceType<typeof FormInputComponent>>()
            const model = ref<string>()

            watch(model, () => emit('changed'))

            onMounted(() => {

                const currentDate = ref()

                if (birthday) {
                    currentDate.value = birthday
                } else {
                    restoreState(currentDate, [ 'data', component.data.name ])
                }

                /**
                 * Restore inputs from cache
                 */
                if (currentDate.value) {

                    const date = parseDate(currentDate.value, dateFormat, new Date())

                    if (isValidDate(date)) {
                        model.value = format(date, dateFormatDisplay)
                    }

                }

            })

            /**
             * Format and ensure that year is always padded with `19XX`
             */
            function formatAndPadYear(date: string): string {

                let padString = '19'

                const [ month, day, year ] = date.split('/')

                if (+year <= +format(new Date(), 'yy')) {
                    padString = '20'
                }

                return `${ year.padStart(4, padString) }-${ month }-${ day }`

            }

            async function fill(formData: Record<string, unknown>): Promise<void> {

                if (model.value) {

                    const birthday = formatAndPadYear(model.value)
                    const birthdayDate = parseDate(birthday, dateFormat, new Date())

                    formData[ component.data.name ] = birthday
                    formData[ `${ component.data.name }_age` ] = toDecimalAge(birthdayDate)

                }

            }

            function getValue(): string | void {

                if (model.value) {
                    return formatAndPadYear(model.value)
                }

            }

            function focus(): void {
                inputReference.value?.input?.focus()
            }

            function isValid(): boolean {

                if (model.value && typeof model.value === 'string') {

                    const date = parseDate(model.value, dateFormatDisplay, new Date())
                    const [ , , year ] = model.value.split('/')

                    if (year && ![ 2, 4 ].includes(year.length)) {
                        return false
                    }

                    return isValidDate(date)

                }

                return component.data.required === false

            }

            return {
                inputReference,
                model,
                fill,
                focus,
                isValid,
                getValue,
            }

        },
    })

</script>

<template>

    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <Image class="mx-auto" :data="data"/>

    </div>

</template>

<script lang="ts">

    import { sbCdnUrl } from '../../../../lib/Helpers'
    import { computed, defineComponent, PropType } from 'vue'
    import Image from '../../Dynamic/Components/Image.vue'
    import { Action as ActionEnum } from '../../Dynamic/Enums/Action'
    import { ClickActionAware } from '../../../../types/Landing/ClickActionAware'

    interface Action {
        action?: string,
        url?: string,
        step?: number,
        phoneNumber?: string,
    }

    interface ImageBlock {
        image: {
            filename: string,
        },
        action: Action,
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
    }

    export default defineComponent({
        name: 'DynamicImageBlock',
        components: {
            Image,
        },
        props: {
            blok: { type: Object as PropType<ImageBlock>, required: true },
        },
        setup({ blok }) {

            const action = computed(() => {

                if (blok.action.action === ActionEnum.ExternalLink) {

                    return {
                        type: ActionEnum.ExternalLink,
                        data: {
                            url: blok.action.url,
                        },
                    }

                }

                if (blok.action.action === ActionEnum.StartWebForm) {

                    return {
                        type: ActionEnum.StartWebForm,
                        data: {
                            step: blok.action.step,
                        },
                    }

                }

                if (blok.action.action === ActionEnum.TriggerDialer) {

                    return {
                        type: ActionEnum.TriggerDialer,
                        data: {
                            phoneNumber: blok.action.phoneNumber,
                        },
                    }

                }

                return null

            })

            /**
             * https://www.storyblok.com/docs/image-service
             *
             * Append /m/ to auto-optimize the image format.
             *
             * Currently our layout does not support width > 1024 px.
             * Append /1024x0/ to resize the image to a maximum width of 1024px.
             */

            let url = `${ blok.image.filename }/m/`

            const dimensions = url.match(/(\d*)x\d*/) || []

            if (dimensions.length === 2 && parseInt(dimensions[ 1 ]) > 1024) {
                url = `${ blok.image.filename }/m/1024x0/`
            }

            return {
                data: {
                    url: sbCdnUrl(url),
                    action: action.value as ClickActionAware,
                    marginTop: blok.marginTop,
                    marginBottom: blok.marginBottom,
                    maxWidth: blok.maxWidth,
                },
            }

        },
    })

</script>

<template>

    <!-- MOBILE -->
    <div class="flex w-full justify-between relative pb-0.5 sm:pb-4 sm:hidden" data-cy="mobile-chat-input">

        <input v-if="requiresMask"
               ref="input"
               v-model="message"
               v-bind="inputProperties"
               v-maska="inputProperties.mask"
               :disabled="disabled"
               class="w-full sm:flex-grow bg-white border border-[#E5E7EB] focus:border-[#1E40AF] sm:rounded-lg px-2 sm:pl-6 sm:pr-4 py-6 resize-none focus:outline-none focus:ring-4 focus:ring-[#1E40AF1A] placeholder:text-gray-300"
               @keydown.enter.exact.prevent="submit">

        <input v-else
               ref="input"
               v-model="message"
               v-bind="inputProperties"
               :disabled="disabled"
               class="w-full sm:flex-grow bg-white border border-[#E5E7EB] focus:border-[#1E40AF] sm:rounded-lg px-2 sm:pl-6 sm:pr-4 py-6 resize-none focus:outline-none focus:ring-4 focus:ring-[#1E40AF1A] placeholder:text-gray-300"
               @keydown.enter.exact.prevent="submit">

        <div class="absolute inset-y-0 sm:pb-4 right-0 pr-3 flex items-center">

            <button type="submit"
                    :disabled="disableButton"
                    class="w-12 h-12 bg-[#DC2626] rounded-full shadow-md flex items-center justify-center"
                    :class="{
                        'disabled:bg-gray-200 disabled:text-gray-400': disableButton,
                    }"
                    aria-label="Send Message"
                    @click.prevent="submit">
                <ChatIcon/>

            </button>

        </div>

    </div>

    <!-- DESKTOP -->
    <TransitionFadeIn :appear="true">

        <div v-if="!answered" class="hidden sm:flex pl-16 py-4 gap-4" data-cy="desktop-chat-input">

            <div class="chat-input flex-1 rounded-lg bg-white border broder-[#E5E7EB] relative px-4 pt-3 pb-4 shadow-lg">

                <p class="text-[#5F6275] text-sm">
                    Enter answer below:
                </p>

                <div class="relative mt-3">

                    <input v-if="requiresMask"
                           ref="input"
                           v-model="message"
                           v-bind="inputProperties"
                           v-maska="inputProperties.mask"
                           :disabled="disabled"
                           class="w-full flex-grow bg-white border border-[#E5E7EB] focus:border-[#1E40AF] rounded-lg px-2 pl-12 pr-4 py-4 resize-none focus:outline-none placeholder:text-[#5F6275] placeholder:opacity-70 leading-6"
                           @keydown.enter.exact.prevent="submit">

                    <input v-else
                           ref="input"
                           v-model="message"
                           v-bind="inputProperties"
                           v-maska="inputProperties.mask"
                           :disabled="disabled"
                           class="w-full flex-grow bg-white border border-[#E5E7EB] focus:border-[#1E40AF] rounded-lg px-2 pl-12 pr-4 py-4 resize-none focus:outline-none placeholder:text-[#5F6275] placeholder:opacity-70 leading-6"
                           @keydown.enter.exact.prevent="submit">

                    <div class="chat-icon absolute inset-y-0 flex items-center justify-center w-14">

                        <span class="h-6 w-6 text-gray-400">

                            <slot name="input-icon">

                                <ChatBubbleCenterIcon/>

                            </slot>

                        </span>

                    </div>

                    <div class="chat-submit absolute inset-y-0 right-0 pr-2 flex items-center">

                        <button type="submit"
                                :disabled="disableButton"
                                class="w-10 h-10 bg-[#DC2647] rounded-full shadow-md flex items-center justify-center"
                                :class="{
                                    'disabled:bg-gray-200 disabled:text-gray-400': disableButton,
                                }"
                                aria-label="Send Message"
                                @click.prevent="submit">
                            <ChatIcon/>

                        </button>

                    </div>

                </div>

                <div v-if="activeStep.content.showTcpa" class="mt-1.5" data-cy="tcpa-message">

                    <label for="leadid_tcpa_disclosure">

                        <input id="leadid_tcpa_disclosure" type="hidden">

                    </label>

                    <span class="text-xs text-[#5F6275] leading-none">
                        By submitting your phone number, you agree to be contacted about Medicare Advantage Plans, and agree to our
                        <span class="text-[#1E40AF] hover:underline cursor-pointer" @click="toggleTcpaModal"> TCPA Disclosure </span>
                    </span>

                </div>

            </div>

            <Avatar :is-user-message="true"/>

        </div>

    </TransitionFadeIn>

</template>

<script lang="ts">

    import { computed, defineComponent, nextTick, onMounted, PropType, ref, watch } from 'vue'
    import ChatIcon from './ChatIcon.vue'
    import { maska } from 'maska'
    import { WebFormStep } from '../../types/State'
    import { ChatInputProperties } from '../../types/ChatComponents'
    import Avatar from './Avatar.vue'
    import ChatBubbleCenterIcon from '../icons/ChatBubbleCenterIcon.vue'
    import TransitionFadeIn from '../transitions/TransitionFadeIn.vue'

    export default defineComponent({
        name: 'ChatInputComponent',
        components: {
            ChatIcon,
            Avatar,
            ChatBubbleCenterIcon,
            TransitionFadeIn,
        },
        directives: { maska },
        props: {
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            inputProperties: { type: Object as PropType<ChatInputProperties>, required: true },
            preventSubmit: { type: Boolean, required: true },
            disabled: { type: Boolean, required: true },
        },
        emits: [ 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            const message = ref('')
            const requiresMask = computed(() => !!props.inputProperties.mask)
            const input = ref<HTMLInputElement | null>(null)
            const submitting = ref(false)
            const disableButton = computed(() => props.preventSubmit || submitting.value || props.inputProperties.required ? message.value.length < 1 : false)
            const answered = ref(false)

            onMounted(() => {

                answered.value = false
                input.value?.focus()

            })

            watch(() => props.activeStep, () => {

                answered.value = false
                nextTick(() => input.value?.focus())

            })

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            function submit() {

                if (!props.inputProperties.required && message.value.length < 1) {
                    message.value = 'Prefer not to answer.'
                }

                submitting.value = true
                answered.value = true
                emit('submit', message.value)
                message.value = ''
                submitting.value = false
                input.value?.blur()

            }

            return {
                message,
                submit,
                disableButton,
                input,
                requiresMask,
                answered,
                toggleTcpaModal,
            }

        },
    })

</script>

<style scoped>
    .chat-input::before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        top: auto;
        left: auto;
        right: -20px;
        bottom: -1px;
        border: 18px solid;
        border-color: transparent transparent #E5E7EB transparent;
    }

    .chat-input::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        top: auto;
        left: auto;
        right: -18px;
        bottom: 0;
        border: 18px solid;
        border-color: transparent transparent white transparent;
    }
</style>

<template>

    <div class="w-full" :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <WebForm>

            <StepComponents :inline-web-form="true"/>

        </WebForm>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import WebForm from '../../../../pages/WebForm.vue'
    import StepComponents from '../../../../components/steps/StepComponents.vue'

    interface DynamicInlineWebFormBlock {
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
    }

    export default defineComponent({
        name: 'DynamicInlineWebFormBlock',
        components: {
            WebForm,
            StepComponents,
        },
        props: {
            blok: { type: Object as PropType<DynamicInlineWebFormBlock>, required: true },
        },
    })

</script>

<script lang="ts">

    import { defineComponent, PropType, onUnmounted } from 'vue'
    import { RingbaTagOverride } from '../../../../types/Landing/Components'
    import { Ringba } from '../../../../lib/Ringba'

    export default defineComponent({
        name: 'RingbaTagOverride',
        props: {
            data: {
                type: Object as PropType<RingbaTagOverride>,
                required: true,
            },
        },
        setup(props) {

            const ringba = new Ringba()

            const destroy = ringba.onClick(() => {

                const tags = props.data.tags

                for (const key in tags) {
                    ringba.push({ [ key ]: tags[ key ] })
                }

            })

            onUnmounted(() => destroy())

        },
    })

</script>

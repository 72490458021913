const stepProgress: string[] = []

function storeAnsweredStepOffset(offset: string): void {
    stepProgress.push(offset)
}

function hasAnsweredStep(offset: string): boolean {
    return stepProgress.includes(offset)
}

export function useStepHistoryStore() {

    return {
        storeAnsweredStepOffset,
        hasAnsweredStep,
    }

}

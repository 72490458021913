<template>

    <div>

        <a :href="phoneNumber.link">

            <div class="flex flex-col items-center">

                <div class="primary-text-dark text-lg flex items-center">

                    <div class="flex mr-1">

                        <Icon icon="mdi:phone-in-talk" class="w-5 h-5 primary-text-dark"/>

                        <div class="relative w-2 h-2">
                            <div class="absolute top-0 left-0 bg-green-400 rounded-full w-1.5 h-1.5 mr-2.5"/>
                            <div class="absolute bg-green-400 animate-ping rounded-full w-1.5 h-1.5 mr-2.5"/>
                        </div>

                    </div>

                    <div class="flex space-x-1 items-center text-xl whitespace-nowrap">
                        <span class="font-medium">{{ phoneNumber.dashed }}</span>
                        <span>(TTY {{ phoneNumber.tty }})</span>
                    </div>

                </div>

            </div>

            <div class="text-gray-500 text-sm">
                {{ text }}
            </div>

        </a>

    </div>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { state } from '../../../types/State'
    import { Ringba } from '../../../lib/Ringba'
    import { Icon } from '@iconify/vue'

    export default defineComponent({
        name: 'PhoneBox',
        components: { Icon },
        props: {
            text: {
                type: String,
                default: () => state.webForm.content?.phoneText ?? state.domain.content.phoneText,
            },
        },
        setup() {

            const phoneNumber = new Ringba().getPhoneNumber()

            return {
                phoneNumber,
            }

        },
    })

</script>

<style lang="scss">

    .tcpa span span, .modal-link {
        @apply text-gray-500 font-semibold cursor-pointer
    }

</style>

<template>

    <div class="px-5 py-4 md:py-6 bg-white rounded-xl items-center flex md:flex-col md:text-center md:space-y-2 h-full space-x-4 md:space-x-0">

        <div v-if="blok.image"
             class="w-24 h-24 bg-slate-500 rounded-full bg-cover bg-center flex-shrink-0"
             :style="{ backgroundImage: `url(${ sbCdnUrl(blok.image.filename) })` }"/>

        <div class="md:space-y-2">

            <div class="text-lg md:text-2xl leading-7 font-bold tracking-wide text-gray-900">
                {{ blok.title }}
            </div>

            <div class="text-sm md:text-base leading-6 text-gray-500">
                {{ blok.description }}
            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Asset } from '../../interfaces/Asset'
    import { sbCdnUrl } from '../../../../../lib/Helpers'

    interface Block {
        title: string,
        description: string,
        image: Asset,
    }

    export default defineComponent({
        name: 'Card1',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup() {

            return {
                sbCdnUrl,
            }

        },
    })

</script>

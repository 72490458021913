<template>

    <FormatNumberDigit v-for="(char, index) of parts" :key="index" :value="char" :direction="Direction.Down"/>

</template>

<script lang="ts">

    import { computed, defineComponent } from 'vue'
    import FormatNumberDigit from './FormatNumberDigit.vue'
    import { Direction } from '../../lib/enums/Direction'

    export default defineComponent({
        name: 'FormatNumber',
        components: { FormatNumberDigit },
        props: {
            value: { type: Number, required: true },
            pad: { type: Number, default: 0 },
        },
        setup(props) {

            const parts = computed<string[]>(() => String(props.value).padStart(props.pad, '0').split(''))

            return {
                Direction,
                parts,
            }

        },
    })

</script>

<template>

    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <Button :data="data"/>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import Button from '../../Dynamic/Components/Button.vue'
    import { Action as ActionEnum } from '../../Dynamic/Enums/Action'
    import { ClickActionAware } from '../../../../types/Landing/ClickActionAware'
    import { PushButtonTheme } from '../../../../lib/enums/PushButton'
    import { sbCdnUrl } from '../../../../lib/Helpers'

    interface Action {
        action?: string,
        url?: string,
        step?: number,
        phoneNumber?: string,
    }

    interface DynamicButtonBlock {
        text: string,
        pulsing: boolean,
        action: Action,
        size: string[],
        theme: PushButtonTheme,
        width: string[],
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
        audioFile?: {
            filename: string,
        },
    }

    export default defineComponent({
        name: 'DynamicButtonBlock',
        components: {
            Button,
        },
        props: {
            blok: { type: Object as PropType<DynamicButtonBlock>, required: true },
        },
        setup({ blok }) {

            const action = computed(() => {

                let audioComponent = null

                if (blok.audioFile?.filename) {

                    audioComponent = new Audio(sbCdnUrl(blok.audioFile.filename))
                    audioComponent.load()

                }

                if (blok.action.action === ActionEnum.ExternalLink) {

                    return {
                        type: ActionEnum.ExternalLink,
                        data: {
                            url: blok.action.url,
                            audioComponent,
                        },
                    }

                }

                if (blok.action.action === ActionEnum.StartWebForm) {

                    return {
                        type: ActionEnum.StartWebForm,
                        data: {
                            step: blok.action.step,
                            audioComponent,
                        },
                    }

                }

                if (blok.action.action === ActionEnum.TriggerDialer) {

                    return {
                        type: ActionEnum.TriggerDialer,
                        data: {
                            phoneNumber: blok.action.phoneNumber,
                            audioComponent,
                        },
                    }

                }

                if (blok.audioFile?.filename) {

                    return {
                        type: ActionEnum.PlayAudio,
                        data: {
                            audioComponent,
                        },
                    }

                }

                return null

            })

            return {
                data: {
                    text: blok.text,
                    pulsing: blok.pulsing,
                    theme: blok.theme,
                    width: blok.width,
                    size: blok.size,
                    action: action.value as ClickActionAware,
                    marginTop: blok.marginTop,
                    marginBottom: blok.marginBottom,
                    maxWidth: blok.maxWidth,
                    audioFile: blok.audioFile,
                },
            }

        },
    })

</script>

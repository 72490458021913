<template>

    <div class="hidden sm:flex mx-4 px-2 py-1 bg-[#1E40AF1A] rounded-t-lg justify-between items-center">

        <span>
            <svg class="inline-block w-3 h-3 mr-1"
                 viewBox="0 0 12 12"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M8 6H9.33333C9.33333 5.11595 8.98214 4.2681 8.35702 3.64298C7.7319 3.01786 6.88406 2.66667 6 2.66667V4C6.53043 4 7.03914 4.21071 7.41421 4.58579C7.78929 4.96086 8 5.46957 8 6ZM10.6667 6H12C12 2.66667 9.31333 0 6 0V1.33333C8.57333 1.33333 10.6667 3.42 10.6667 6ZM11.3333 8.33333C10.5 8.33333 9.7 8.2 8.95333 7.95333C8.72 7.88 8.46 7.93333 8.27333 8.12L6.80667 9.58667C4.91554 8.6222 3.3778 7.08446 2.41333 5.19333L3.88 3.72667C4.06667 3.54 4.12 3.28 4.04667 3.04667C3.79377 2.2787 3.66548 1.4752 3.66667 0.666667C3.66667 0.489856 3.59643 0.320286 3.4714 0.195262C3.34638 0.0702379 3.17681 0 3 0H0.666667C0.489856 0 0.320286 0.0702379 0.195262 0.195262C0.0702379 0.320286 0 0.489856 0 0.666667C0 3.67245 1.19404 6.55513 3.31946 8.68054C5.44487 10.806 8.32755 12 11.3333 12C11.5101 12 11.6797 11.9298 11.8047 11.8047C11.9298 11.6797 12 11.5101 12 11.3333V9C12 8.82319 11.9298 8.65362 11.8047 8.5286C11.6797 8.40357 11.5101 8.33333 11.3333 8.33333Z"
                      fill="#1E40AF"/>
            </svg>

            Have a question? Contact us:

            <a :href="phoneNumber.link"
               class="font-semibold text-[#1E40AF] hover:text-[#DC2626] px-2 py-1.5 hover:bg-[#DC262633] -my-0 rounded-lg cursor-clickable"
               @click="phoneNumber.onClick">

                <span>{{ phoneNumber.dashed }}</span>
                <span>&nbsp;</span>
                <span>(TTY {{ phoneNumber.tty }})</span>

            </a>

        </span>

        <span>

            <a :href="phoneNumber.link"
               class="font-semibold text-[#1E40AF] hover:text-[#DC2626] px-2 py-1.5 hover:bg-[#DC262633] rounded-lg cursor-clickable">
                Call Now
            </a>

        </span>

    </div>

</template>

<script lang="ts">
    import { Ringba } from '../../lib/Ringba'

    export default {
        name: 'ChatInputHeader',
        setup() {

            const phoneNumber = new Ringba().getPhoneNumber()

            return {
                phoneNumber,
            }

        },
    }
</script>

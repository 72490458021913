<template>
    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">
        <Text :data="data"
              class="space-y-4"
              :content="modifiedText"/>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, PropType, inject, nextTick } from 'vue'
    import { Richtext } from '@storyblok/vue'
    import Text from '../../Dynamic/Components/Text.vue'
    import { Action } from '../../Dynamic/Enums/Action'
    import cloneDeep from 'clone-deep'
    import { ModalLinkHandlerKey } from '../../../../types/InjectKeys'
    import { renderSbRichText } from '../../../../lib/Helpers'

    interface DynamicParagraphBlock {
        text: Richtext,
        fontAlignment: string[],
        fontColor: {
            color: string,
        },
        fontWeight: string[],
        fontSize: string[],
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
    }

    enum Placement {
        Previous = 'previous',
        Next = 'next',
        Between = 'between',
    }

    enum ActionLinkComponent {
        TriggerDialerLink = 'triggerDialerLink',
        StartWebFormLink = 'startWebFormLink',
        OpenModalLink = 'openModalLink',
    }

    interface ActionLink {
        component: ActionLinkComponent,
        linkText: string,
        placement: Placement,
        phoneNumber?: string,
        modal?: string,
    }

    interface LinkMark {
        type: string,
        attrs: {
            custom: object,
            linktype: string,
            uuid: null,
            target: string,
        },
    }

    interface StyledMark {
        type: string,
        attrs: object,
    }

    interface LinkObject {
        text: string,
        marks: LinkMark | StyledMark[],
        type: string,
    }

    export default defineComponent({
        name: 'DynamicParagraphBlock',
        components: {
            Text,
        },
        props: {
            blok: { type: Object as PropType<DynamicParagraphBlock>, required: true },
        },
        setup({ blok }) {

            const handleModalLinkClickEvents = inject(ModalLinkHandlerKey, () => {
                console.error('ModalLinkHandlerKey not provided')
            })

            onMounted(() => {

                // Remove click events while the content still exists to prevent memory leaks
                handleModalLinkClickEvents(false)

                nextTick(() => {

                    // Re-handle the click events, if there are any on the new modal they will be processed here
                    setTimeout(() => handleModalLinkClickEvents(true), 400)

                })

            })

            function removeContentAtIndex(blok: Richtext, index: number) {
                blok.content.splice(index, 1)
            }

            function generateLinkObject(actionLink: ActionLink): LinkObject {

                let linkSpecificAttributes = {}

                if (actionLink.component === ActionLinkComponent.TriggerDialerLink) {

                    linkSpecificAttributes = {
                        action: Action.TriggerDialer,
                        phone: actionLink.phoneNumber,
                        href: '#',
                    }

                } else if (
                    actionLink.component === ActionLinkComponent.StartWebFormLink
                ) {

                    linkSpecificAttributes = {
                        href: '#start-web-form',
                    }

                } else if (
                    actionLink.component === ActionLinkComponent.OpenModalLink
                ) {

                    return {
                        text: actionLink.linkText,
                        type: 'text',
                        marks: [
                            {
                                type: 'styled',
                                attrs: {
                                    class: 'dynamic-paragraph modal-link',
                                    'data-modal': actionLink.modal,
                                },
                            } as StyledMark,
                        ],
                    }

                }

                return {
                    text: actionLink.linkText,
                    type: 'text',
                    marks: [
                        {
                            type: 'link',
                            attrs: {
                                custom: {},
                                ...linkSpecificAttributes,
                                target: '_self',
                                linktype: 'url',
                                uuid: null,
                            },
                        },
                    ],
                }

            }

            function placeLinkInPreviousContent(modifiedText: Richtext, currentIndex: number, linkObject: LinkObject) {

                const previousContent = modifiedText.content[ currentIndex - 1 ]

                /**
                 * Spacing between paragraphs are paragraphs with no content.
                 * For our purposes we want to add in content, so we
                 * need to default the content array.
                 */
                if (typeof previousContent.content === 'undefined') {
                    previousContent.content = []
                } else {

                    // Add a space to the end of the previous content
                    previousContent.content[ 0 ].text = `${ previousContent.content[ 0 ].text } `

                }

                previousContent.content.push(linkObject)
                // Remove the current content as it's been merged into the previous
                removeContentAtIndex(modifiedText, currentIndex)

            }

            function placeLinkInNextContent(modifiedText: Richtext, currentIndex: number, linkObject: LinkObject) {

                if (currentIndex === modifiedText.content.length - 1) {

                    // If it's the last index, just add it to the end
                    const content = modifiedText.content

                    content.push({
                        type: 'paragraph',
                        content: [ linkObject ],
                    })

                } else {

                    // Add to the beginning of the next
                    const nextContent = modifiedText.content[ currentIndex + 1 ]

                    // Add a space to the beginning of the next content
                    nextContent.content[ 0 ].text = ` ${ nextContent.content[ 0 ].text }`
                    nextContent.content.unshift(linkObject)

                }

                // We adjust the index we are removing since we have 'unshifted' the next content
                removeContentAtIndex(modifiedText, currentIndex)

            }

            function placeLinkBetweenPreviousAndNextContent(modifiedText: Richtext, currentIndex: number, linkObject: LinkObject) {

                if (
                    currentIndex === 0 &&
                    currentIndex < modifiedText.content.length - 1
                ) {

                    // No previous, add to start of next
                    const nextContent = modifiedText.content[ currentIndex + 1 ]

                    // Add a space to the beginning of the next content
                    nextContent.content[ 0 ].text = ` ${ nextContent.content[ 0 ].text }`
                    nextContent.content.unshift(linkObject)
                    removeContentAtIndex(modifiedText, currentIndex)

                } else if (
                    currentIndex > 0 &&
                    currentIndex === modifiedText.content.length - 1
                ) {

                    // No next, add to end of previous
                    const previousContent = modifiedText.content[ currentIndex - 1 ]

                    // Add a space to the end of the previous content
                    previousContent.content[ 0 ].text = `${ previousContent.content[ 0 ].text } `
                    previousContent.content.push(linkObject)
                    removeContentAtIndex(modifiedText, currentIndex)

                } else if (
                    currentIndex > 0 &&
                    currentIndex < modifiedText.content.length - 1
                ) {

                    // Both previous and next exist
                    const previousContent = modifiedText.content[ currentIndex - 1 ]
                    const nextContent = modifiedText.content[ currentIndex + 1 ]

                    // Add a spaces to the end of the previous and beginning of the next content
                    previousContent.content[ 0 ].text = `${ previousContent.content[ 0 ].text } `
                    nextContent.content[ 0 ].text = ` ${ nextContent.content[ 0 ].text }`
                    // Merge the next content into the previous
                    previousContent.content.push(linkObject, ...nextContent.content)
                    // Remove the current and next content as it's been merged into the previous
                    removeContentAtIndex(modifiedText, currentIndex)
                    removeContentAtIndex(modifiedText, currentIndex)

                    /*
                     * Note: After removing the current content, the next content shifts to the current index,
                     * So we don't need to adjust the index for the second removal,
                     * This is why we call the previous: `removeContentAtIndex()` twice.
                     */

                } else {

                    // Between with no previous and no next, default to just displaying the link in its own paragraph
                    const content = modifiedText.content

                    content.push({
                        type: 'paragraph',
                        content: [ linkObject ],
                    })

                }

            }

            /**
             * We do not want to modify the blok prop directly, so we clone it first and modify the clone and then
             * return the clone to be used in the RenderRichText function.
             */
            const modifiedText: Richtext = computed(function() {

                const modifiedText = cloneDeep(blok.text)

                // Loop through the text content to find the action link bloks and use them to create hyperlinks in the text
                for (const index in modifiedText.content) {

                    const content = modifiedText.content[ index ]

                    // Check if it's a blok type and has the required components
                    if (
                        content.type !== 'blok' ||
                        content.attrs.body.length === 0 ||
                        !Object.values(ActionLinkComponent).includes(
                            content.attrs.body[ 0 ].component,
                        )
                    ) {
                        continue
                    }

                    const actionLink = content.attrs.body[ 0 ] as ActionLink
                    const currentIndex = parseInt(index, 10)
                    const linkObject = generateLinkObject(actionLink)

                    // Insert the link based on the placement
                    switch (actionLink.placement) {

                    case Placement.Previous: {

                        placeLinkInPreviousContent(modifiedText, currentIndex, linkObject)

                        break

                    }

                    case Placement.Next: {

                        placeLinkInNextContent(modifiedText, currentIndex, linkObject)

                        break

                    }

                    case Placement.Between: {

                        placeLinkBetweenPreviousAndNextContent(modifiedText, currentIndex, linkObject)

                        break

                    }

                    default: {

                        placeLinkBetweenPreviousAndNextContent(modifiedText, currentIndex, linkObject)

                        break

                    }

                    }

                }

                return modifiedText

            })

            return {
                data: computed(() => ({
                    size: blok.fontSize,
                    alignment: blok.fontAlignment,
                    weight: blok.fontWeight,
                    color: blok.fontColor.color,
                })),
                modifiedText: renderSbRichText(modifiedText.value),
            }

        },
    })
</script>

<style scoped>
    :deep(.text-center img) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
</style>

<template>

    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <Highlight :data="data"/>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Richtext } from '@storyblok/vue'
    import Highlight from '../../Dynamic/Components/Highlight.vue'
    import { renderSbRichText } from '../../../../lib/Helpers'

    interface DynamicHighlightBlock {
        text: Richtext,
        fontAlignment: string[],
        fontColor: {
            color: string,
        },
        fontWeight: string[],
        fontSize: string[],
        backgroundColor: {
            color: string,
        },
        borderColor: {
            color: string,
        },
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
    }

    export default defineComponent({
        name: 'DynamicHighlightBlock',
        components: {
            Highlight,
        },
        props: {
            blok: { type: Object as PropType<DynamicHighlightBlock>, required: true },
        },
        setup({ blok }) {

            return {
                data: computed(() => ({
                    ...blok,
                    text: renderSbRichText(blok.text),
                    color: blok.fontColor.color,
                    size: blok.fontSize,
                    backgroundColor: blok.backgroundColor.color,
                    borderColor: blok.borderColor.color,
                    alignment: blok.fontAlignment,
                    weight: blok.fontWeight,
                })),
            }

        },
    })

</script>

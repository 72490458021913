<template>

    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <ButtonGroup :data="data"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import ButtonGroup from '../../Dynamic/Components/ButtonGroup.vue'
    import { PushButtonTheme } from '../../../../lib/enums/PushButton'

    interface DynamicButtonGroupBlock {
        theme: PushButtonTheme,
        width: string[],
        columns: number[],
        size: string[],
        gap: number[],
        buttons: {
            text: string,
            name: string,
            value: string,
        }[],
        marginTop: string[],
        marginBottom: string[],
        maxWidth: string[],
    }

    export default defineComponent({
        name: 'DynamicButtonGroupBlock',
        components: {
            ButtonGroup,
        },
        props: {
            blok: { type: Object as PropType<DynamicButtonGroupBlock>, required: true },
        },
        setup({ blok }) {

            return {
                data: {
                    ...blok,
                },
            }

        },
    })

</script>

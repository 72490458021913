<template>

    <div v-editable="blok" class="py-10 md:py-20 bg-amber-400" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid class="space-y-12 md:space-y-0 md:flex md:space-x-8">

            <div class="space-y-4 flex-shrink-0 md:space-y-8 md:w-1/4">

                <h2 class="text-white leading-tight"
                    :class="[ blok.titleFontSize, blok.titleSpacingBottom ]"
                    :style="{ color: blok.textColor }">
                    {{ blok.title }}
                </h2>

                <p class="text-base md:text-xl leading-8 text-gray-800">
                    {{ blok.description }}
                </p>

            </div>

            <div class="visible-scrollbar flex-1 flex gap-6 snap-x snap-mandatory overflow-x-auto pb-2 md:grid sm:grid-cols-2 lg:grid-cols-3 md:pb-0 md:overflow-visible">

                <StoryblokComponent v-for="cardBlok of blok.cards"
                                    :key="cardBlok._uid"
                                    :blok="cardBlok"
                                    class="w-full snap-start shrink-0"/>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import type { SbBlokData } from '@storyblok/js'
    import Grid from '../components/Grid.vue'
    import { FontSize } from '../interfaces/FontSize'

    interface Block {
        title: string,
        titleFontSize: FontSize,
        titleSpacingBottom: string[],
        description: string,
        textColor?: string,
        backgroundColor?: string,
        cards: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block4',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

<style scoped>

    .visible-scrollbar::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    .visible-scrollbar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, .4);
    }

    .visible-scrollbar::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 10px;
    }

</style>

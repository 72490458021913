<template>

    <div v-if="isUserMessage"
         class="w-12 h-12 bg-[#1E40AF] rounded-full flex items-center justify-center mt-auto flex-shrink-0 bg-opacity-20 text-[#1E40AF]">
        <div class="text-center flex flex-col items-center justify-center">
            <p class="font-semibold">
                You
            </p>
        </div>
    </div>

    <div v-if="!isUserMessage"
         class="w-12 h-12 rounded-full flex items-center justify-center mt-auto flex-shrink-0">
        <img :src="avatar" alt="agent-avatar">
    </div>

</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import avatar from '../../../images/avatar.png'

    export default defineComponent({
        name: 'Avatar',
        props: {
            isUserMessage: { type: Boolean, required: true },
        },
        setup() {
            return { avatar }
        },
    })
</script>

<template>

    <div class="flex">

        <PushButton :size="size"
                    :theme="data.theme"
                    class="justify-center mx-auto rounded"
                    :class="[ { 'animate-throb': data.pulsing }, data.width ]"
                    @click="run">

            {{ data.text }}

        </PushButton>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { ButtonComponent } from '../../../../types/Landing/Components'
    import PushButton from '../../../buttons/PushButton.vue'
    import { PushButtonSize } from '../../../../lib/enums/PushButton'
    import { responsiveValue } from '../../../../lib/Helpers'
    import { useAction } from './Compositions/useActions'

    export default defineComponent({
        name: 'Button',
        components: { PushButton },
        props: {
            data: { type: Object as PropType<ButtonComponent>, required: true },
        },
        setup({ data }) {

            const size = responsiveValue<PushButtonSize>(data.size, PushButtonSize.Smallest)
            const { run } = useAction(data.action)

            return {
                run,
                size,
            }

        },
    })

</script>

<template>

    <div class="flex flex-col space-y-4 w-full">

        <FormInputComponent ref="inputReference"
                            v-model="model"
                            v-maska="'+1 (###) ###-####'"
                            :component="component"
                            :extra-attributes="{
                                maxlength: 17,
                                minlength: 17,
                                autocomplete: component.data.name,
                                inputmode: 'tel',
                            }"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import { PhoneNumber } from '../../types/Components'
    import { maska } from 'maska'
    import FormInputComponent from '../form/FormInputComponent.vue'
    import { restoreState } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepPhoneNumber',
        components: { FormInputComponent },
        directives: { maska },
        props: {
            component: { type: Object as PropType<PhoneNumber>, required: true },
        },
        setup({ component }) {

            const inputReference = ref<InstanceType<typeof FormInputComponent>>()
            const model = ref<string | null>()

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function focus(): void {
                inputReference.value?.input?.focus()
            }

            function isValid(): boolean {

                if (model.value) {

                    if (model.value.length === 17) {
                        return true
                    }

                    return false

                }

                return component.data.required === false

            }

            return {
                inputReference,
                model,
                fill,
                focus,
                isValid,
            }

        },
    })

</script>

<template>

    <img :src="data.url" :class="{ 'cursor-pointer': data.action }" @click.prevent="run">

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { ImageComponent } from '../../../../types/Landing/Components'
    import { useAction } from './Compositions/useActions'

    export default defineComponent({
        name: 'Image',
        props: {
            data: { type: Object as PropType<ImageComponent>, required: true },
        },
        setup({ data }) {

            const { run } = useAction(data.action)

            return {
                run,
            }

        },
    })

</script>

<template>

    <StoryblokComponent v-if="story"
                        :blok="story.content"
                        class="px-0"
                        :style="{ '--primary-color': story.content.primary_color }"/>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { useStoryblok } from '@storyblok/vue'
    import { isDebugMode } from '../../types/State'

    export default defineComponent({
        name: 'StoryBlok',
        props: {
            data: { type: Object, required: true },
        },
        async setup() {

            if (isDebugMode) {

                return {
                    story: await useStoryblok(
                        window.__STORY_BLOK.full_slug,
                        { language: location.pathname.startsWith('/es') ? 'es' : 'default', version: 'draft' },
                        { customParent: window.location.href.split('?')[ 0 ] },
                    ),
                }

            }

            return {
                story: window.__STORY_BLOK,
            }

        },
    })

</script>

<template>

    <div class="flex rounded-md shadow-sm">

        <div v-if="prefix"
             class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            <span class="text-gray-500 sm:text-sm">{{ prefix }}</span>
        </div>

        <div class="relative flex-1">

            <textarea v-if="isTextarea"
                      ref="input"
                      v-model="local"
                      v-bind="properties"
                      class="
                          pt-6 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                          border-gray-300 rounded-r-md peer bg-white
                      "/>

            <input v-else
                   ref="input"
                   v-model="local"
                   v-bind="properties"
                   class="
                       pt-6 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                       border-gray-300 rounded-r-md peer bg-white
                   ">

            <label :for="id"
                   :class="{
                       'translate-y-[8px] translate-x-[12px] scale-[0.8]': modelValue,
                       'translate-y-[18px] translate-x-[16px]': !modelValue,
                   }"
                   class="
                       absolute top-0 pointer-events-none origin-top-left text-gray-500
                       whitespace-nowrap transition-transform duration-200
                       peer-focus:translate-y-[8px] peer-focus:translate-x-[12px] peer-focus:scale-[0.8]
                   ">

                {{ component.data.placeholder }}

            </label>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
    import { StepComponent } from '../../types/Components'
    import { isBlank } from '../../lib/Helpers'

    export default defineComponent({
        name: 'FormInputComponent',
        props: {
            modelValue: { type: [ String, Number ], required: true },
            component: { type: Object as PropType<StepComponent>, required: true },
            focus: { type: Boolean as PropType<boolean>, default: false },
            extraAttributes: { type: Object as PropType<Record<string, unknown>>, default: () => ({}) },
            prefix: { type: String, default: null },
        },
        emits: [ 'update:model-value' ],
        setup(props, { emit }) {

            const input = ref<HTMLInputElement>()
            const id = ref<string>(`input-${ props.component.data.name }`)
            const isTextarea = props.extraAttributes.type === 'textarea'

            const properties = computed(() => ({
                id: id.value,
                type: 'text',
                placeholder: ' ',
                name: props.component.data.name,
                class: { 'rounded-l-md': !props.prefix },
                ...props.extraAttributes,
            }))

            const local = computed({
                get(): string | number | null {
                    return props.modelValue
                },
                set(value: string | number | null): void {
                    emit('update:model-value', isBlank(value) ? null : value)
                },
            })

            onMounted(() => {

                if (props.focus && input.value) {
                    input.value.focus()
                }

                /**
                 * Initialize with an empty value, to ensure it is submitted to server even when it's null
                 */
                if (isBlank(local.value)) {
                    local.value = null
                }

            })

            return {
                local,
                input,
                id,
                isTextarea,
                properties,
            }

        },
    })

</script>

<style lang="scss" scoped>

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

</style>

<template>

    <div v-editable="blok" class="py-10 lg:py-20" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid class="flex flex-col md:flex-row md:space-x-8">

            <div class="w-full space-y-10 md:w-1/2 order-1" :class="leftContent">

                <h2 :class="[ blok.titleFontSize, blok.headlineSpacingBottom ]"
                    class="mb-14"
                    :style="{ color: 'var(--primary-color)' }">
                    {{ blok.title }}
                </h2>

                <div class="space-y-3 md:space-y-6">

                    <StoryblokComponent v-for="checklistBlok of blok.checklist"
                                        :key="checklistBlok._uid"
                                        :blok="checklistBlok"/>

                </div>

            </div>

            <div class="w-full flex flex-col items-center space-y-10 md:w-1/2 order-2" :class="rightContent">

                <img :src="sbCdnUrl(blok.image.filename)" :alt="blok.image.alt" width="438" class="hidden md:block">

                <StoryblokComponent v-for="button of blok.button" :key="button._uid" :blok="button"/>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { StoryblokComponent } from '@storyblok/vue'
    import Grid from '../components/Grid.vue'
    import { Asset } from '../interfaces/Asset'
    import { FontSize } from '../interfaces/FontSize'
    import { Alignment } from '../interfaces/Alignment'
    import { SbBlokData } from '@storyblok/js'
    import { sbCdnUrl } from '../../../../lib/Helpers'

    interface Block {
        image: Asset,
        imageAlignment: Alignment,
        title: string,
        titleFontSize: FontSize,
        headlineSpacingBottom: string[],
        backgroundColor?: string,
        checklist: typeof StoryblokComponent[],
        button: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block2',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup(props) {

            return {
                leftContent: computed(() => props.blok.imageAlignment === Alignment.Left ? 'md:order-2' : 'md:order-1'),
                rightContent: computed(() => props.blok.imageAlignment === Alignment.Left ? 'md:order-1' : 'md:order-2'),
                sbCdnUrl,
            }

        },
    })

</script>

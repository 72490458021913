<template>

    <button type="button"
            class="relative inline-flex items-center"
            :class="[ sizes[ size ], cursor, currentThemeClass ]"
            @click="click">

        <slot/>

    </button>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { PushButtonSize, PushButtonState, PushButtonTheme } from '../../lib/enums/PushButton'

    interface ThemeInterface {
        name: PushButtonTheme,
        primary: string,
        active: string,
        selected: string,
        disabled: string,
    }

    const themes: ThemeInterface[] = [
        {
            name: PushButtonTheme.Themed,
            primary: 'border border-transparent font-medium rounded text-white button-bg-light',
            active: 'hover:button-bg-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:button-ring',
            selected: 'button-bg-dark',
            disabled: '',
        },
        {
            name: PushButtonTheme.White,
            primary: 'border border-gray-300 shadow-sm font-medium rounded text-gray-700 bg-white',
            active: 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            selected: 'primary-text bg-gray-50',
            disabled: 'bg-gray-200',
        },
        {
            name: PushButtonTheme.Text,
            primary: 'text-gray-700',
            active: 'hover:text-gray-500 hover:bg-gray-100 focus:outline-none active:text-gray-800 active:bg-gray-50',
            selected: 'text-gray-500',
            disabled: '',
        },
        {
            name: PushButtonTheme.Dark,
            primary: 'border border-gray-600 text-gray-300 bg-gray-700',
            active: 'hover:bg-gray-600 hover:text-gray-400 focus:outline-none focus:border-blue-600 focus:shadow-outline-blue active:text-gray-200 active:bg-gray-500',
            selected: 'bg-gray-600',
            disabled: '',
        },
        {
            name: PushButtonTheme.Indigo,
            primary: 'border border-transparent font-medium rounded shadow-sm text-white bg-indigo-600',
            active: 'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            selected: 'bg-indigo-700',
            disabled: '',
        },
        {
            name: PushButtonTheme.IndigoLight,
            primary: 'text-indigo-700 bg-indigo-100',
            active: 'hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200',
            selected: 'bg-indigo-50',
            disabled: '',
        },
        {
            name: PushButtonTheme.IndigoDark,
            primary: 'text-indigo-200 bg-indigo-900',
            active: 'hover:bg-indigo-700 hover:text-white focus:outline-none focus:border-indigo-800 focus:shadow-outline-indigo active:bg-indigo-800',
            selected: 'bg-indigo-700',
            disabled: '',
        },
        {
            name: PushButtonTheme.Red,
            primary: 'text-white bg-red-600',
            active: 'hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700',
            selected: 'bg-red-500',
            disabled: '',
        },
        {
            name: PushButtonTheme.RedDark,
            primary: 'text-white bg-red-700 rounded opacity-90',
            active: 'hover:bg-red-600 rounded focus:outline-none focus:border-red-800 focus:shadow-outline-red active:bg-red-800',
            selected: 'bg-red-600',
            disabled: '',
        },
        {
            name: PushButtonTheme.Yellow,
            primary: 'text-white bg-yellow-600',
            active: 'hover:bg-yellow-500 focus:outline-none focus:border-yellow-700 focus:shadow-outline-yellow active:bg-yellow-700',
            selected: 'bg-yellow-500',
            disabled: '',
        },
        {
            name: PushButtonTheme.Green,
            primary: 'text-white bg-green-600',
            active: 'hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700',
            selected: 'bg-green-500',
            disabled: '',
        },
        {
            name: PushButtonTheme.Blue,
            primary: 'text-white bg-blue-600',
            active: 'hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700',
            selected: 'bg-blue-500',
            disabled: '',
        },
        {
            name: PushButtonTheme.Purple,
            primary: 'text-white bg-purple-600',
            active: 'hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700',
            selected: 'bg-purple-500',
            disabled: '',
        },
        {
            name: PushButtonTheme.Pink,
            primary: 'text-white bg-pink-600',
            active: 'hover:bg-pink-500 focus:outline-none focus:border-pink-700 focus:shadow-outline-pink active:bg-pink-700',
            selected: 'bg-pink-500',
            disabled: '',
        },
    ]

    const sizes: Record<PushButtonSize, string> = {
        [ PushButtonSize.Smallest ]: 'px-2.5 py-1.5 text-xs leading-4',
        [ PushButtonSize.Small ]: 'px-3 py-2 text-sm leading-4',
        [ PushButtonSize.Medium ]: 'px-4 py-2 text-sm leading-5',
        [ PushButtonSize.Large ]: 'px-4 py-2 text-base leading-6',
        [ PushButtonSize.Largest ]: 'px-6 py-3 text-xl leading-6',
    }

    export default defineComponent({
        name: 'PushButton',
        props: {
            customTheme: { type: Object as PropType<ThemeInterface>, default: null },
            theme: { type: String as PropType<PushButtonTheme>, default: PushButtonTheme.Themed },
            state: { type: String as PropType<PushButtonState>, default: PushButtonState.Active },
            size: { type: String as PropType<PushButtonSize>, default: PushButtonSize.Medium },
        },
        emits: [ 'click' ],
        setup(props, { emit }) {

            const isActive = computed(() => props.state === PushButtonState.Active || props.state === PushButtonState.Selected)
            const isSelected = computed(() => props.state === PushButtonState.Selected)
            const isDisabled = computed(() => props.state === PushButtonState.Disabled)

            const cursor = computed(
                () => isActive.value ? 'cursor-pointer' : (isDisabled.value ? 'cursor-not-allowed' : 'cursor-wait'),
            )

            const currentTheme = computed<ThemeInterface | undefined>(() => {

                if (props.customTheme) {
                    return props.customTheme
                }

                if (props.theme) {
                    return themes.find(({ name }) => name === props.theme)
                }

                return themes.find(({ name }) => name === PushButtonTheme.Themed)

            })

            const currentThemeClass = computed<string[]>(() => {

                if (currentTheme.value) {

                    return [
                        currentTheme.value.primary,
                        isActive.value ? currentTheme.value.active : currentTheme.value.disabled,
                        isSelected.value ? currentTheme.value.selected : '',
                    ]

                }

                return []

            })

            function click() {

                if (isActive.value) {
                    emit('click')
                }

            }

            return {
                sizes,
                cursor,
                currentThemeClass,
                click,
            }

        },
    })

</script>

<template>

    <div v-editable="blok"
         class="block-1-wrapper w-full relative bg-white flex flex-col"
         :style="{ minHeight: `${ blok.blockHeight }vh` }"
         data-cy="block1">

        <div class="relative md:flex-1">

            <div v-if="hasBackgroundImage"
                 class="absolute inset-0 z-0"
                 :class="[ blok.backgroundImageVisibility ]"
                 :style="{ opacity: `${ (blok.backgroundOpacity || 1) / 100 }` }">
                <img class="h-full w-full object-cover" :src="sbCdnUrl(blok.background.filename)" :alt="blok.background.alt">
            </div>

            <div class="pb-20">

                <Grid class="relative flex pt-10 z-10"
                      :class="{
                          'justify-end text-right mr-4': blok.alignment === 'right',
                          'justify-center text-center': blok.alignment === 'center',
                          'justify-start text-left': blok.alignment === 'left',
                      }">

                    <svg v-if="!hasBackgroundImage"
                         width="799"
                         height="293"
                         viewBox="0 0 799 293"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         class="svg-background">
                        <circle cx="399.5"
                                cy="399.5"
                                r="399.5"
                                :fill="theme.primaryColor"
                                fill-opacity="0.15"/>
                        <circle cx="399.5"
                                cy="399.5"
                                r="353.5"
                                :fill="theme.primaryColor"
                                fill-opacity="0.2"/>
                    </svg>

                    <div v-if="blok.logo.filename" class="flex justify-between">

                        <img class="h-12 md:h-auto w-[60vw] md:w-[380px]"
                             :src="sbCdnUrl(blok.logo.filename)"
                             :alt="blok.logo.alt">

                        <a :href="phoneNumber.link"
                           class="text-white bg-red-600 rounded w-10 h-10 flex items-center justify-center md:hidden"
                           @click="phoneNumber.onClick">

                            <svg width="20"
                                 height="22"
                                 viewBox="0 0 20 22"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">

                                <path d="M18.5912 14.586C17.3021 14.586 16.055 14.3507 14.8917 13.927C14.5249 13.7858 14.1162 13.8917 13.8332 14.2095L12.1879 16.5276C9.22202 14.939 6.44482 11.9384 4.96714 8.49063L7.01074 6.53729C7.2937 6.20781 7.37754 5.7489 7.26226 5.33705C6.8745 4.0309 6.67538 2.63061 6.67538 1.18325C6.67538 0.547831 6.20378 0.0183105 5.63786 0.0183105H2.01177C1.44585 0.0183105 0.764648 0.300721 0.764648 1.18325C0.764648 12.1149 8.86571 21.1991 18.5912 21.1991C19.3352 21.1991 19.6287 20.4578 19.6287 19.8106V15.7509C19.6287 15.1155 19.1571 14.586 18.5912 14.586Z"
                                      fill="white"/>

                            </svg>

                        </a>

                    </div>

                    <div class="w-full flex flex-col md:max-w-3xl md:block"
                         :class="{ 'mt-4': !blok.logo.filename }">

                        <div class="leading-tight text-gray-500 font-semibold mb-3 mt-6"
                             :class="[
                                 blok.descriptionFontSize.length ? blok.descriptionFontSize : 'text-xl',
                                 blok.descriptionSpacingBottom,
                             ]">
                            {{ blok.description }}
                        </div>

                        <h1 class="text-xl text-black font-bold mb-2 mt-6 border-separate" :class="[ blok.headlineFontSize, blok.headlineSpacingBottom ]">
                            {{ blok.headline }}
                        </h1>

                        <div class="flex mt-3"
                             :class="{
                                 'justify-end': blok.alignment === 'right',
                                 'justify-center': blok.alignment === 'center',
                                 'justify-start': blok.alignment === 'left',
                             }">

                            <StoryblokComponent v-for="button of blok.button"
                                                :key="button._uid"
                                                :blok="button"
                                                :theme="theme.buttonTheme"
                                                :brand-text-color="theme.primaryColor"/>

                        </div>

                        <div v-if="blok.footerImage.filename" class="flex justify-between mt-8 max-w-xl mx-auto w-full">
                            <img class="h-full w-full object-cover" :src="sbCdnUrl(blok.footerImage.filename)" :alt="blok.footerImage.alt">
                        </div>

                    </div>

                </Grid>

            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import Grid from '../components/Grid.vue'
    import { Asset } from '../interfaces/Asset'
    import { Ringba } from '../../../../lib/Ringba'
    import { FontSize } from '../interfaces/FontSize'
    import { SbBlokData } from '@storyblok/js'
    import { sbCdnUrl } from '../../../../lib/Helpers'
    import { BrandTheme } from '../interfaces/BrandTheme'

    interface Block {
        headline: string,
        headlineFontSize: FontSize,
        headlineSpacingBottom: string[],
        description: string,
        descriptionFontSize: FontSize,
        descriptionSpacingBottom: string[],
        logo: Asset,
        background: Asset,
        footerImage: Asset,
        backgroundColor?: string,
        backgroundOpacity?: number,
        backgroundImageVisibility?: string[],
        blockHeight: number,
        alignment: 'left' | 'center' | 'right',
        button: SbBlokData[],
    }

    export default defineComponent({
        name: 'BrandedBlock1',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
            theme: { type: Object as PropType<BrandTheme>, required: true },
        },
        setup({ blok }) {

            const phoneNumber = new Ringba().getPhoneNumber()

            function isFieldSet(value: string | undefined | null) {
                return (value !== undefined && value !== null && value !== '')
            }

            const hasBackgroundImage = computed(() => isFieldSet(blok.background.filename))

            return {
                phoneNumber,
                hasBackgroundImage,
                sbCdnUrl,
            }

        },
    })

</script>

<style lang="scss">

    .banner_text b {
        color: var(--primary-color);
        font-weight: 800;
    }

    @media (max-width: 768px) {

        .block-1-wrapper {
            min-height: 0 !important;
        }

    }

</style>

<style scoped>
    .svg-background {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: -1;
    }

    @media (max-width: 768px) {
        .svg-background {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: 100vw;
            overflow: hidden;
            z-index: -1;
        }
    }

</style>

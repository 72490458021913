<template>

    <svg xmlns="http://www.w3.org/2000/svg"
         x="0px"
         y="0px"
         viewBox="0 0 124.4 85.8"
         xml:space="preserve">

        <path class="fill-current"
              :class="[ secondary ]"
              d="M59.7,0H8.1C3.6,0,0,3.6,0,8.1v69.6c0,4.5,3.6,8.1,8.1,8.1h20.2
                c4.8-3.6,9.4-7.7,13.6-12.5C61.6,51,67.8,22.2,59.7,0z M39.4,10.2l1.3-3.9l1.3,3.9h4.1l-3.3,2.4l1.3,3.9l-3.3-2.4l-3.3,2.4l1.3-3.9
                l-3.3-2.4H39.4z M17.4,48.2l-2.1-1.5l-2.1,1.5l0.8-2.5l-2.1-1.5h2.6l0.8-2.5l0.8,2.5h2.6l-2.1,1.5L17.4,48.2z M14.3,34.1l2.5-7.7
                l-6.6-4.8h8.1l2.5-7.7l2.5,7.7h8.1l-6.6,4.8l2.5,7.7l-6.6-4.8L14.3,34.1z M35.9,56.3l2.4,7.3L32,59.1l-6.2,4.5l2.4-7.3l-6.2-4.5
                h7.7l2.4-7.3l2.4,7.3h7.7L35.9,56.3z M47,36.9l-3.6-2.6l-3.6,2.6l1.4-4.2L37.6,30H42l1.4-4.2l1.4,4.2h4.4l-3.6,2.6L47,36.9z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M112.4,60.4c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.1,0.3,0.1,0.5c0,0,0,0,0.1,0c0-0.2,0-0.4,0-0.6C112.7,60.4,112.7,60.4,112.4,60.4z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M108.8,64.4C108.8,64.4,108.8,64.5,108.8,64.4c-0.4,0.2-0.7,0.3-1.1,0.5c0.9,0.3,1.8,0.3,2.7,0c-0.5-0.7-1.1-1.2-1.8-1.7c-0.7,0.3-1.5,0.5-2.2,0.7C107.2,64.2,108,64.3,108.8,64.4z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M111.7,66.5C111.7,66.6,111.7,66.6,111.7,66.5c0.1,0,0.1,0,0.1,0c0.7-0.6,1.5-0.6,2.3-0.2c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0c-0.1-0.2-0.3-0.3-0.4-0.5c0.4-0.1,0.8,0,1.2,0c-0.2-0.1-0.3-0.2-0.5-0.2c-0.4-0.1-0.7-0.1-1.1-0.1c-0.8,0-1.6,0.2-2.4,0c-0.1,0.1-0.2,0.2-0.3,0.3c0.4,0,0.8,0.1,1.2,0.1C111.8,66.1,111.7,66.3,111.7,66.5z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M97.9,54.7c-0.5-0.8-1.3-1.3-2.1-1.8c-1.1-0.7-2.2-1.1-3.4-1.5
		c-1-0.3-1.9-0.5-2.9-0.8c-4.2-1.1-8-2.9-11.5-5.4c0,0-0.1-0.1-0.1-0.1C81,49,85,51.9,89.6,53.9c-3.1-0.4-6.1-1.3-9.2-2.2
		c0.1,0.1,0.1,0.1,0.2,0.2c1.3,0.9,2.6,1.8,4,2.6c1.8,1,3.7,1.9,5.7,2.4c0,0,0,0,0.1,0c-1.3,0.1-2.6,0-3.9-0.1
		c0.1,0.1,0.2,0.2,0.3,0.3c1.9,1.7,4.1,2.7,6.6,3c0.9,0.1,1.7,0.1,2.6-0.2c1.4-0.5,2.2-1.5,2.5-3C98.6,56.1,98.4,55.4,97.9,54.7z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M104.6,63c-0.1-0.1-0.2-0.1-0.4-0.1c-0.7,0.2-1.3,0.2-2,0.2c-0.1,0-0.2,0-0.3,0
		c0.1,0,0.1-0.1,0.1-0.1c0.6-0.2,1.2-0.5,1.8-0.8c0.4-0.2,0.8-0.4,1.2-0.6c0.2-0.1,0.5-0.2,0.8-0.3c-0.3,0-0.6,0-1,0
		c-0.3,0-0.6,0.1-1,0.1c0.5-0.9,1.1-1.6,1.9-2.2c-1.2-0.2-2.3-0.1-3.5,0c0.9-0.9,1.7-1.7,2.9-2.3c-0.6-0.5-1.2-0.9-1.7-1.4
		c1.3-0.3,2.6-0.4,4-0.3c0-0.1-0.1-0.1-0.1-0.2c-0.8-1.8-1.2-3.7-1.3-5.7c-0.1-1.9,0.2-3.8,0.9-5.6c0.5-1.3,1.3-2.5,2.2-3.7
		c0.9-1.1,1.8-2.2,2.7-3.3c0.7-0.8,1.4-1.7,1.9-2.7c0.4-0.8,0.7-1.5,0.8-2.4c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0.1-0.1,0.1
		c-0.2,0.5-0.5,1-0.7,1.4c-0.5,0.9-1.2,1.7-1.9,2.5c-1.2,1.3-2.4,2.6-3.6,3.9c-1.2,1.3-2.3,2.7-3.2,4.3c-1.7,2.9-2.4,6.1-2.1,9.5
		c0,0.1,0,0.2,0,0.2c1.1,0.2,2.3,0.5,2.6,0.8c-1,0-2,0.1-2.9,0.3c-0.4-0.5-0.8-1.1-1.2-1.5c-0.7-0.8-1.5-1.5-2.4-2.1
		c-1.2-0.8-2.5-1.5-3.9-2c-1.7-0.6-3.4-1.2-5-1.8c-2-0.7-3.9-1.4-5.8-2.4c-2.1-1-4.1-2.3-5.8-3.9c-0.2-0.2-0.4-0.3-0.5-0.5
		c0,0.1,0,0.1,0,0.2c0.7,1.2,1.6,2.2,2.6,3.2c1.6,1.6,3.5,2.8,5.5,3.6c1.5,0.6,3.1,1.1,4.7,1.6c1.4,0.4,2.8,0.9,4.2,1.5
		c0.8,0.4,1.5,0.8,2.3,1.1c1.5,1.1,2.7,2.4,3.4,4.1c0.5,1.3,0.6,2.6,0.2,4c-0.3,1-0.9,2-1.6,2.8c-0.1,0.1-0.1,0.1-0.2,0.2
		c1.4,1,3,1.5,4.7,1.8c-1.3,0.3-2.5,0.4-3.8,0.5c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.1,0.9,0.3,1.4,0.4c1.1,0.2,2.1,0.2,3.2,0.2
		c0.1,0,0.1,0,0.2,0c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.8,0.2c1,0.3,2.7-0.1,3.4-0.6c0-0.1-0.1-0.1-0.1-0.2
		C106.1,64.6,105.4,63.8,104.6,63z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M98.1,68.9c-0.8-0.3-1.5-0.7-2.1-1.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9,1.2-2,2.1-3.3,2.7c2.8,1.2,6.7,0.9,9.1-1c-0.1,0-0.2,0-0.3,0C100.2,69.3,99.2,69.2,98.1,68.9z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M108.8,66.6c-0.4,0-0.9-0.1-1.3-0.1c-0.2,0-0.5,0.1-0.6,0.2c0.4,0.1,0.7,0.2,1.1,0.3c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.4c1.2-0.8,2-0.5,2.8,0.4c0-0.4-0.2-0.6-0.5-0.9c0.6-0.1,1.1,0.1,1.6,0.2c-0.2-0.2-0.4-0.3-0.6-0.4C110.1,66.8,109.4,66.7,108.8,66.6z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M116,0H67.7c8.1,22.2,1.9,51-17.8,73.3c-4.2,4.8-8.8,8.9-13.6,12.5H116
		c4.5,0,8.1-3.7,8.1-8.1V8.1C124.1,3.6,120.4,0,116,0z M116.7,38.4c0.4-0.2,0.8-0.3,1.3-0.4c-0.1,0.2-0.2,0.3-0.2,0.4
		c-0.6,1-1.3,1.9-2,2.8c-0.5,0.7-1.1,1.4-1.5,2.2c-0.6,1-1,2.1-1.2,3.3c-0.2,1.2-0.4,2.5-0.7,3.7c-0.2,1.1-0.5,2.2-0.9,3.2
		c-0.3,0.7-0.7,1.4-1.2,1.9c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0.5,0.2,1,0.4,1.5,0.6c0.6,0.2,1.1,0.5,1.6,0.9
		c0.3,0.3,0.6,0.6,0.7,1.1c0,0.1,0.1,0.2,0.2,0.2c0.4,0.2,0.7,0.5,1,0.9c0.3,0.4,0.3,0.8,0.2,1.3c-0.1,0.2-0.1,0.4-0.2,0.5
		c-0.3,0.5-0.7,0.9-1.1,1.3c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1,0.5-0.1,1-0.2,1.5
		c-0.1,0-0.2,0-0.2-0.1c-0.5-0.2-0.9-0.4-1.3-0.7c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.2-2.4-0.3-3.6-0.3c-0.1,0-0.1,0-0.2,0
		c0.1,0.1,0.1,0.1,0.2,0.2c0.9,0.7,1.8,1.5,2.5,2.3c0.3,0.3,0.6,0.7,0.9,1c0.1,0.1,0.2,0.2,0.4,0.2c0.5,0,0.9,0.1,1.4,0.1
		c0.4,0,0.8,0,1.2,0c0.2,0,0.4,0,0.6,0.1c0.7,0.2,1.1,0.6,1.3,1.2c0.1,0.3,0.1,0.5,0.1,0.8c-0.3-0.4-0.6-0.8-1.2-0.9
		c0.1,0.3,0.2,0.7,0.1,1c-0.1,0.3-0.4,0.6-0.7,0.8c0-0.1,0-0.2,0-0.2c0-0.1,0-0.3,0-0.4c0-0.5-0.4-0.9-0.9-0.9c-0.3,0-0.7,0.1-1,0.3
		c-0.1,0.1-0.2,0.2-0.3,0.4c0,0.1,0,0.2,0,0.2c0.2,0.4,0.2,0.8,0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.8c-0.1-0.1-0.1-0.2-0.1-0.3
		c0-0.3-0.1-0.6-0.3-0.9c-0.2-0.3-0.6-0.4-1-0.5c0,0.1,0.1,0.2,0.1,0.2c0.1,0.3,0.1,0.6,0,0.9c-0.2,0.6-0.6,1-1.1,1.3
		c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.2-0.6,0.2-1c0-0.8-0.6-1.2-1.3-1.1c-0.8,0.1-1.4,0.7-1.4,1.5c0,0,0,0.1,0,0.1
		c-0.3-0.3-0.5-0.6-0.5-1c-0.1-0.4,0.1-0.8,0.4-1.1c-0.4-0.2-0.8-0.4-1.3-0.3c-0.8,0.2-1.6,0.2-2.4,0c-0.1,0-0.2,0-0.3,0
		c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.6-0.4,1.1-0.5,1.7c-0.1,0.2-0.2,0.4-0.4,0.5c-0.9,0.7-1.9,1.1-3,1.4c-1.4,0.4-2.8,0.6-4.2,0.4
		c-1.3-0.1-2.5-0.5-3.6-1.3c-0.2-0.1-0.3-0.2-0.5-0.4c0.1-0.1,0.1-0.1,0.2-0.2c1.8-1.2,3.4-2.6,4.8-4.2c0.6-0.7,1.2-1.5,1.6-2.4
		c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.1,0-0.2,0c-1.8,0.2-3.6,0-5.4-0.5c-2.9-0.9-5.4-2.4-7.4-4.6c-0.6-0.6-1.1-1.2-1.5-1.9
		c-0.1-0.1-0.2-0.2-0.4-0.3c-2.4-0.8-4.6-1.9-6.6-3.4c-1.1-0.9-2.1-1.9-2.9-3.1c-0.1-0.1-0.1-0.2-0.1-0.3c1.5,0.7,3.1,0.9,4.8,0.6
		c0,0-0.1,0-0.1,0c-1.9-0.5-3.4-1.5-4.5-3.1c-0.9-1.2-1.5-2.6-1.9-4.1c0-0.1,0-0.2,0-0.3c0.1,0.1,0.1,0.1,0.2,0.2
		c0.8,1.2,1.9,2.2,3.1,3c0.7,0.4,1.4,0.7,2.1,0.9c0.1,0,0.2,0,0.3,0.1c-0.4-0.3-0.7-0.6-1.1-0.9c-1-0.9-1.9-2-2.5-3.2
		c-1.1-2.1-1.6-4.4-1.7-6.8c0-0.1,0-0.1,0-0.2c1.2,3.2,3,5.9,5.7,7.9c0.4,0.3,0.8,0.5,1.2,0.7c-0.3-0.4-0.7-0.8-1-1.1
		c-1.7-2.2-2.9-4.7-3-7.6c-0.1-1.4,0.1-2.8,0.6-4.2c0,0,0-0.1,0.1-0.2c0,0.1,0,0.1,0,0.2c0.1,1.9,0.6,3.7,1.5,5.4
		c1,2.1,2.5,3.9,4.5,5.2c1.2,0.8,2.4,1.5,3.7,2.1c1.8,0.8,3.7,1.5,5.5,2.2c1.7,0.6,3.5,1.2,5.2,2c1.6,0.7,3,1.4,4.4,2.5
		c0.3,0.2,0.6,0.5,0.9,0.7c0,0,0.1,0.1,0.2,0.1c0-0.1,0-0.1,0-0.1c0.1-2.8,0.9-5.3,2.3-7.7c0.8-1.4,1.9-2.7,3-3.9
		c1.3-1.3,2.5-2.6,3.6-4.1c1.1-1.4,2.1-2.9,2.8-4.5c0.7-1.7,1.2-3.4,1.1-5.2c0-1-0.2-2-0.4-3c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1
		c0.1,0.1,0.2,0.2,0.2,0.3c1.2,1.5,1.8,3.2,1.9,5.1c0.1,1.6-0.1,3.2-0.6,4.8c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1
		c0.2-0.2,0.3-0.4,0.4-0.6c0.4-0.7,0.7-1.5,1-2.2c0.1-0.3,0.2-0.5,0.3-0.8c0-0.1,0-0.1,0.1-0.2c0.4,2,0.2,4-0.6,5.9
		c0.2-0.1,0.3-0.2,0.4-0.3c0.7-0.7,1.3-1.4,1.7-2.3c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0c-0.1,2.7-1.3,4.8-3.3,6.5
		C115.9,38.7,116.3,38.5,116.7,38.4z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M112.2,59.3c-0.4,0.3-0.8,0.4-1.2,0.4c-0.1,0-0.3,0-0.5,0.1c0.6,0.8,1.2,1.4,2,1.9

		c-0.5-0.5-1-1-1.4-1.6c0,0,0.1,0,0.1,0c0.6,0,1.2,0,1.8,0.2c0.6,0.2,1,0.5,1.2,1.1c0.3-0.2,0.5-0.7,0.5-1c0-0.2-0.1-0.4-0.2-0.6
		c-0.2-0.3-0.5-0.5-0.8-0.6c-0.2-0.1-0.4-0.2-0.7-0.1C112.8,59.1,112.5,59.1,112.2,59.3z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M110.9,58.3c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.2,0.3,0.2c-0.2-0.2-0.1-0.3,0-0.5C111,58.4,110.9,58.3,110.9,58.3z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M115.2,35.5C115.2,35.5,115.2,35.5,115.2,35.5c-0.2,0.2-0.3,0.3-0.4,0.5
		c-0.9,1.3-1.9,2.4-2.9,3.6c-0.8,0.9-1.7,1.9-2.4,2.9c-0.6,0.8-1,1.6-1.4,2.5c-0.5,1.3-0.6,2.6-0.5,4c0,0,0,0.1,0,0.1
		c0.5-0.7,1-1.3,1.8-1.7c0.3,2,0.2,4-0.3,5.9c0.6-0.5,1.1-1.2,1.4-2c0.3-0.6,0.4-1.3,0.5-2c0.1-0.8,0.2-1.5,0.3-2.3
		c0.2-1.5,0.7-3,1.6-4.3c0.6-1,1.3-1.8,2.2-2.6c0,0,0.1-0.1,0.1-0.1c-1.5,0.6-2.8,1.6-4.1,2.5C112.9,40.4,114.3,38.1,115.2,35.5z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M111.1,58.7c0,0.1,0.1,0.1,0.2,0c-0.1-0.1-0.1-0.1-0.2-0.1C111.1,58.6,111,58.7,111.1,58.7z"/>

        <path class="fill-current"
              :class="[ primary ]"
              d="M105.5,56c0,0-0.1,0-0.2,0c0.5,0.4,1,0.7,1.4,1.1c-0.3,0.1-0.5,0.1-0.7,0.2
                c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.3,0.2-0.4,0.3-0.5,0.4c1.4,0,2.1,0.1,2.7,0.3c-0.5,0.5-1,1-1.3,1.7
                c1.8-0.2,3.5,0,5.2,0.4c-0.4-0.4-0.8-0.8-1-1.2c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.5-0.1,0.7-0.3
                c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2-0.1c-0.3,0.3-0.6,0.3-0.9,0.1c-0.2-0.2-0.2-0.5,0.2-0.9c-0.1-0.1-0.2-0.2-0.4-0.3
                c-0.1-0.1-0.3-0.1-0.4-0.2c0.5,0,0.9,0.2,1.2,0.6c0.1,0.2,0.3,0.3,0.5,0.5c0.3,0.2,0.4,0.1,0.5-0.2c0,0,0-0.1,0-0.1
                c0.2,0.1,0.1,0.3,0.1,0.5c0.3-0.1,0.5-0.1,0.8-0.2c0-0.3-0.2-0.5-0.4-0.7c-0.4-0.4-0.9-0.7-1.4-0.9C109.6,56.1,107.6,55.8,105.5,56
                z"/>

    </svg>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'LayoutIcon',
        props: {
            primary: { type: String, default: 'secondary-text' },
            secondary: { type: String, default: 'primary-text' },
        },
    })

</script>

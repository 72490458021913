<template>

    <Transition mode="out-in"
                :enter-from-class="enterFrom"
                enter-active-class="transition transition-all ease-out duration-300"
                enter-to-class="transform translate-y-0 opacity-100"
                leave-from-class="transform translate-y-0 opacity-100"
                leave-active-class="transition transition-all ease-in duration-300"
                :leave-to-class="leaveTo">

        <div class="inline-flex w-4">
            {{ value }}
        </div>

    </Transition>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Direction } from '../../lib/enums/Direction'

    export default defineComponent({
        name: 'FormatNumberDigit',
        props: {
            value: { type: String, required: true },
            direction: { type: Number as PropType<Direction>, required: true },
        },
        setup(props) {

            const classes = {
                upEnterFrom: 'transform opacity-0 -translate-y-1',
                downEnterFrom: 'transform opacity-0 translate-y-1',
                upLeaveTo: 'transform opacity-0 translate-y-1',
                downLeaveTo: 'transform opacity-0 -translate-y-1',
            }

            const enterFrom = computed<string>(() => props.direction === Direction.Up ? classes.downEnterFrom : classes.upEnterFrom)
            const leaveTo = computed<string>(() => props.direction === Direction.Up ? classes.downLeaveTo : classes.upLeaveTo)

            return {
                enterFrom,
                leaveTo,
            }

        },
    })

</script>

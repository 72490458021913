<template>

    <div v-if="finishedApiCall" class="flex justify-center items-center -mt-16">
        <FlowLoading :steps="loadingSteps"/>
    </div>

</template>

<script lang="ts">

    import { defineComponent, ref } from 'vue'
    import FlowLoading from '../components/flow/FlowLoading.vue'
    import { Api } from '../lib/Api'
    import { isStoryBlokLandingActive, State, state } from '../types/State'

    export default defineComponent({
        name: 'Loading',
        components: { FlowLoading },
        setup() {

            const loadingSteps = ref<State[ 'domain' ][ 'content' ][ 'loadingSteps' ]>([])
            const api = new Api()
            const finishedApiCall = ref(false)

            isStoryBlokLandingActive.value = false

            api.getLoadingPage(state.visitorId)
                .then(response => {

                    if (response.isOk()) {

                        state.domain = response.value.data
                        loadingSteps.value = state.domain.content.loadingSteps

                    }

                })
                .finally(() => finishedApiCall.value = true)

            return {
                loadingSteps,
                finishedApiCall,
            }

        },
    })

</script>

<template>

    <div v-if="content.showRequestImmediateAssistance" class="mx-5 mt-5 text-xs">

        <div class="relative flex items-start">

            <div class="flex items-center h-5 pt-2.5">

                <input id="request_immediate_assistance"
                       v-model="requestImmediateAssistance"
                       name="request_immediate_assistance"
                       type="checkbox"
                       value="1"
                       class="focus:ring-indigo-500 h-3 w-3 text-indigo-600 border-gray-300 rounded p-2 primary-text">

            </div>

            <div class="ml-2.5">

                <label for="request_immediate_assistance" class="cursor-pointer inline-block">
                    {{ content.requestImmediateAssistanceText }}
                </label>

            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { WebFormStep, requestImmediateAssistance } from '../../../types/State'

    export default defineComponent({
        name: 'RequestImmediateAssistance',
        props: {
            content: { type: Object as PropType<WebFormStep[ 'content' ]>, required: true },
        },
        setup() {

            return {
                requestImmediateAssistance,
            }

        },
    })

</script>

<template>

    <div v-editable="blok" class="py-10 lg:py-20" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid class="space-y-6 md:flex md:space-x-8 md:space-y-0">

            <div v-if="blok.image.filename" class="flex-1" :class="leftContent" :style="{ marginTop: `-${ blok.offset }%` }">
                <img :src="sbCdnUrl(blok.image.filename)" :alt="blok.image.alt">
            </div>

            <div class="flex-1 flex flex-col justify-center text-center items-center space-y-5" :class="rightContent">

                <h2 :class="[ blok.ctaHeadingFontSize, blok.ctaHeadingSpacingBottom ]"
                    :style="{ color: 'var(--primary-color)' }">
                    {{ blok.ctaHeading }}
                </h2>

                <p class="text-base md:text-xl leading-8 text-gray-600">
                    {{ blok.ctaSubheading }}
                </p>

                <StoryblokComponent v-for="button of blok.button" :key="button._uid" :blok="button"/>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Asset } from '../interfaces/Asset'
    import Grid from '../components/Grid.vue'
    import { FontSize } from '../interfaces/FontSize'
    import { Alignment } from '../interfaces/Alignment'
    import { SbBlokData } from '@storyblok/js'
    import { sbCdnUrl } from '../../../../lib/Helpers'

    interface Block {
        backgroundColor: string,
        ctaHeading: string,
        ctaHeadingFontSize: FontSize,
        ctaHeadingSpacingBottom: string[],
        ctaSubheading: string,
        image: Asset,
        imageAlignment: Alignment,
        offset: number,
        button: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block7',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup(props) {

            return {
                leftContent: computed(() => props.blok.imageAlignment === Alignment.Left ? 'order-1' : 'order-2'),
                rightContent: computed(() => props.blok.imageAlignment === Alignment.Left ? 'order-2' : 'order-1'),
                sbCdnUrl,
            }

        },
    })

</script>

export enum PushButtonTheme {
    Themed = 'themed',
    White = 'white',
    Text = 'text',
    Dark = 'dark',
    Indigo = 'indigo',
    IndigoLight = 'indigo-light',
    IndigoDark = 'indigo-dark',
    Red = 'red',
    RedDark = 'red-dark',
    Yellow = 'yellow',
    Green = 'green',
    Blue = 'blue',
    Purple = 'purple',
    Pink = 'pink',
}

export enum PushButtonSize {
    Smallest = 'xs',
    Small = 'sm',
    Medium = 'md',
    Large = 'lg',
    Largest = 'xl',
}

export enum PushButtonFontSize {
    Smallest = 'text-xs',
    Small = 'text-sm',
    Medium = 'text-base',
    Large = 'text-lg',
    Largest = 'text-xl',
}

export enum PushButtonState {
    Active = 'active',
    Selected = 'selected',
    Loading = 'loading',
    LoadingQuiet = 'loading-quiet',
    Disabled = 'disabled',
}

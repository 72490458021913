<template>

    <div class="-mx-4 sm:mx-0" data-cy="chat-component-wrapper">

        <Component :is="resolve(components[ component.type ], component)"
                   v-for="(component, index) of activeStep.components"
                   :key="`${ component.type }-${ index }`"
                   :component="component"
                   :active-step="activeStep"
                   :disabled="loading || chatCompleted || hasError"
                   :prevent-submit="loading || chatCompleted"
                   :loading="loading"
                   @scroll-chat="scrollChat"
                   @toggle-tcpa-modal="toggleTcpaModal"
                   @submit="submit"/>

    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import { ChatComponent } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import { ChatInputComponent } from '../../lib/enums/components/ChatInputComponent'
    import ChatText from './ChatText.vue'
    import ChatFullName from './ChatFullName.vue'
    import ChatPhoneNumber from './ChatPhoneNumber.vue'
    import ChatDateOfBirth from './ChatDateOfBirth.vue'
    import ChatZipCode from './ChatZipCode.vue'
    import ChatButtonGroup from './ChatButtonGroup.vue'

    type ChatComponents = ((value: ChatComponent) => ChatComponents | void)
        | typeof ChatText
        | typeof ChatFullName
        | typeof ChatPhoneNumber
        | typeof ChatDateOfBirth
        | typeof ChatZipCode
        | typeof ChatButtonGroup

    const components: Record<ChatInputComponent, ChatComponents> = {
        [ ChatInputComponent.Text ]: ChatText,
        [ ChatInputComponent.PhoneNumber ]: ChatPhoneNumber,
        [ ChatInputComponent.FullName ]: ChatFullName,
        [ ChatInputComponent.DateOfBirth ]: ChatDateOfBirth,
        [ ChatInputComponent.Zipcode ]: ChatZipCode,
        [ ChatInputComponent.ButtonGroup ]: ChatButtonGroup,
    }

    function resolve(component: ((data: ChatComponent) => unknown) | unknown, data: ChatComponent) {

        if (typeof component === 'function') {
            return component(data)
        }

        return component

    }

    export default defineComponent({
        name: 'ChatComponents',
        components: {
            ChatText,
            ChatFullName,
            ChatPhoneNumber,
            ChatDateOfBirth,
            ChatZipCode,
            ChatButtonGroup,
        },
        props: {
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            chatCompleted: { type: Boolean, required: true },
            loading: { type: Boolean, required: true },
            hasError: { type: Boolean, required: true },
        },
        emits: [ 'scroll-chat', 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            function scrollChat() {
                emit('scroll-chat')
            }

            function submit(value: string) {
                emit('submit', value)
            }

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            return {
                scrollChat,
                submit,
                toggleTcpaModal,
                resolve,
                components,
            }

        },
    })
</script>

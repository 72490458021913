<template>

    <TransitionBottomToTopFull>

        <div v-if="canShow && show" class="fixed bottom-0 inset-x-0 px-4 z-10 flex">

            <div class="relative max-w-7xl mx-auto bg-white ring-1 ring-gray-200 ring-opacity-50 rounded-md shadow-md mb-3 py-2 px-3">

                <div class="flex items-center justify-between pr-6">

                    <span class="text-center text-xs sm:text-[.8rem]" v-html="consentModal"/>

                </div>

                <div class="absolute right-0 inset-y-0 flex justify-center p-1">

                    <div>

                        <button type="button"
                                class="mt-0.5 mr-1.5 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-text"
                                @click="show = false">

                            <span class="sr-only">Dismiss</span>

                            <Icon icon="mdi:close" class="h-6 w-6"/>

                        </button>

                    </div>

                </div>

            </div>

        </div>

    </TransitionBottomToTopFull>

</template>

<script lang="ts">

    import TransitionBottomToTopFull from '../transitions/TransitionBottomToTopFull.vue'
    import { Icon } from '@iconify/vue'
    import { computed, defineComponent, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { state } from '../../types/State'
    import { LandingPageTemplate } from '../../lib/enums/LandingPageTemplate'
    import { LandingDynamicComponent } from '../../lib/enums/LandingDynamicComponent'
    import { RouteKey } from '../../lib/enums/RouteKey'

    type Block = {
        component: string,
    }

    export default defineComponent({
        name: 'LayoutConsentModal',
        components: { Icon, TransitionBottomToTopFull },
        setup() {

            const show = ref(true)
            const route = useRoute()
            const landingPage = state.webForm.landingPage
            const consentModal = state.webForm.content.consentModalOverride ?? state.domain.content.consentModal

            const landingHasInlineWebForm = computed(() => {

                if (!landingPage) {
                    return false
                }

                if (landingPage.template === LandingPageTemplate.StoryBlok) {

                    if (landingPage.data.contentType !== 'dynamicPage') {
                        return false
                    }

                    return window.__STORY_BLOK.content.blocks.some(
                        (block: Block) => block.component === 'dynamicInlineWebFormBlock',
                    ) ?? false

                }

                if (landingPage.template === LandingPageTemplate.Dynamic) {

                    return landingPage?.data.components.some(
                        component => component.type === LandingDynamicComponent.InlineWebForm,
                    ) ?? false

                }

                return false

            })

            const canShow = computed(() => {

                if (!consentModal?.length) {
                    return false
                }

                if (route.name === RouteKey.WebForm || route.name === RouteKey.InlineWebForm) {
                    return Number(route.params?.step) === 1
                }

                if (route.name === RouteKey.Landing) {
                    return landingHasInlineWebForm.value
                }

                return false

            })

            return {
                show,
                canShow,
                consentModal,
            }

        },
    })

</script>

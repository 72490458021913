<template>

    <div class="video-modal fixed z-30 inset-0 overflow-hidden mt-[65px] sm:mt-0 flex">

        <div class="flex-1 flex items-start sm:items-center justify-center sm:py-8 sm:px-16">

            <Transition enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-active-class="ease-in duration-200"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0">

                <div v-if="active" class="hidden sm:block absolute inset-0 transition-opacity" @click="destroy">

                    <div class="hidden sm:block absolute inset-0 bg-black opacity-75"/>

                    <button type="button" class="close-modal hidden sm:block fixed top-0 right-0 p-3" @click="destroy">

                        <div class="p-3 cursor-pointer rounded-full transition ease-in-out duration-150 bg-gray-500 hover:bg-gray-800">

                            <Icon icon="mdi:close" class="w-6 h-6 text-gray-200"/>

                        </div>

                    </button>

                </div>

            </Transition>

            <Transition enter-active-class="ease-out duration-300"
                        enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-from-class="opacity-100 translate-y-0 sm:scale-100"
                        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        @after-leave="$emit('close')">

                <div v-if="active"
                     class="inline-block text-left overflow-hidden transform transition-all w-full max-w-6xl sm:rounded-[20px] h-full sm:h-auto"
                     role="dialog"
                     aria-modal="true"
                     aria-labelledby="modal-headline"
                     @click="togglePlay">

                    <div class="header hidden bg-white sm:flex justify-between items-center h-24 px-5">

                        <div id="modal-headline" class="text-2xl font-semibold tracking-wide text-[#1D1F2E]">
                            {{ blok.heading }}
                        </div>

                        <div>
                            <a :href="phoneNumber.link"
                               class="rounded-xl w-48 h-14 text-medium font-nunito text-white shadow-sm focus:outline focus:outline-2 focus:outline-offset-2 flex items-center justify-center transition duration-300 space-x-2 tracking-wide"
                               :style="{ backgroundColor, 'outline-color': backgroundColor }"
                               @click="phoneNumber.onClick">

                                <Icon icon="mdi:phone-in-talk" class="w-7 h-7"/>

                                <span class="flex flex-col items-center justify-center whitespace-nowrap">
                                    Call Now
                                </span>

                            </a>
                        </div>

                    </div>

                    <div class="body absolute inset-0 sm:relative z-20 bg-gray-700">

                        <video :ref="(element) => player = element"
                               class="w-auto h-full object-cover sm:w-full sm:h-auto"
                               preload="metadata"
                               autoplay="true"
                               :muted="muted"
                               playsinline="true"
                               @play="play"
                               @ended="pause"
                               @durationchange="setDuration">

                            <source :src="sbCdnUrl(blok.videoFile.filename)" type="video/mp4">

                        </video>

                        <div class="video-controls absolute top-0 sm:top-auto sm:bottom-0 bg-gray-900/10 sm:bg-transparent inset-x-0 py-2 px-6 sm:pt-0 sm:pb-4">

                            <div class="flex sm:hidden justify-end mb-2">

                                <button class="close-modal px-2 py-1 cursor-pointer rounded-full transition ease-in-out duration-150 bg-gray-500/40 text-white w-10 flex items-center justify-center" @click="destroy">

                                    <Icon icon="mdi:close" class="w-5 h-5 text-white"/>

                                </button>

                            </div>

                            <div class="seekbar relative flex-1 h-2 bg-gray-500/40 rounded-full flex items-center">

                                <div class="absolute inset-y-0 rounded-full bg-white pointer-events-none"
                                     :style="{ width: `${ playProgress }%` }"/>

                            </div>

                            <div class="toolbar flex justify-between mt-2">

                                <div class="time">

                                    <span class="font-medium text-white">

                                        {{ formattedCurrent }} / {{ formattedDuration }}

                                    </span>

                                </div>

                                <div class="buttons flex justify-end space-x-2">

                                    <button class="w-10 py-1 text-sm rounded-full transition bg-gray-500/40 text-white flex items-center justify-center hover:bg-gray-500/60 focus:outline-offset-2 focus:outline-gray-500/40 focus:outline-none focus:outline focus:outline-2"
                                            @click.stop="toggleMute">

                                        <Icon v-if="!muted" icon="humbleicons:volume-2" class="w-5 h-5 text-white"/>
                                        <Icon v-if="muted" icon="humbleicons:volume" class="w-5 h-5 text-white"/>

                                    </button>

                                    <button class="w-10 py-1 text-sm rounded-full transition bg-gray-500/40 text-white flex items-center justify-center hover:bg-gray-500/60 focus:outline-offset-2 focus:outline-gray-500/40 focus:outline-none focus:outline focus:outline-2"
                                            @click.stop="togglePlaybackRate">
                                        {{ playbackRate }}x
                                    </button>

                                    <button class="w-10 py-1 text-sm rounded-full transition bg-gray-500/40 text-white flex items-center justify-center hover:bg-gray-500/60 focus:outline-offset-2 focus:outline-gray-500/40 focus:outline-none focus:outline focus:outline-2" @click.stop="enterFullScreen">
                                        <Icon icon="humbleicons:maximize" class="w-5 h-5 text-white"/>
                                    </button>

                                </div>

                            </div>

                            <div v-if="blok.heading.length" class="video-banner inline-block sm:hidden relative py-0.5 mt-2 px-2">

                                <div class="absolute inset-0 bg-[#E0EAFB] -skew-x-[20deg] z-0"/>

                                <span class="leading-none text-sm font-semibold text-[#1E40AF] relative z-10">
                                    {{ blok.heading }}
                                </span>

                            </div>

                        </div>

                        <TransitionFadeIn>

                            <div v-if="!playing" class="video-play-button absolute inset-0 flex items-center justify-center bg-gray-900/50">

                                <div class="rounded-full p-1 bg-white/20">

                                    <div class="rounded-full p-1 bg-white/20">

                                        <div class="rounded-full w-16 h-16 sm:w-[90px] sm:h-[90px] bg-white flex items-center justify-center group-hover:bg-blue-50 transition duration-500">

                                            <PlayIcon class="h-6 w-6 sm:w-8 sm:h-8 text-[#DC2647] group-hover:text-red-500 transition"/>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </TransitionFadeIn>

                        <div class="video-cta fixed mb-6 sm:mb-24 inset-x-0 px-8 sm:px-0"
                             :class="[
                                 blok.heading.length ? 'mt-[8.75rem]' : 'mt-[6.5rem]',
                                 {
                                     'bottom-0': [ '', 'bottom' ].includes(blok.buttonPositionMobile),
                                     'sm:bottom-0': [ '', 'bottom' ].includes(blok.buttonPositionDesktop),
                                     'top-0': [ 'top' ].includes(blok.buttonPositionMobile),
                                     'sm:top-0': [ 'top' ].includes(blok.buttonPositionDesktop),
                                 },
                             ]">

                            <div :class="[ blok.marginTop, blok.marginBottom ]">

                                <VideoClickToCall :background-color="backgroundColor"/>

                            </div>

                        </div>

                    </div>

                </div>

            </Transition>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType, ref, watch } from 'vue'
    import { Ringba } from '../../lib/Ringba'
    import { Icon } from '@iconify/vue'
    import { VideoComponent } from '../../../client/types/Landing/Components'
    import VideoClickToCall from '../../components/buttons/VideoClickToCall.vue'
    import PlayIcon from '../../components/icons/PlayIcon.vue'
    import TransitionFadeIn from '../transitions/TransitionFadeIn.vue'
    import { sbCdnUrl } from '../../lib/Helpers'

    export default defineComponent({
        name: 'VideoModal',
        components: { Icon, PlayIcon, VideoClickToCall, TransitionFadeIn },
        props: {
            blok: { type: Object as PropType<VideoComponent>, default: null },
            openedOnPageLoad: { type: Boolean },
        },
        emits: {
            close: () => true,
        },
        setup({ blok, openedOnPageLoad }) {

            const active = ref<boolean>(true)
            const phoneNumber = new Ringba().getPhoneNumber()
            const player = ref<HTMLVideoElement>()
            const duration = ref<number>(0)
            const playing = ref<boolean>(true)
            const muted = ref<boolean>(openedOnPageLoad || false)
            const interval = ref<number | undefined>()
            const playbackRate = ref<number>(1)
            const currentSeconds = ref<number | undefined>()
            const playProgress = computed(() => currentSeconds.value / duration.value * 100)
            const backgroundColor = blok.buttonColor?.color || '#DC2647'

            const formattedDuration = computed(() => {

                const value = Math.round(duration.value || 0)
                const minutes = Math.floor(value / 60).toString().padStart(2, '0')
                const seconds = Math.floor(value % 60).toString().padStart(2, '0')

                return `${ minutes }:${ seconds }`

            })

            const formattedCurrent = computed(() => {

                const value = Math.round(currentSeconds.value || 0)
                const minutes = Math.floor(value / 60).toString().padStart(2, '0')
                const seconds = Number(value % 60).toString().padStart(2, '0')

                return `${ minutes }:${ seconds }`

            })

            watch(() => playing.value, isPlaying => {
                isPlaying ? player.value?.play() : player.value?.pause()
            })

            function destroy() {

                active.value = false
                pause()

            }

            function play() {

                playing.value = true

                if (interval.value) {
                    clearInterval(interval.value)
                }

                interval.value = setInterval(() => {
                    currentSeconds.value = player.value?.currentTime
                }, 100)

            }

            function pause() {

                playing.value = false
                clearInterval(interval.value)

            }

            function togglePlay() {

                if (openedOnPageLoad && muted.value === true) {
                    return unmute()
                }

                playing.value === true ? pause() : play()

            }

            function setDuration(event: Event) {
                duration.value = event.target.duration || 0
            }

            function toggleMute() {

                muted.value = !muted.value
                player.value.muted = muted.value

            }

            function unmute() {

                muted.value = false
                player.value.muted = false

            }

            function enterFullScreen() {

                if (player.value?.requestFullscreen) {
                    player.value.requestFullscreen()
                } else if (player.value.mozRequestFullScreen) {
                    player.value.mozRequestFullScreen()
                } else if (player.value.webkitRequestFullscreen) {
                    player.value.webkitRequestFullscreen()
                } else if (player.value.webkitEnterFullscreen) {
                    player.value.webkitEnterFullscreen()
                } else if (player.value.msRequestFullscreen) {
                    player.value.msRequestFullscreen()
                }

            }

            function togglePlaybackRate() {

                playbackRate.value === 1 ? playbackRate.value = 1.25 : playbackRate.value = 1
                player.value.playbackRate = playbackRate.value

            }

            return {
                active,
                destroy,
                phoneNumber,
                player,
                setDuration,
                playing,
                play,
                pause,
                togglePlay,
                playProgress,
                formattedDuration,
                formattedCurrent,
                toggleMute,
                muted,
                enterFullScreen,
                togglePlaybackRate,
                playbackRate,
                sbCdnUrl,
                backgroundColor,
            }

        },
    })

</script>

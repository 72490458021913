<template>

    <Transition :appear="appear"
                mode="out-in"
                :enter-active-class="enterActive"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="ease-in duration-200"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">

        <slot/>

    </Transition>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'TransitionScaleIn',
        props: {
            appear: { type: Boolean },
            enterActive: {
                type: String,
                default: 'transition ease-out delay-200 duration-500',
            },
        },
    })

</script>

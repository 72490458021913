<template>

    <TransitionBottomToTop appear>

        <div v-if="!loading && !wasClicked" class="mx-4 sm:mx-0" data-cy="button-group-input">

            <!-- Button Group -->
            <div class="flex sm:pl-16">

                <div class="pt-6 pb-4 text-left flex flex-col flex-grow gap-3">

                    <p class="text-[#5F6275] text-sm">
                        Select prefilled answer below:
                    </p>

                    <div class="hidden sm:flex flex-wrap max-w-xs w-full gap-4">

                        <div v-for="option of component.data.options"
                             :key="option.value">

                            <label class="rounded-lg bg-white hover:border-gray-300 relative flex items-center cursor-pointer border border-[#E5E7EB] px-3 py-3 focus:outline-none shadow-lg">

                                <input type="radio"
                                       name="button_group"
                                       :value="option.value"
                                       class="mt-0.5 h-5 w-5 shrink-0 cursor-pointer primary-text border-[#E5E7EB] focus:primary-ring active:ring-2 active:ring-offset-2 active:primary-ring"
                                       :aria-labelledby="option.label"
                                       :aria-describedby="option.label"
                                       @change="submit(option.label)">

                                <span class="ml-3 block whitespace-nowrap text-black  leading-none">
                                    {{ option.label }}
                                </span>

                            </label>

                        </div>

                    </div>

                    <div class="sm:hidden flex flex-col gap-3">

                        <button v-for="option of component.data.options"
                                :key="option.value"
                                class="font-semibold w-full sm:w-fit px-4 py-2 rounded-lg bg-[#1E40AF1A] text-[#1E40AF] hover:bg-[#1E40AF33]"
                                @click="submit(option.label)">

                            {{ option.label }}

                        </button>

                    </div>

                </div>

            </div>

        </div>

    </TransitionBottomToTop>

</template>

<script lang="ts">
    import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
    import { ButtonGroup } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import TransitionBottomToTop from '../transitions/TransitionBottomToTop.vue'

    export default defineComponent({
        name: 'ChatButtonGroup',
        components: {
            TransitionBottomToTop,
        },
        props: {
            component: { type: Object as PropType<ButtonGroup>, required: true },
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            loading: { type: Boolean, required: false },
        },
        emits: [ 'submit', 'scroll-chat' ],
        setup(props, { emit }) {

            onMounted(() => {
                emit('scroll-chat')
            })

            const wasClicked = ref(false)

            function submit(value: string): void {

                wasClicked.value = true
                emit('submit', value)

            }

            watch(() => props.activeStep, () => {
                wasClicked.value = false
            })

            return {
                submit,
                wasClicked,
            }

        },
    })
</script>

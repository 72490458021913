<template>

    <div class="md:w-3/5 flex flex-col text-center items-center space-y-8">

        <div class="bg-white p-6 border rounded-lg shadow-md space-y-6 flex flex-col">

            <h1 class="mt-4 px-2 self-center" :class="titleClass">
                {{ data.title }}
            </h1>

            <h3 v-if="data.subtitle" class="mt-4 px-2" :class="subtitleClass">
                {{ data.subtitle }}
            </h3>

            <div v-if="data.checklist.length" class="p-4 text-lg justify-center flex ">

                <ul class="flex flex-col space-y-4">

                    <li v-for="item of data.checklist" :key="item" class="flex space-x-4">

                        <div class="mt-0.5">
                            <Icon icon="akar-icons:circle-check-fill" class="i-mdi-check-bold primary-text w-6 h-6"/>
                        </div>

                        <div class=" items-start text-left" v-html="item"/>

                    </li>

                </ul>

            </div>

            <div v-if="data.liveAgentText" class="font-bold flex items-center justify-center">

                <div class="relative w-2.5 h-2.5 mr-4">
                    <div class="absolute top-0 left-0 bg-green-400 rounded-full w-2.5 h-2.5 "/>
                    <div class="absolute bg-green-400 animate-ping rounded-full w-2.5 h-2.5 "/>
                </div>

                <div class="whitespace-nowrap uppercase">
                    {{ data.liveAgentText }}
                </div>

            </div>

            <ClickToCall :text="data.button"/>

            <div class="uppercase">

                <div class="font-bold text-lg">
                    TTY users 711
                </div>

                <div class="text-gray-700 text-xs font-semibold tracking-widest">
                    {{ phoneText }}
                </div>

            </div>

        </div>

        <div v-if="!isPast && data.timerActive && timerEndDate" class="mt-8 max-w-md mx-auto">

            <h1 v-if="data.timerTitle" class="uppercase tracking-widest text-gray-800 text-lg font-semibold">
                {{ data.timerTitle }}
            </h1>

            <div class="border-t border-gray-300 my-2"/>

            <FormatCountDown :date="timerEndDate"/>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Icon } from '@iconify/vue'
    import { ClassicC2CLandingPage } from '../../types/LandingPage'
    import { RouteKey } from '../../lib/enums/RouteKey'
    import { PushButtonSize } from '../../lib/enums/PushButton'
    import FormatCountDown from '../format/FormatCountDown.vue'
    import { addMinutes, isPast, parseISO } from 'date-fns'
    import { state } from '../../types/State'
    import ClickToCall from '../buttons/ClickToCall.vue'
    import { TimerType } from '../../lib/enums/TimerType'

    export default defineComponent({
        name: 'ClassicC2C',
        components: { ClickToCall, FormatCountDown, Icon },
        props: {
            data: { type: Object as PropType<ClassicC2CLandingPage['data']>, required: true },
        },
        setup({ data }) {

            const titleClass = computed(() => data?.style?.title ? data.style.title : 'text-4xl font-bold')
            const subtitleClass = computed(() => data?.style?.subtitle ? data.style.subtitle : 'text-2xl font-medium')

            let timerEndDate: Date | null = null

            if (data.timerType === TimerType.Fixed) {
                timerEndDate = parseISO(data.timerEndDate)
            }

            if (data.timerType === TimerType.Recurring) {
                timerEndDate = addMinutes(new Date(), data.timerDuration)
            }

            return {
                RouteKey,
                PushButtonSize,
                titleClass,
                subtitleClass,
                timerEndDate,
                phoneText: data.phoneText ?? state.webForm.content.phoneText ?? state.domain.content.phoneText,
                isPast: timerEndDate ? isPast(timerEndDate) : null,
            }

        },
    })

</script>

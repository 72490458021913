<template>

    <div class="inline-flex flex-col text-center">

        <PushButton :size="blok.size" class="justify-center" @click="run">
            {{ blok.text }}
        </PushButton>

        <PhoneBox v-if="showPhoneNumber" :text="blok.tollFreeText" class="px-4 mt-4"/>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { useAction } from '../../../Dynamic/Components/Compositions/useActions'
    import { Action } from '../../../Dynamic/Enums/Action'
    import PushButton from '../../../../buttons/PushButton.vue'
    import { PushButtonSize } from '../../../../../lib/enums/PushButton'
    import PhoneBox from '../../../../web_forms/content/PhoneBox.vue'

    interface Block {
        text: string,
        tollFreeText: string,
        step: number,
        size: PushButtonSize,
        hidePhoneNumber?: boolean,
    }

    export default defineComponent({
        name: 'StartWebFormButton',
        components: { PhoneBox, PushButton },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup(props) {

            const { run } = useAction({
                type: Action.StartWebForm, data: { step: props.blok.step },
            })

            const showPhoneNumber = computed(() => props.blok.hidePhoneNumber === undefined || !props.blok.hidePhoneNumber)

            return {
                run,
                showPhoneNumber,
            }

        },
    })

</script>

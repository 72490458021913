<template>

    <div v-if="component.data.displayAsButtons" class="grid gap-2 w-full max-w-sm mx-auto pb-4">

        <label v-for="(option, key) of component.data.options"
               :key="key"
               class="flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1 cursor-pointer focus:outline-none border transition secondary-border"
               data-cy="tick-box-button"
               :class="[
                   model.includes(option.value)
                       ? 'secondary-bg text-white hover:secondary-bg hover:opacity-90'
                       : 'bg-white text-gray-900 hover:bg-gray-50',
               ]">

            <input type="checkbox"
                   class="sr-only"
                   :value="option.value"
                   :checked="model.includes(option.value)"
                   :aria-labelledby="`option-${ key }-label`"
                   @change="change">

            <span :id="`option-${ key }-label`"> {{ option.label }}</span>

        </label>

    </div>

    <div v-else class="grid grid-cols-2 gap-2">

        <div v-for="(option, key) of component.data.options" :key="key">

            <div class="relative flex items-center">

                <div class="flex items-center h-5">

                    <input :id="option.value"
                           :value="option.value"
                           type="checkbox"
                           class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded p-2.5 primary-text"
                           :checked="model.includes(option.value)"
                           @change="change">

                </div>

                <div class="ml-2.5">

                    <label :for="option.value" class="font-medium text-gray-700">
                        {{ option.label }}
                    </label>

                </div>

            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import { TickBox } from '../../types/Components'
    import { restoreState } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepTickBox',
        props: {
            component: { type: Object as PropType<TickBox>, required: true },
        },
        setup({ component }) {

            const model = ref<string[]>([])

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function focus(): void {
                // Nothing to do
            }

            function isValid(): boolean {

                if (model.value.length > 0) {
                    return true
                }

                return component.data.required === false

            }

            function change(event: Event) {

                if (event.target instanceof HTMLInputElement) {

                    event.target.checked
                        ? model.value.push(event.target.value)
                        : model.value.splice(model.value.indexOf(event.target.value), 1)

                }

            }

            return {
                model,
                fill,
                focus,
                isValid,
                change,
            }

        },
    })

</script>

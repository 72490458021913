<template>

    <span v-html="left"/>

    <template v-if="right">

        <a :href="phoneNumber.link" class="font-bold primary-text" @click="phoneNumber.onClick">
            {{ phoneNumber.dashed }}
        </a>

        <span v-html="right"/>

    </template>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { Ringba } from '../../lib/Ringba'

    export default defineComponent({
        name: 'PhoneAware',
        props: {
            text: { type: String, required: true },
        },
        setup(props) {

            const phoneNumber = new Ringba().getPhoneNumber()
            const [ left, right ] = props.text.split('{number}')

            return {
                phoneNumber,
                left,
                right,
            }

        },
    })

</script>

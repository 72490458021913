<template>

    <FormInputComponent ref="inputReference"
                        v-model="model"
                        :component="component"
                        :prefix="component.data.type === 'currency' ? '$' : undefined"
                        :extra-attributes="{
                            type: inputType,
                            inputmode: inputMode,
                        }"/>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import FormInputComponent from '../form/FormInputComponent.vue'
    import { Text } from '../../types/Components'
    import { isValidEmail, restoreState } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepTextInput',
        components: { FormInputComponent },
        props: {
            component: { type: Object as PropType<Text>, required: true },
        },
        setup({ component }) {

            const model = ref<string | null>()
            const inputReference = ref<InstanceType<typeof FormInputComponent>>()

            const inputTypeMapping = {
                text: 'text',
                email: 'email',
                number: 'number',
                currency: 'number',
                textarea: 'textarea',
            }

            const inputModeMapping = {
                text: 'text',
                email: 'email',
                number: 'numeric',
                currency: 'numeric',
                textarea: 'text',
            }

            const inputType = inputTypeMapping[ component.data.type ]
            const inputMode = inputModeMapping[ component.data.type ]

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function focus(): void {
                inputReference.value?.input?.focus()
            }

            function isValid(): boolean {

                if (model.value) {

                    if (component.data.type === 'email' && isValidEmail(model.value) === false) {
                        return false
                    }

                    return true

                }

                return component.data.required === false

            }

            return {
                inputReference,
                model,
                fill,
                focus,
                isValid,
                inputType,
                inputMode,
            }

        },
    })

</script>

<template>
    <div class="mb-2">

        <div class="relative flex">
            <div class="h-5">
                <input id="confirmation-checkbox"
                       v-model="model"
                       :value="true"
                       type="checkbox"
                       data-cy="confirmation-checkbox"
                       class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded primary-text"
                       :class="checkboxSize">
            </div>

            <div class="ml-2.5" :class="component.data.checkboxTextAlignment">
                <label data-cy="checkbox-text"
                       for="confirmation-checkbox"
                       class="font-medium text-gray-700"
                       :class="component.data.checkboxFontSize">
                    {{ replaceTokens(component.data.checkboxText, { date: appointmentDate }) }}
                </label>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import { AppointmentConfirmation } from '../../types/Components'
    import { restoreState } from '../../lib/Helpers'
    import { replaceTokens } from '../../lib/Helpers'
    import { format, addDays, isSaturday, isSunday } from 'date-fns'
    import { PushButtonFontSize } from '../../lib/enums/PushButton'

    export default defineComponent({
        name: 'StepAppointmentConfirmation',
        props: {
            component: {
                type: Object as PropType<AppointmentConfirmation>,
                required: true,
            },
        },
        setup({ component }) {

            const model = ref<boolean>(true)

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function focus(): void {
                // Nothing to do
            }

            function isValid(): boolean {
                return true
            }

            function change(event: Event) {

                if (event.target instanceof HTMLInputElement) {
                    event.target.checked ? (model.value = true) : (model.value = false)
                }

            }

            function getDaysAhead(daysAhead: number, includeWeekends: boolean) {

                let resultDate = addDays(new Date(), daysAhead)

                if (includeWeekends === false) {

                    if (isSaturday(resultDate)) {
                        resultDate = addDays(resultDate, 2)
                    } else if (isSunday(resultDate)) {
                        resultDate = addDays(resultDate, 1)
                    }

                }

                return format(resultDate, 'MMMM d, yyyy')

            }

            const appointmentDate = getDaysAhead(component.data.daysAhead, component.data.includeWeekends)

            function getCheckboxSize(fontSize: string): string {

                if (fontSize === PushButtonFontSize.Smallest) {
                    return 'p-1'
                }

                if (fontSize === PushButtonFontSize.Small) {
                    return 'p-2'
                }

                if (fontSize === PushButtonFontSize.Large) {
                    return 'p-3'
                }

                if (fontSize === PushButtonFontSize.Largest) {
                    return 'p-4'
                }

                return 'p-2.5'

            }

            const checkboxSize = getCheckboxSize(component.data.checkboxFontSize)

            return {
                model,
                fill,
                focus,
                isValid,
                change,
                replaceTokens,
                appointmentDate,
                checkboxSize,
            }

        },
    })
</script>

import { Ref } from 'vue'
import { DomainResource, ThankYouPageResource } from '../types/resources/ThankYouPageResource'
import { SuccessResource } from '../types/resources/SuccessResource'
import { ErrorResource } from '../types/resources/ErrorResource'
import { ZipcodeResource } from '../types/resources/ZipcodeResource'
import { err, ok, Result } from 'neverthrow'
import { Prefill, prefill, State } from '../types/State'
import { postMessage } from './Helpers'

export class Api {

    private readonly token: string
    public visitor: Ref<Prefill>

    constructor() {

        this.token = this.getCSRFToken()
        this.visitor = prefill

    }

    private url(url: string) {

        return window.__STATE.domain.content.path
            ? `/${ window.__STATE.domain.content.path }/${ url }`
            : `/${ url }`

    }

    public async get<T>(url: string) {

        return this.handleResponse<T, ErrorResource>(
            fetch(this.url(url), {
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    'x-csrf-token': this.token,
                },
            }),
        )

    }

    public async post<T>(url: string, data: Record<string, unknown>) {

        return this.handleResponse<T, ErrorResource>(
            fetch(this.url(url), {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    'x-csrf-token': this.token,
                },
            }),
        )

    }

    public getZipcode(zipcode: string | number) {
        return this.get<ZipcodeResource>(`lookup/${ zipcode }`)
    }

    public contactUs(data: Record<string, unknown>) {
        return this.post<SuccessResource>('contact-us', data)
    }

    public updateVisitor(visitor: string, group: number, data: Record<string, unknown>) {

        this.updatePrefillState(data)
        postMessage('updateVisitor', JSON.parse(JSON.stringify(this.visitor.value)))

        return this.post<SuccessResource[ State[ 'webForm' ][ 'formPlanner' ] ]>(
            `update-visitor/${ visitor }/${ group }`, data,
        )

    }

    public updateVisitorData(visitor: string, data: Record<string, unknown>) {

        this.updatePrefillState(data)
        postMessage('updateVisitor', JSON.parse(JSON.stringify(this.visitor.value)))

        return this.post<SuccessResource>(
            `update-visitor-data/${ visitor }`, data,
        )

    }

    public updateLeadData(visitor: string, data: Record<string, unknown>) {

        return this.post<SuccessResource>(
            `update-lead-data/${ visitor }`, data,
        )

    }

    public completeWebForm(visitor: string) {

        postMessage('completeForm')

        return this.post<ThankYouPageResource>(`complete-form/${ visitor }`, {})

    }

    public getLoadingPage(visitor: string) {
        return this.get<DomainResource>(`loading-page/${ visitor }`)
    }

    public getThankYouPage(visitor: string) {

        postMessage('thankYou')

        return this.get<ThankYouPageResource>(`thank-you/${ visitor }`)

    }

    public rotateThankYouPage(visitor: string) {
        return this.get<ThankYouPageResource>(`thank-you/${ visitor }/rotate`)
    }

    public confirmThankYouPage(visitor: string, thankYouPageId: number) {
        return this.post<SuccessResource>(`thank-you/${ visitor }/${ thankYouPageId }/confirm`)
    }

    public updateConversation(visitor: string, data: Record<string, unknown>) {
        return this.post<SuccessResource>(`update-visitor-conversation/${ visitor }/`, data)
    }

    public getConversationData(visitor: string, fromMessageId: number) {
        return this.get<SuccessResource>(`visitor-conversation-data/${ visitor }/?fromMessageId=${ fromMessageId }`)
    }

    public getCarMakesForYear(year: string) {
        return this.get<SuccessResource>(`vehicles/car-model-years/${ year }/makes`)
    }

    public getRecommendedCarModelsByMakeAndYear(make: string, year: number, limit = 10) {
        return this.get<SuccessResource>(`vehicles/car-makes/${ make }/models/year/${ year }/recommended?limit=${ limit }`)
    }

    public getCarModelsByMakeAndYear(make: string, year: number, limit = 1000) {
        return this.get<SuccessResource>(`vehicles/car-makes/${ make }/models/year/${ year }?limit=${ limit }`)
    }

    private getCSRFToken(): string {

        const meta = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement

        return meta.content

    }

    private async handleResponse<S, E>(fetch: Promise<Response>): Promise<Result<S, E>> {

        try {

            const response = await fetch

            if (response.status === 204) {
                return ok(null as unknown as S)
            }

            const value = await response.json()

            if (response.ok && value.success) {
                return ok(value)
            }

            return err(value)

        } catch (error) {
            return err(error as E)
        }

    }

    private updatePrefillState(formData: Record<string, unknown>): void {

        const prefillData = Object.assign({}, formData)
        const blacklistKeys = [ 'address', 'offset' ]

        // Forget keys that are not needed for prefill
        for (const key of blacklistKeys) {
            delete prefillData[ key ]
        }

        this.visitor.value = Object.assign(this.visitor.value, {
            ...this.visitor.value,
            ...formData,
            data: { ...this.visitor.value.data, ...prefillData },
        })

    }

}

<template>

    <WebForm>

        <StepComponents :inline-web-form="true"/>

    </WebForm>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { InlineWebFormComponent } from '../../../../types/Landing/Components'
    import WebForm from '../../../../pages/WebForm.vue'
    import StepComponents from '../../../../components/steps/StepComponents.vue'

    export default defineComponent({
        name: 'InlineWebForm',
        components: {
            WebForm,
            StepComponents,
        },
        props: {
            data: { type: Object as PropType<InlineWebFormComponent>, required: true },
        },
    })

</script>

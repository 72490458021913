<template>

    <div class="max-w-5xl w-full mx-auto sm:px-6 lg:px-8 h-full">

        <div class="px-4 h-full">
            <slot/>
        </div>

        <slot name="footer"/>

        <slot name="consent"/>

    </div>

</template>

<template>

    <div class="inline-flex flex-col text-center justify-center">

        <LayoutPhoneBox :text="blok.tollFreeText" :number="number" class="px-4 mt-4" :custom-text-color="brandTextColor"/>

        <PushButton :size="blok.size" class="justify-center mt-6 !p-0" :theme="theme" @click="run">
            <span class="text-sm py-3 px-4"> {{ blok.text }} </span>
        </PushButton>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { useAction } from '../../../Dynamic/Components/Compositions/useActions'
    import { Action } from '../../../Dynamic/Enums/Action'
    import PushButton from '../../../../buttons/PushButton.vue'
    import { PushButtonSize, PushButtonTheme } from '../../../../../lib/enums/PushButton'
    import { Ringba } from '../../../../../lib/Ringba'
    import { ClickActionAware } from '../../../../../types/Landing/ClickActionAware'
    import LayoutPhoneBox from '../../../../layout/LayoutPhoneBox.vue'

    interface Block {
        text: string,
        tollFreeText: string,
        size: PushButtonSize,
    }

    export default defineComponent({
        name: 'BrandedRingbaButton',
        components: { LayoutPhoneBox, PushButton },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
            theme: { type: String as PropType<PushButtonTheme>, default: null },
            brandTextColor: { type: String, default: null },
        },
        setup() {

            const number = new Ringba().getPhoneNumber()

            const { run } = useAction<ClickActionAware>({
                type: Action.TriggerDialer,
                data: { phone: number },
            })

            return {
                run,
                number,
            }

        },
    })

</script>

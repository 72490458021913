import { ref, Ref, watch } from 'vue'
import { mergeDeep } from '../Helpers'
import { encrypt, decrypt } from '../Crypto'

function parseJson(value: string): object {

    try {
        return JSON.parse(value)
    } catch (error) {
        return {}
    }

}

function setLocalStorage(key: string, data: unknown, secret: string): void {
    localStorage.setItem(key, encrypt(JSON.stringify(data), secret))
}

export default <T>(key: string, defaultData: T = {} as T, secret: string): Ref<T> => {

    let mergedData: object = defaultData as object

    try {

        const localStorageData: object = parseJson(
            decrypt(localStorage.getItem(key) || '{}', secret),
        )

        mergedData = mergeDeep(localStorageData, defaultData as object)

    } catch (error) {
        console.log(error)
    }

    setLocalStorage(key, mergedData, secret)

    const data: Ref<unknown> = ref(mergedData)

    watch(
        data,
        to => {
            setLocalStorage(key, to, secret)
        },
        { deep: true },

    )

    return data as Ref<T>

}

<template>

    <div v-editable="blok" class="dynamic-page flex flex-col items-center w-full py-8">

        <StoryblokComponent v-for="block of blok.blocks"
                            :key="block._uid"
                            :blok="block"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType, onMounted } from 'vue'
    import { StoryblokComponent } from '@storyblok/vue'
    import { isStoryBlokLandingActive } from '../../../types/State'

    interface Block {
        blocks: typeof StoryblokComponent[],
    }

    export default defineComponent({
        name: 'DynamicThankYouPage',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup() {

            onMounted(() => {
                isStoryBlokLandingActive.value = true
            })

        },
    })

</script>

<template>
    <a :href="number.link" class="flex items-center justify-center space-x-2" @click="number.onClick">

        <div class="hidden lg:flex">

            <Icon icon="mdi:phone-in-talk" class="w-8 h-8" :class="defaultColor" :style="customTextColor ? { color: customTextColor } : {}"/>

            <div class="relative w-2.5 h-2.5 -ml-1">
                <div class="absolute top-0 left-0 bg-green-400 rounded-full w-2 h-2 mr-2.5"/>
                <div class="absolute bg-green-400 animate-ping rounded-full w-2 h-2 mr-2.5"/>
            </div>

        </div>

        <div class="flex flex-col items-center">

            <div class="text-gray-700 text-xs font-semibold tracking-widest uppercase">
                {{ text }}
            </div>

            <div class="flex items-center" :class="defaultColor" :style="customTextColor ? { color: customTextColor } : {}">

                <div class="bg-green-400 rounded-full w-2 h-2 mr-2.5 lg:hidden"/>
                <div class="absolute bg-green-400 animate-ping rounded-full w-2 h-2 mr-2.5 lg:hidden"/>

                <div class="flex space-x-1 items-center text-xl whitespace-nowrap">
                    <span class="font-bold tracking-tighter">{{ number.dashed }}</span>
                    <span class="font-semibold">(TTY {{ number.tty }})</span>
                </div>

            </div>

        </div>

    </a>
</template>

<script lang="ts">
    import { Icon } from '@iconify/vue'
    import { PhoneNumber } from '../../lib/Ringba'
    import { PropType } from 'vue'

    export default {
        name: 'LayoutPhoneBox',
        components: { Icon },
        props: {
            number: { type: Object as PropType<PhoneNumber>, required: true },
            text: { type: String, required: true },
            customTextColor: { type: String, default: null },
        },
        setup(props) {

            const defaultColor = props.customTextColor ? '' : 'primary-text-dark'

            return {
                defaultColor,
            }

        },
    }
</script>

<template>

    <input :id="component.data.id"
           ref="inputReference"
           v-model="model"
           type="hidden"
           :name="component.data.name">

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import { HiddenInput } from '../../types/Components'
    import { restoreState } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepHiddenInput',
        props: {
            component: { type: Object as PropType<HiddenInput>, required: true },
        },
        setup({ component }) {

            const inputReference = ref<HTMLInputElement>()
            const model = ref<string | undefined>(component.data.value)

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function isValid(): boolean {
                return true
            }

            return {
                inputReference,
                model,
                fill,
                isValid,
            }

        },
    })

</script>

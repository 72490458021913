<template>

    <div class="p-6 bg-white rounded-xl flex flex-col">

        <div class="text-4xl leading-10 font-extrabold md:font-bold" :style="{ color: 'var(--primary-color)' }">
            {{ blok.title }}
        </div>

        <div v-if="blok.subtitle" class="mt-2 text-xl leading-7 font-semibold text-gray-600">
            {{ blok.subtitle }}
        </div>

        <div class="my-2 text-base leading-6 text-gray-500">
            {{ blok.description }}
        </div>

        <a v-if="blok.callToAction"
           :href="blok.url?.url"
           target="_blank"
           class="mt-auto flex items-center space-x-3 text-gray-900">

            <span class="text-base leading-6 font-bold tracking-wider">{{ blok.callToAction }}</span>

            <svg class="mt-1"
                 width="28"
                 height="11"
                 viewBox="0 0 28 11"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <line x1="1"
                      y1="5.69983"
                      x2="25"
                      y2="5.69983"
                      stroke="#111827"
                      stroke-width="2"
                      stroke-linecap="round"/>
                <path d="M21.9819 1.5L26.0001 5.51818L21.9819 9.53636"
                      stroke="#111827"
                      stroke-width="2"
                      stroke-linecap="round"/>
            </svg>

        </a>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Url } from '../../interfaces/Url'

    interface Block {
        title: string,
        subtitle?: string,
        description: string,
        callToAction?: string,
        url?: Url,
    }

    export default defineComponent({
        name: 'Card2',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

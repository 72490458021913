<template>

    <ChatInputHeader/>

    <div class="flex w-full justify-between relative pb-0.5 sm:pb-4" data-cy="chat-input">
        <input v-if="requiresMask"
               ref="input"
               v-model="message"
               v-bind="inputProperties"
               v-maska="inputProperties.mask"
               :disabled="disabled"
               class="w-full sm:flex-grow bg-white border border-[#E5E7EB] focus:border-[#1E40AF] sm:rounded-lg px-2 sm:pl-6 sm:pr-4 py-6 resize-none focus:outline-none focus:ring-4 focus:ring-[#1E40AF1A] placeholder:text-gray-300 disabled:cursor-pointer"
               @keydown.enter.exact.prevent="submit">
        <input v-else
               ref="input"
               v-model="message"
               v-bind="inputProperties"
               :disabled="disabled"
               class="w-full sm:flex-grow bg-white border border-[#E5E7EB] focus:border-[#1E40AF] sm:rounded-lg px-2 sm:pl-6 sm:pr-4 py-6 resize-none focus:outline-none focus:ring-4 focus:ring-[#1E40AF1A] placeholder:text-gray-300 disabled:cursor-pointer"
               @keydown.enter.exact.prevent="submit">
        <div class="absolute inset-y-0 sm:pb-4 right-0 pr-3 flex items-center">
            <button type="submit"
                    :disabled="disableButton"
                    class="w-12 h-12 bg-[#DC2626] rounded-full shadow-md flex items-center justify-center"
                    :class="{
                        'disabled:bg-gray-200 disabled:text-gray-400': disableButton,
                    }"
                    aria-label="Send Message"
                    @click.prevent="submit">
                <ChatIcon/>
            </button>
        </div>
    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
    import ChatIcon from './ChatIcon.vue'
    import ChatInputHeader from './ChatInputHeader.vue'
    import { InputComponent } from '../../lib/enums/components/InputComponent'
    import { maska } from 'maska'
    import { state, WebFormStep } from '../../types/State'

    interface Properties {
        mask: string | null,
        placeholder: string,
        inputMode: string,
        name?: string,
        autocomplete?: string,
    }

    type InputType =
        InputComponent.Text |
        InputComponent.DateOfBirth |
        InputComponent.Zipcode |
        InputComponent.PhoneNumber

    type InputProperties = {
        [K in InputType]: Properties
    }

    export default defineComponent({
        name: 'ChatInput',
        components: { ChatInputHeader, ChatIcon },
        directives: { maska },
        props: {
            preventSubmit: {
                type: Boolean,
            },
            disabled: {
                type: Boolean,
            },
            activeStep: {
                type: Object as PropType<WebFormStep>,
                required: true,
            },
        },
        emits: [ 'submit' ],
        setup(props, { emit }) {

            const useMaskedInputs = computed(() => state.webForm.content.useMaskedInputs)

            const standardPhoneProperties: Properties = {
                placeholder: 'Enter your phone number',
                mask: null,
                inputMode: 'text',
                name: 'phone',
                autocomplete: 'tel',
            }

            const maskedPhoneProperties: Properties = {
                placeholder: '(###) ###-####',
                mask: '(###) ###-####',
                inputMode: 'numeric',
                name: 'phone',
                autocomplete: 'tel',
            }

            const properties: InputProperties = {
                [ InputComponent.Text ]: {
                    placeholder: 'Enter your answer',
                    mask: null,
                    inputMode: 'text',
                },
                [ InputComponent.DateOfBirth ]: {
                    placeholder: 'MM/DD/YYYY',
                    mask: '##/##/####',
                    inputMode: 'numeric',
                },
                [ InputComponent.Zipcode ]: {
                    placeholder: 'Ex. 12345',
                    mask: '#####',
                    inputMode: 'numeric',
                    name: 'zip',
                    autocomplete: 'postal-code',
                },
                [ InputComponent.PhoneNumber ]: useMaskedInputs.value ? maskedPhoneProperties : standardPhoneProperties,
            }

            const message = ref('')
            const requiresMask = ref(false)
            const input = ref<HTMLInputElement | null>(null)
            const submitting = ref(false)
            const disableButton = computed(() => props.preventSubmit || submitting.value || message.value.length < 1)
            const inputProperties = ref<InputProperties[ InputType ]>(properties.text)

            function submit() {

                submitting.value = true
                emit('submit', message.value)
                message.value = ''
                submitting.value = false
                resetDataMask()
                input.value?.blur()

            }

            onMounted(() => {
                setInputProperties()
            })

            // Set the input properties based on the active step
            function setInputProperties(): void {
                inputProperties.value = getTextAreaProperties(props.activeStep)
            }

            function getTextAreaProperties(activeStep: WebFormStep): InputProperties {

                const targetComponents = [ 'date_of_birth', 'zipcode', 'phone_number' ]
                // Find the specific component from activeStep.components
                const specificComponent = activeStep.components.find(component => targetComponents.includes(component.type))

                if (specificComponent && targetComponents.includes(specificComponent.type)) {

                    requiresMask.value = true

                    return properties[ specificComponent.type ]

                }

                // Default to text
                requiresMask.value = false

                return properties.text

            }

            /*
             * If a mask was set on the input and the next step has no mask (null) these properties are not
             * automatically removed and cause the input to act as if there is still a mask. This allows us to
             * reset the data mask on the input before a new one is assigned.
             */
            function resetDataMask() {

                for (const attribute of [ 'data-mask', 'data-mask-raw-value', 'data-mask-inited' ]) {

                    if (input.value && input.value.hasAttribute(attribute)) {
                        input.value.removeAttribute(attribute)
                    }

                }

                inputProperties.value = properties.text

            }

            watch(() => props.activeStep, () => {
                setInputProperties()
            }, { deep: true })

            return {
                message,
                submit,
                disableButton,
                inputProperties,
                requiresMask,
                input,
                setInputProperties,
            }

        },
    })

</script>

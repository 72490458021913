<template>

    <TransitionScaleIn appear>

        <a :href="phoneNumber.link"
           class="mx-auto text-white shadow-lg relative max-w-lg w-full flex focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-rose-600 rounded-xl transition duration-300 h-14 sm:h-20 px-8"
           :style="{ backgroundColor, 'outline-color': backgroundColor }"
           @click.stop="phoneNumber.onClick">

            <div class="w-full flex items-center space-x-4 justify-center">

                <Icon icon="mdi:phone-in-talk" class="w-8 h-8 sm:w-9 sm:h-9"/>

                <span class="hidden sm:flex flex-col whitespace-nowrap space-y-1">

                    <span class="tracking-widest text-sm font-medium uppercase text-white/70">
                        {{ text }}
                    </span>

                    <span class="text-2xl tracking-wide leading-none font-medium">
                        {{ phoneNumber.dashed }} (TTY {{ phoneNumber.tty }})
                    </span>

                </span>

                <span class="sm:hidden font-medium tracking-wider">
                    Call Now
                </span>

            </div>

        </a>

    </TransitionScaleIn>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { Icon } from '@iconify/vue'
    import { Ringba } from '../../lib/Ringba'
    import TransitionScaleIn from '../transitions/TransitionScaleIn.vue'

    export default defineComponent({
        name: 'VideoClickToCall',
        components: { TransitionScaleIn, Icon },
        props: {
            text: { type: String, default: 'Call Toll-Free M-F 7AM-10PM CST' },
            backgroundColor: { type: String, default: null },
        },
        setup() {

            const phoneNumber = new Ringba().getPhoneNumber()

            return {
                phoneNumber,
            }

        },
    })

</script>

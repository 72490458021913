<template>

    <div v-editable="blok" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid class="space-y-8 lg:space-y-0 lg:flex lg:space-x-20 py-10 lg:py-20">

            <div class="w-full lg:w-1/3 space-y-4 lg:space-y-8 flex-shrink-0">

                <h2 class="leading-tight"
                    :class="[ blok.titleFontSize, blok.titleSpacingBottom ]"
                    :style="{ color: 'var(--primary-color)' }">
                    {{ blok.title }}
                </h2>

                <p class="text-base lg:text-xl leading-6 lg:leading-8 text-gray-600">
                    {{ blok.description }}
                </p>

            </div>

            <div class="flex-1">

                <StoryblokComponent v-for="qBlok of blok.questions"
                                    :key="qBlok._uid"
                                    :blok="qBlok"
                                    :active="active === qBlok._uid"
                                    @toggle="toggleAccordion(qBlok._uid)"/>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType, ref } from 'vue'
    import type { SbBlokData } from '@storyblok/js'
    import Grid from '../components/Grid.vue'
    import { FontSize } from '../interfaces/FontSize'

    interface Block {
        backgroundColor: string,
        title: string,
        titleFontSize: FontSize,
        titleSpacingBottom: string[],
        description: string,
        questions: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block5',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },

        setup() {

            const active = ref('')

            function toggleAccordion(uid: string) {
                active.value = active.value !== uid ? uid : ''
            }

            return {
                active,
                toggleAccordion,
            }

        },
    })

</script>

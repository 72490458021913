<template>

    <div class="flex items-start">

        <div class="mr-5 mt-3" :style="{ color: blok.color }">
            <Icon :icon="blok.icon" class="w-8 h-8"/>
        </div>

        <div class="text-base md:text-xl leading-8 text-gray-500 self-center">
            {{ blok.text }}
        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Icon } from '@iconify/vue'

    interface Block {
        text: string,
        icon: string,
        color: string,
    }

    export default defineComponent({
        name: 'ChecklistItem',
        components: { Icon },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

<template>

    <div v-editable="blok" class="py-10 lg:py-20" :style="{ backgroundColor: blok.backgroundColor }">

        <Grid class="flex flex-col space-y-8 md:space-y-12">

            <div class="w-full lg:w-2/5 space-y-4 lg:space-y-8 flex-shrink-0">

                <h2 class="leading-tight"
                    :class="[ blok.titleFontSize, blok.titleSpacingBottom ]"
                    :style="{ color: 'var(--primary-color)' }">
                    {{ blok.title }}
                </h2>

                <p class="text-base lg:text-xl leading-6 lg:leading-8 text-gray-600">
                    {{ blok.description }}
                </p>

            </div>

            <div v-if="blok.bigMediaCards.length"
                 class="visible-scrollbar flex snap-x snap-mandatory overflow-x-auto pb-2 gap-6 md:grid md:grid-cols-3 md:gap-12 md:overflow-visible">

                <div v-for="mediaBlok of blok.bigMediaCards" :key="mediaBlok._uid" class="w-full snap-start shrink-0">

                    <StoryblokComponent :blok="mediaBlok"/>

                </div>

            </div>

            <div v-if="blok.slimMediaCards.length"
                 class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">

                <StoryblokComponent v-for="mediaBlok of blok.slimMediaCards"
                                    :key="mediaBlok._uid"
                                    :blok="mediaBlok"/>

            </div>

            <div v-if="blok.showCta" class="mt-12">

                <div class="flex flex-col items-center justify-center text-center space-y-6">

                    <h1 class="md:font-semibold"
                        :class="blok.ctaHeadingFontSize"
                        :style="{ color: 'var(--primary-color)' }">
                        {{ blok.ctaHeading }}
                    </h1>

                    <p class="text-base md:text-xl leading-8 text-gray-600 md:w-2/5">
                        {{ blok.ctaSubheading }}
                    </p>

                    <StoryblokComponent v-for="button of blok.button" :key="button._uid" :blok="button"/>

                </div>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import type { SbBlokData } from '@storyblok/js'
    import Grid from '../components/Grid.vue'
    import { FontSize } from '../interfaces/FontSize'

    interface Block {
        backgroundColor: string,
        title: string,
        titleFontSize: FontSize,
        titleSpacingBottom: string[],
        description: string,
        bigMediaCards: SbBlokData[],
        slimMediaCards: SbBlokData[],
        showCta: boolean,
        ctaHeading?: string,
        ctaHeadingFontSize?: FontSize,
        ctaSubheading?: string,
        button: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block6',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

<style scoped>

    .visible-scrollbar::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    .visible-scrollbar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, .4);
    }

    .visible-scrollbar::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 10px;
    }

</style>

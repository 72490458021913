<template>

    <Transition :appear="appear"
                mode="out-in"
                enter-active-class="transition-opacity ease-linear duration-100"
                enter-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition-opacity ease-linear duration-100"
                leave-class="transform opacity-100"
                leave-to-class="transform opacity-0">

        <slot/>

    </Transition>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'TransitionFadeIn',
        props: {
            appear: { type: Boolean },
        },
    })

</script>

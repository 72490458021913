<template>

    <div v-editable="blok" class="w-full relative bg-white flex flex-col pb-20" :style="{ backgroundColor: theme.backgroundColor }" data-cy="block2">

        <Grid class="relative justify-center">

            <div class="w-full order-1 text-center">

                <div class="flex flex-wrap justify-center p-0 mb-0">

                    <h2 class="font-semibold max-w-3xl text-center !mb-6" :class="[ blok.titleFontSize ]">
                        {{ blok.title }}
                    </h2>

                </div>

                <div class="flex flex-wrap justify-center gap-y-10 pb-6">

                    <StoryblokComponent v-for="(cardBlok, index) of blok.cards"
                                        :key="cardBlok._uid"
                                        :blok="cardBlok"
                                        :class="pyramidClass(blok.cards.length, index)"/>

                </div>

            </div>

            <div class="w-full order-2 flex justify-center">

                <StoryblokComponent v-for="button of blok.button"
                                    :key="button._uid"
                                    :blok="button"
                                    :theme="theme.buttonTheme"
                                    :brand-text-color="theme.primaryColor"/>

            </div>

        </Grid>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { StoryblokComponent } from '@storyblok/vue'
    import Grid from '../components/Grid.vue'
    import { FontSize } from '../interfaces/FontSize'
    import { SbBlokData } from '@storyblok/js'
    import { sbCdnUrl } from '../../../../lib/Helpers'
    import { BrandTheme } from '../interfaces/BrandTheme'

    interface Block {
        title: string,
        titleFontSize: FontSize,
        backgroundColor?: string,
        cards: typeof StoryblokComponent[],
        button: SbBlokData[],
    }

    export default defineComponent({
        name: 'BrandedBlock2',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
            theme: { type: Object as PropType<BrandTheme>, required: true },
        },
        setup() {

            /**
             * In desktop / large screen we desire to layout the cards in a pyramid shape
             */
            function pyramidClass(total: number, index: number): string {

                const perRow = 3

                if (total % perRow === 0) {
                    return ''
                }

                if (index < perRow - 1) {
                    return 'lg:mx-1'
                }

                return ''

            }

            return {
                sbCdnUrl,
                pyramidClass,
            }

        },
    })

</script>

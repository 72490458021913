<template>

    <div v-if="header">
        <PhoneAware :text="header"/>
    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { WebFormStep } from '../../../types/State'
    import { Ringba } from '../../../lib/Ringba'
    import { replaceTokens } from '../../../lib/Helpers'
    import PhoneAware from '../../format/PhoneAware.vue'

    export default defineComponent({
        name: 'Header',
        components: { PhoneAware },
        props: {
            content: { type: Object as PropType<WebFormStep[ 'content' ]>, required: true },
        },
        setup(props) {

            const phoneNumber = new Ringba().getPhoneNumber()

            const header = computed<string | null>(() => {

                if (props.content.header) {

                    return replaceTokens(
                        props.content.header, {
                            number: `<a href="${ phoneNumber.link }" class="font-bold primary-text">${ phoneNumber.dashed }</a>`,
                        },
                    )

                }

                return null

            })

            return {
                header,
            }

        },
    })

</script>

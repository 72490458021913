<template>

    <div v-editable="blok"
         class="block-1-wrapper w-full relative bg-white flex flex-col"
         :style="{ minHeight: `${ blok.blockHeight }vh` }"
         data-cy="block1">

        <div class="relative md:flex-1">

            <div class="absolute inset-0 z-0"
                 :class="[ blok.backgroundImageVisibility ]"
                 :style="{ opacity: `${ (blok.backgroundOpacity || 1) / 100 }` }">
                <img class="h-full w-full object-cover" :src="sbCdnUrl(blok.background.filename)" :alt="blok.background.alt">
            </div>

            <Grid class="relative flex pt-10 pb-20 space-y-20 z-10"
                  :class="{
                      'justify-end text-right mr-4': blok.alignment === 'right',
                      'justify-center text-center': blok.alignment === 'center',
                      'justify-start text-left': blok.alignment === 'left',
                  }">

                <div v-if="blok.logo.filename" class="flex justify-between ">

                    <img class="h-12 md:h-auto w-[60vw] md:w-[380px]"
                         :src="sbCdnUrl(blok.logo.filename)"
                         :alt="blok.logo.alt">

                    <a :href="phoneNumber.link"
                       class="text-white bg-red-600 rounded w-10 h-10 flex items-center justify-center md:hidden"
                       @click="phoneNumber.onClick">

                        <svg width="20"
                             height="22"
                             viewBox="0 0 20 22"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">

                            <path d="M18.5912 14.586C17.3021 14.586 16.055 14.3507 14.8917 13.927C14.5249 13.7858 14.1162 13.8917 13.8332 14.2095L12.1879 16.5276C9.22202 14.939 6.44482 11.9384 4.96714 8.49063L7.01074 6.53729C7.2937 6.20781 7.37754 5.7489 7.26226 5.33705C6.8745 4.0309 6.67538 2.63061 6.67538 1.18325C6.67538 0.547831 6.20378 0.0183105 5.63786 0.0183105H2.01177C1.44585 0.0183105 0.764648 0.300721 0.764648 1.18325C0.764648 12.1149 8.86571 21.1991 18.5912 21.1991C19.3352 21.1991 19.6287 20.4578 19.6287 19.8106V15.7509C19.6287 15.1155 19.1571 14.586 18.5912 14.586Z"
                                  fill="white"/>

                        </svg>

                    </a>

                </div>

                <div class="space-y-6 w-full flex flex-col md:max-w-xl md:block"
                     :class="{ 'mt-4 md:mt-20': !blok.logo.filename }">

                    <h1 class="leading-tight" :class="[ blok.headlineFontSize, blok.headlineSpacingBottom ]">

                        <span :style="{ color: 'var(--primary-color)' }" class="md:hidden">
                            {{ blok.headline }}
                        </span>

                        <span class="hidden md:inline">
                            {{ blok.headline }}
                        </span>

                    </h1>

                    <div class="text-xl text-gray-500 md:text-gray-600">
                        {{ blok.description }}
                    </div>

                    <div class="flex"
                         :class="{
                             'justify-end': blok.alignment === 'right',
                             'justify-center': blok.alignment === 'center',
                             'justify-start': blok.alignment === 'left',
                         }">

                        <StoryblokComponent v-for="button of blok.button" :key="button._uid" :blok="button"/>

                    </div>

                </div>

            </Grid>

        </div>

        <div v-if="renderedBannerText.length"
             class="md:absolute flex flex-col justify-center md:bottom-0 md:inset-x-0"
             :style="{ backgroundColor: blok.bannerBackgroundColor }">

            <div class="text-2xl md:text-3xl py-10 px-6 md:py-12 banner_text text-center">
                <div v-html="renderedBannerText"/>
            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Richtext } from '@storyblok/vue'
    import Grid from '../components/Grid.vue'
    import { Asset } from '../interfaces/Asset'
    import { Ringba } from '../../../../lib/Ringba'
    import { FontSize } from '../interfaces/FontSize'
    import { SbBlokData } from '@storyblok/js'
    import { sbCdnUrl, renderSbRichText } from '../../../../lib/Helpers'

    interface Block {
        headline: string,
        headlineFontSize: FontSize,
        headlineSpacingBottom: string[],
        description: string,
        logo: Asset,
        background: Asset,
        backgroundColor?: string,
        backgroundOpacity?: number,
        backgroundImageVisibility?: string[],
        bannerText: Richtext,
        bannerBackgroundColor: string,
        blockHeight: number,
        alignment: 'left' | 'center' | 'right',
        button: SbBlokData[],
    }

    export default defineComponent({
        name: 'Block1',
        components: {
            Grid,
        },
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup({ blok }) {

            const phoneNumber = new Ringba().getPhoneNumber()
            const renderedBannerText = renderSbRichText(blok.bannerText)

            return {
                phoneNumber,
                sbCdnUrl,
                renderedBannerText,
            }

        },
    })

</script>

<style lang="scss">

    .banner_text b {
        color: var(--primary-color);
        font-weight: 800;
    }

    @media (max-width: 768px) {

        .block-1-wrapper {
            min-height: 0 !important;
        }

    }

</style>

<template>

    <button type="button"
            class="text-left w-full cursor-pointer py-4 border-t-2 border-b-2 border-b-transparent"
            :aria-controls="`faq-${ blok._uid }`"
            :aria-expanded="active"
            @click="$emit('toggle')">

        <div class="text-xl md:text-xl leading-7 font-medium md:font-semibold flex items-start sm:items-center justify-between space-x-4 select-none text-gray-900">

            <span>{{ blok.question }}</span>

            <svg width="18"
                 class="flex-shrink-0 text-gray-400"
                 :class="{ 'rotate-180 transition-all': active }"
                 height="11"
                 viewBox="0 0 18 11"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M16 2L9 9L2 2"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>

        </div>

    </button>

    <div v-if="active"
         :id="`faq-${ blok._uid }`"
         class="cursor-default text-lg leading-7 text-gray-500 w-full md:w-5/6 pb-4">

        {{ blok.answer }}

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'

    interface Block {
        _uid: string,
        question: string,
        answer: string,
    }

    export default defineComponent({
        name: 'FaqItem',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
            active: { type: Boolean },
        },
        emits: [ 'toggle' ],
    })

</script>

<template>

    <div class="flex flex-wrap justify-between" :class="[ data.gap ]">

        <PushButtonSpinner v-for="(button, index) of data.buttons"
                           :key="index"
                           :style="{ flex: `calc(100% / ${ columns } - ${ gapMapping[ gap ] })` }"
                           :size="size"
                           :state="states[ index ]"
                           class="justify-center rounded"
                           :theme="data.theme"
                           @click="onClick(index)">

            {{ button.text }}

        </PushButtonSpinner>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType, reactive } from 'vue'
    import { ButtonGroupComponent } from '../../../../types/Landing/Components'
    import { PushButtonSize, PushButtonState } from '../../../../lib/enums/PushButton'
    import { useRoute, useRouter } from 'vue-router'
    import { responsiveValue, smoothScrollTo } from '../../../../lib/Helpers'
    import PushButtonSpinner from '../../../buttons/PushButtonSpinner.vue'
    import { Api } from '../../../../lib/Api'
    import { isDebugMode, state } from '../../../../types/State'
    import { Hook } from '../../../../lib/enums/Hook'
    import { HookRunner } from '../../../../lib/HookRunner'
    import { RouteKey } from '../../../../lib/enums/RouteKey'
    import { WebFormTemplate } from '../../../../lib/enums/WebFormTemplate'

    const gapMapping = {
        'gap-1': '0.25rem',
        'gap-2': '0.5rem',
        'gap-3': '0.75rem',
        'gap-4': '1rem',
        'gap-5': '1.25rem',
        'gap-6': '1.5rem',
        'gap-7': '1.75rem',
        'gap-8': '2rem',
    }

    export default defineComponent({
        name: 'ButtonGroup',
        components: { PushButtonSpinner },
        props: {
            data: { type: Object as PropType<ButtonGroupComponent>, required: true },
        },
        setup(props) {

            const router = useRouter()
            const route = useRoute()
            const columns = responsiveValue<string>(props.data.columns.map(String), '1')
            const size = responsiveValue<PushButtonSize>(props.data.size, PushButtonSize.Smallest)
            const gap = responsiveValue<string>(props.data.gap.map(String), 'gap-4')
            const states = reactive(Object.keys(props.data.buttons).map(() => PushButtonState.Active))
            const api = new Api()
            const visitorId = state.visitorId
            const runner = new HookRunner()
            const metadata = { ...state.domain.metadata, ...state.webForm.metadata }

            async function updateVisitorData(payload: Record<string, string>) {

                /**
                 * Post Form
                 */
                const formData: Record<string, unknown> = { ...route.query, ...payload }

                runner.run(Hook.BeforeStepSubmission, formData, metadata)

                const response = await api.updateVisitorData(visitorId, formData)

                if (response.isErr()) {
                    throw response.error
                }

                runner.run(Hook.AfterStepSubmission, formData, metadata)

            }

            async function onClick(index: number) {

                for (const key in states) {
                    states[ key ] = PushButtonState.Disabled
                }

                states[ index ] = PushButtonState.Loading

                const { name, value } = props.data.buttons[ index ]

                await updateVisitorData({ [ name ]: value }).catch(message => {

                    if (isDebugMode) {
                        alert(message)
                    }

                    console.error('Error....', message)

                })

                smoothScrollTo()

                const routeOptions = function() {

                    if (state.webForm.template === WebFormTemplate.Chat) {

                        return {
                            name: RouteKey.Chat,
                            params: route.params,
                            query: route.query,
                        }

                    }

                    return {
                        name: RouteKey.WebForm,
                        params: { ...route.params, step: 1 },
                        query: route.query,
                    }

                }

                await router.replace(routeOptions())

            }

            return {
                states,
                PushButtonState,
                onClick,
                gapMapping,
                gap,
                size,
                columns,
            }

        },
    })

</script>

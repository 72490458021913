<template>

    <select ref="input"
            v-model="local"
            class="block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">

        <option v-if="placeholder" value="" disabled selected>
            {{ placeholder }}
        </option>

        <option v-for="option of options" :key="option.value" :value="option.value">
            {{ option.name }}
        </option>

    </select>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType, ref } from 'vue'

    export interface SelectOption {
        name: number | string,
        value: number | string,
    }

    export default defineComponent({
        name: 'FormSelectNative',
        props: {
            placeholder: { type: String, default: null },
            options: { type: Array as PropType<SelectOption[]>, required: true },
            modelValue: { type: [ String, Number ], required: true },
        },
        emits: [ 'update:model-value' ],
        setup(props, { emit }) {

            return {
                input: ref<HTMLSelectElement>(),
                local: computed({
                    get: () => props.modelValue,
                    set: (value: string | number) => emit('update:model-value', value),
                }),
            }

        },
    })

</script>

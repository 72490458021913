import { state } from '../types/State'
import { onUnmounted, reactive, ref } from 'vue'
import { mask } from 'maska'
import { getParametersByName, postMessage } from './Helpers'

declare global {
    interface Window {
        _rgba: object,
    }
}

export interface PhoneNumber {
    number: number,
    link: string,
    dashed: string,
    tty: number,
    onClick: () => void,
}

const phoneNumber = state.webForm.content?.phoneNumber ?? state.domain.content.phoneNumber
const clickListeners = ref<(() => void)[]>([])
// eslint-disable-next-line camelcase
const rgbaTags = (window._rgba_tags = (window._rgba_tags || []))

export class Ringba {

    private defaultPhone: string = phoneNumber

    private phoneNumber = reactive<PhoneNumber>(
        this.formatPhone(phoneNumber),
    )

    private formatPhone(phone: string): PhoneNumber {

        return {
            number: parseInt(phone),
            dashed: mask(phone, '1-###-###-####', { '#': { pattern: /[0-9]/ } }),
            link: `tel:${ phone }`,
            tty: 711,
            onClick: () => {

                for (const listener of clickListeners.value) {
                    listener()
                }

            },
        }

    }

    public getPhoneNumber(): PhoneNumber {

        this.refreshPhone()

        const interval = setInterval(() => this.refreshPhone(), 1000)

        onUnmounted(() => clearInterval(interval))

        return this.phoneNumber

    }

    private refreshPhone(): void {

        const storageKey = `ringbaNumber_${ window.RINGBA_TAG }`

        let ringba

        // We wrap this into a try / catch to avoid triggering security errors when cookies are blocked
        try {

            const key = Object.keys(window.localStorage || {}).find(key => key.startsWith(storageKey))

            if (key) {
                ringba = JSON.parse(window.localStorage[ key ])
            }

        } catch {
            // Nothing to do here
        }

        const phone = ringba?.displayNumber ?? this.defaultPhone

        Object.assign(this.phoneNumber, this.formatPhone(phone))
        postMessage('updateRingbaPhoneNumber', JSON.parse(JSON.stringify(this.phoneNumber)))

    }

    public onClick(listener: () => void): () => void {

        clickListeners.value.push(listener)

        return () => {

            const index = clickListeners.value.indexOf(listener)

            if (index) {
                clickListeners.value.splice(index, 1)
            }

        }

    }

    public push(data: Record<string, unknown>) {
        rgbaTags.push(data)
    }

    public pushFormData(data: object) {

        if (!data) {
            return
        }

        for (const _reference of Object.entries(data)) {

            const key = _reference[ 0 ]

            let value = _reference[ 1 ]

            if (Array.isArray(value)) {
                value = value.join(',')
            } else if (typeof value === 'object') {

                this.pushFormData(value)

                continue

            }

            this.push({
                [ key ]: value,
            })

        }

    }

    public getDefaultTag(metadata: Record<string, unknown>) {

        let defaultTag = 'JS3e46ffb5e2574cd4803f54c9db0b0efa'

        for (const key in metadata) {

            const source = getParametersByName('source') || getParametersByName('src')

            if (source === key) {

                defaultTag = metadata[ key ]

                break

            }

        }

        return defaultTag

    }

    public createRingbaTagScript(metadata: Record<string, unknown>) {

        const defaultTag: string = this.getDefaultTag(metadata)

        window.RINGBA_TAG = defaultTag

        const scriptElements = document.getElementsByTagName('script'),
            lastScriptElement = scriptElements[ scriptElements.length - 1 ]

        window._rgba = window._rgba || {
            q: [],
        }

        window._rgba.q.push({
            tag: defaultTag,
            script: lastScriptElement,
            numberClass: null,
            numberDisplayTag: 'bob',
        })

        if (!(window._rgba.loading = !!window._rgba.loading)) {

            const newScriptElement = document.createElement('script')

            newScriptElement.type = 'text/javascript'
            newScriptElement.async = true
            newScriptElement.src = '//js.callcdn.com/js_v3/min/ringba.com.js'

            const firstScriptElement = document.getElementsByTagName('script')[ 0 ]

            if (firstScriptElement.parentNode) {
                firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement)
            } else {

                // Fallback to appending to the body
                document.body.appendChild(newScriptElement)

            }

            window._rgba.loading = true

        }

    }

}

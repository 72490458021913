export enum Hook {
    VueReady = 'vue_ready',
    DOMContentLoaded = 'dom_content_loaded',
    BeforeStepSubmission = 'before_step_submission',
    AfterStepSubmission = 'after_step_submission',
    BeforeFormSubmission = 'before_form_submission',
    AfterFormSubmission = 'after_form_submission',
    AfterEnterThankYouPage = 'after_enter_thank_you_page',
    OnWebFormStart = 'on_web_form_start',
}

<template>

    <section ref="messageList"
             class="message-window flex flex-col py-4 space-y-6 w-full overflow-y-auto scroll-smooth sm:pr-3">

        <ChatMessage v-for="message of messages" :key="message.key" :message="message"/>

        <div v-if="isTyping" class="inline-flex gap-4 flex-row">
            <Avatar :is-user-message="false"/>
            <TransitionScaleIn appear enter-active="transition ease-in-out delay-50 duration-50">
                <ChatLoading :loading="isTyping" class="flex items-center min-h-[50px]"/>
            </TransitionScaleIn>
        </div>

        <slot/>

    </section>
</template>

<script lang="ts">
    import { defineComponent, nextTick, ref, watch } from 'vue'
    import { ChatMessages } from '../../types/Chat'
    import ChatMessage from './ChatMessage.vue'
    import ChatLoading from './ChatLoading.vue'
    import TransitionScaleIn from '../transitions/TransitionScaleIn.vue'
    import Avatar from './Avatar.vue'

    export default defineComponent({
        name: 'ChatMessageList',
        components: { Avatar, TransitionScaleIn, ChatLoading, ChatMessage },
        props: {
            messages: { type: ref<ChatMessages>, required: true },
            isTyping: { type: Boolean, required: true },
        },
        setup(props) {

            const messageList = ref<HTMLElement>()

            function scrollToBottom(): void {

                nextTick(() => {

                    if (messageList.value) {
                        messageList.value.scrollTop = messageList.value.scrollHeight
                    }

                })

            }

            watch(props.messages, () => {
                scrollToBottom()
            }, { deep: true })

            watch(() => props.isTyping, () => {
                scrollToBottom()
            })

            return {
                messageList,
                scrollToBottom,
            }

        },
    })
</script>

<style scoped lang="css">

    .message-window {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    .message-window::-webkit-scrollbar {
        display: none;
    }

</style>

<template>

    <div class="mb-10">
        <StepSpouseChildrenItem v-if="model.spouse"
                                v-model:gender="model.spouse.gender"
                                v-model:tobacco-use="model.spouse.tobacco_use"
                                v-model:birthday="model.spouse.birthday"
                                title="Your Spouse"
                                @remove="removeSpouse"/>

        <StepSpouseChildrenItem v-for="(children, key) of model.children"
                                :key="key"
                                v-model:gender="model.children[ key ].gender"
                                v-model:tobacco-use="model.children[ key ].tobacco_use"
                                v-model:birthday="model.children[ key ].birthday"
                                title="Your Child"
                                @remove="removeChild(key)"/>

        <div class="flex justify-center space-x-4">

            <PushButton :theme="PushButtonTheme.White"
                        class="text-gray-500 uppercase"
                        :state="model.spouse ? PushButtonState.Disabled : PushButtonState.Active"
                        @click="addSpouse">
                + Add Spouse
            </PushButton>

            <PushButton :theme="PushButtonTheme.White"
                        :state="model.children.length >= 4 ? PushButtonState.Disabled : PushButtonState.Active"
                        class="text-gray-500 uppercase"
                        @click="addChild">
                + Add Child
            </PushButton>

        </div>
    </div>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, ref } from 'vue'
    import PushButton from '../buttons/PushButton.vue'
    import { SpouseChildren, SpouseChildrenType } from '../../types/Components'
    import { RouteKey } from '../../lib/enums/RouteKey'
    import { PushButtonState, PushButtonTheme } from '../../lib/enums/PushButton'
    import StepSpouseChildrenItem from './StepSpouseChildrenItem.vue'
    import { smoothScrollTo, restoreState } from '../../lib/Helpers'
    import { useRoute } from 'vue-router'

    export default defineComponent({
        name: 'StepSpouseChildren',
        components: { PushButton, StepSpouseChildrenItem },
        props: {
            component: { type: Object as PropType<SpouseChildren>, required: true },
        },
        emits: [ 'progress' ],
        setup({ component }, { emit }) {

            const route = useRoute()

            const model = ref<SpouseChildrenType>({
                spouse: null,
                children: [],
                children_count: 0, // eslint-disable-line
            })

            const loading = ref(false)

            onMounted(() => restoreState(model, [ 'data', component.data.name ]))

            function addSpouse(): void {

                /**
                 * Prevent the page from scrolling to top when adding spouse. This is because
                 * the inline web form can be rendered below the fold, and scrolling
                 * the page to the top after adding a spouse wouldn't make sense.
                 */
                if (route.name !== RouteKey.InlineWebForm) {
                    smoothScrollTo()
                }

                model.value.spouse = {
                    gender: undefined,
                    tobacco_use: undefined, // eslint-disable-line
                    birthday: undefined,
                }

            }

            function addChild(): void {

                if (!model.value.children) {
                    model.value.children = []
                }

                model.value.children.push({
                    gender: undefined,
                    tobacco_use: undefined, // eslint-disable-line
                    birthday: undefined,
                })

                model.value.children_count = model.value.children.length // eslint-disable-line

            }

            function removeSpouse(): void {
                model.value.spouse = null
            }

            function removeChild(key: number): void {

                model.value.children.splice(key, 1)
                model.value.children_count = model.value.children.length // eslint-disable-line

            }

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function isValid(): boolean {

                if (component.data.required === true) {

                    if (!model.value.spouse && model.value.children.length === 0) {
                        return false
                    }

                }

                for (const key in model.value.spouse) {

                    if (!model.value.spouse[ key ]) {
                        return false
                    }

                }

                for (const childKey in model.value.children) {

                    for (const key in model.value.children[ childKey ]) {

                        if (!model.value.children[ childKey ][ key ]) {
                            return false
                        }

                    }

                }

                return true

            }

            function setValue(value: string) {

                loading.value = true
                model.value = value
                emit('progress')

            }

            return {
                PushButtonState,
                PushButtonTheme,
                addSpouse,
                addChild,
                removeSpouse,
                removeChild,
                model,
                fill,
                isValid,
                setValue,
            }

        },
    })

</script>

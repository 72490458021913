<template>

    <Teleport to="#root">

        <div v-editable="blok"
             class="page"
             :class="[ `header-${ blok.headerFontFamily }` ]"
             :style="{ '--primary-color': blok.primaryColor }">

            <StoryblokComponent v-for="block of blok.blocks"
                                :key="block._uid"
                                :blok="block"/>

        </div>

    </Teleport>

</template>

<script lang="ts">

    import { defineComponent, PropType, onMounted } from 'vue'
    import { FontFamily } from '../interfaces/FontSize'
    import { StoryblokComponent } from '@storyblok/vue'
    import { isStoryBlokLandingActive } from '../../../../types/State'

    interface Block {
        primaryColor: string,
        headerFontFamily: FontFamily,
        blocks: typeof StoryblokComponent[],
    }

    export default defineComponent({
        name: 'Page',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup() {

            onMounted(() => {
                isStoryBlokLandingActive.value = true
            })

        },
    })

</script>

<style lang="scss">

    main {
        padding: 0 !important;
    }

    $fonts: arial, verdana, tahoma, trebuchet-ms, times-new-roman, georgia, garamond, helvetica, calibri;

    @each $font in $fonts {

        .page.header-font-#{$font} {

            h1, h2, h3, h4, h5, h6 {
                @apply font-#{$font};
            }

        }

    }

</style>

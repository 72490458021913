<template>

    <form v-if="currentStep" @submit.prevent>

        <Component :is="webFormComponent"
                   :content="currentStep.content"
                   :progress="progress">

            <div class="w-full max-w-md">

                <div class="mb-6 font-medium text-center">

                    <div v-if="isCardWithProgress && currentStep.content.icon"
                         class="mb-4 w-16 inline-block text-gray-400 fill-gray-400"
                         v-html="currentStep.content.icon"/>

                    <div v-if="isCardWithProgress && currentStep.content.undertitle"
                         class="text-gray-500 text-sm text-center"
                         v-html="currentStep.content.undertitle"/>

                    <h1 class="text-2xl my-1" v-html="currentStep.content.headline"/>

                    <div v-if="isCardWithProgress && currentStep.content.subtitle"
                         class="text-gray-500 text-sm text-center"
                         v-html="currentStep.content.subtitle"/>

                    <div class="flex justify-center items-center">

                        <Popper v-if="isCardWithProgress && currentStep.content.popover?.type" arrow hover>

                            <template #content>

                                <div class="p-4 space-y-4 max-w-xl text-left bg-white rounded-md border border-gray-300 shadow-lg"
                                     v-html="currentStep.content.popover.text"/>

                            </template>

                            <template v-if="currentStep.content.popover.type === PopoverType.Icon">
                                <Icon icon="ep:warning-filled" class="ml-1 w-5 h-5 text-gray-400 cursor-pointer"/>
                            </template>

                            <template v-if="currentStep.content.popover.type === PopoverType.Link">

                                <a href="#" class="ml-1 underline primary-text-dark" @click.prevent>
                                    {{ currentStep.content.popover.label }}
                                </a>

                            </template>

                        </Popper>

                    </div>

                </div>

                <slot>

                    <StepComponents/>

                </slot>

                <div v-if="(isCardWithProgress || isStandard) && currentStep.content.description"
                     class="m-2 mt-6 text-gray-500 text-sm text-center"
                     v-html="currentStep.content.description"/>

            </div>

        </Component>

    </form>

    <div v-else class="p-8 text-4xl bg-white rounded-lg border">
        Form steps hasn't been setup yet.
    </div>

</template>

<script lang="ts">

    import { isStoryBlokLandingActive, state } from '../types/State'
    import { defineComponent, onMounted } from 'vue'
    import StepComponents from '../components/steps/StepComponents.vue'
    import Popper from 'vue3-popper'
    import { Icon } from '@iconify/vue'
    import { PopoverType } from '../lib/enums/PopoverType'
    import { HookRunner } from '../lib/HookRunner'
    import { Hook } from '../lib/enums/Hook'
    import TransitionLeftToRight from '../components/transitions/TransitionLeftToRight.vue'
    import WebFormStandard from '../components/web_forms/Standard.vue'
    import WebFormCardWithProgress from '../components/web_forms/CardWithProgress.vue'
    import { useFormPlanner } from '../lib/compositions/useFormPlanner'
    import { WebFormTemplate } from '../lib/enums/WebFormTemplate'

    export default defineComponent({
        name: 'WebForm',
        components: {
            TransitionLeftToRight,
            Popper,
            Icon,
            StepComponents,
            WebFormStandard,
            WebFormCardWithProgress,
        },
        setup() {

            const { currentStep, progress } = useFormPlanner()
            const template = state.webForm.template

            const webFormComponent = template === WebFormTemplate.Standard
                ? WebFormStandard
                : WebFormCardWithProgress

            const isCardWithProgress = template === WebFormTemplate.CardWithProgress
            const isStandard = template === WebFormTemplate.Standard

            isStoryBlokLandingActive.value = false

            const runner = new HookRunner()
            const metadata = { ...state.domain.metadata, ...state.webForm.metadata }

            onMounted(() => {
                runner.run(Hook.OnWebFormStart, metadata)
            })

            return {
                PopoverType,
                webFormComponent,
                isCardWithProgress,
                isStandard,
                currentStep,
                progress,
            }

        },
    })

</script>

<style lang="scss">

    :deep(.popper #arrow::before) {
        border-top: 1px solid theme('colors.gray.300') !important;
        border-left: 1px solid theme('colors.gray.300') !important;
        background: theme('colors.white') !important;
    }

    :deep(.popper #arrow) {
        left: -3px !important;
    }

    :deep(.popper a) {
        @apply underline text-blue-600 hover:text-blue-800 visited:text-purple-600
    }

</style>

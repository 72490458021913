<template>

    <PushButton :state="buttonState" :size="buttonStyles.size" :theme="buttonStyles.theme">

        <Icon v-if="state === PushButtonState.Loading"
              icon="gg:spinner-two"
              class="absolute left-2 w-6 h-6 animate-spin text-white"/>

        <div class="w-full justify-center"
             :class="buttonStyles.fontSize">

            <slot/>

        </div>

    </PushButton>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import PushButton from './PushButton.vue'
    import { Icon } from '@iconify/vue'
    import { PushButtonFontSize, PushButtonSize, PushButtonState, PushButtonTheme } from '../../lib/enums/PushButton'

    export default defineComponent({
        name: 'PushButtonSpinner',
        components: { PushButton, Icon },
        props: {
            state: { type: String as PropType<PushButtonState>, default: null },
            theme: { type: String as PropType<PushButtonTheme>, default: null },
            size: { type: String as PropType<PushButtonSize>, default: null },
            fontSize: { type: String as PropType<PushButtonFontSize>, default: null },
        },
        setup(props) {

            const buttonState = computed(() => props.state ?? PushButtonState.Active)

            const buttonStyles = computed(() => ({
                theme: props.theme ?? PushButtonTheme.Themed,
                size: props.size ?? PushButtonSize.Medium,
                fontSize: props.fontSize ?? PushButtonFontSize.Large,
            }))

            return {
                PushButtonState,
                buttonStyles,
                buttonState,
            }

        },
    })

</script>

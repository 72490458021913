import { ThankYouPageResource } from './resources/ThankYouPageResource'
import { reactive, ref, Ref } from 'vue'
import { StepComponent } from './Components'
import { ChatComponent } from './ChatComponents'
import { FooterLink } from '../lib/enums/Footer'
import { LandingPage } from './LandingPage'
import { Language } from '../lib/enums/Language'
import { PopoverType } from '../lib/enums/PopoverType'
import { StepType } from '../lib/enums/StepType'
import { Group } from '../lib/compositions/useFormPlanner'
import useLocalStorage from '../lib/compositions/useLocalStorage'
import { PushButtonFontSize, PushButtonSize, PushButtonTheme } from '../lib/enums/PushButton'

type Popover<T> = T & {
    text: string,
}

export type WebFormStep = {
    id: number,
    content: {
        header?: string,
        undertitle?: string,
        headline: string,
        subtitle?: string,
        showTcpa: boolean,
        tcpa?: string,
        showPhoneBox: boolean,
        phoneBoxText?: string,
        showRequestImmediateAssistance?: boolean,
        requestImmediateAssistanceText?: string,
        description: string,
        button?: {
            text: string,
            invalid: string,
            error: string,
            theme?: PushButtonTheme,
            size?: PushButtonSize,
            fontSize?: PushButtonFontSize,
        },
        popover?:
            Popover<{ type: PopoverType.Link, label: string }> |
            Popover<{ type: PopoverType.Icon }>,
    },
    components: StepComponent[] | ChatComponent[],
    type: StepType,
    audio?: string,
}

export type ExternalLink = {
    type: FooterLink.ExternalLink,
    data: {
        label: string,
        url: string,
    },
}

export type Modal = {
    type: FooterLink.Modal,
    data: {
        id: string,
        label: string,
        content: string,
        hidden: boolean,
    },
}

export type Prefill = {
    address?: Record<string, string>,
    addressInput: Record<string, string>,
    data: Record<string, string>,
    smartyAddress: Record<string, Record<string, string>>,
}

export interface State {
    language: Language,
    visitorId: string,
    prefill: Prefill,
    tokens: Record<string, string>,
    webForm: {
        key: number,
        metadata: Record<string, string>,
        template: string,
        content: {
            phoneNumber?: string,
            phoneText?: string,
            showStickyBar: boolean,
            consentModal: boolean,
            appendVisitorId: boolean,
            bypassLoadingPage: boolean,
            footerless: boolean,
            headerless: boolean,
            tcpa?: string,
            welcomeMessage?: string,
            useMaskedInputs?: boolean,
            consentModalOverride?: string,
        },
        disclaimers: {
            key: number,
            order: number,
            content: string,
        }[],
        landingPage?: LandingPage,
        formPlanner: {
            groups: Group[],
            statistics: {
                step: number,
                steps: number,
                progress: number,
            },
        },
        tags: string[],
    },
    domain: {
        id: number,
        theme?: {
            id: number,
            content: {
                color: {
                    button: string,
                    primary: string,
                    secondary: string,
                },
            },
        },
        content: {
            phoneNumber: string,
            phoneText: string,
            tcpa: string,
            consentModal: string,
            path?: string,
            headerless: boolean,
            footerless: boolean,
            logo: {
                a: string,
                b: string,
                c: string,
                tagline: string,
            },
            footer: {
                copyright: string,
                links: (ExternalLink | Modal)[],
            },
            loadingSteps: null | {
                type: 'loading_step',
                data: {
                    icon: string,
                    title: string,
                    subtitle?: string,
                },
            }[],
        },
        disclaimers: {
            key: number,
            order: number,
            content: string,
        }[],
        metadata: Record<string, string>,
    },
}

export const state: State = reactive(window.__STATE)

export const prefill: Ref<Prefill> = useLocalStorage('__PREFILL', state.prefill, state.tokens.fingerprint)

export const thankYouPage = ref<ThankYouPageResource[ 'data' ]>()

export const isStoryBlokLandingActive = ref<boolean>(false)

export const requestImmediateAssistance = ref<boolean>(true)

export const isDebugMode = import.meta.env.DEV
    || location.href.includes('debug=true')
    || location.href.includes('_storyblok')

<template>
    <div class="mb-3">
        <!-- Year Panel -->
        <div>
            <div class="text-xl font-semibold mb-2">
                {{ replaceTokens(component.data.questionLabel, { type: component.data.yearLabel }) }}
            </div>
            <div v-if="car.year === null" id="year_panel">
                <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                    <PushButtonSpinner v-for="option of yearRange"
                                       :key="option"
                                       :theme="theme"
                                       @click="setYear(option)">

                        {{ option }}
                    </PushButtonSpinner>
                    <PushButtonSpinner v-if="!showingAllYears" :theme="theme" @click="showAllYears">
                        {{ component.data.otherLabel }} {{ component.data.yearLabel }}
                    </PushButtonSpinner>

                    <span v-else>
                        <FormSelectNative ref="yearSelect"
                                          v-model="car.year"
                                          class="w-full"
                                          :aria-label="component.data.yearLabel"
                                          :options="yearsSelect"
                                          :placeholder="`${ component.data.selectLabel } ${ component.data.yearLabel }`"/>
                    </span>
                </div>
            </div>
            <!--            Year Selected Display -->
            <div v-else>
                <PushButtonSpinner :theme="theme"
                                   @click="setYear(null)">
                    <span class="flex">
                        {{ car.year }}
                        <Icon icon="mdi:close"
                              class="cursor-pointer ml-2 pt-1 text-xl"
                              :aria-label="component.data.resetLabel"/>
                    </span>
                </PushButtonSpinner>
            </div>
        </div>
        <!-- Make Panel -->
        <div id="make_panel" class="pt-4">
            <div class="text-xl font-semibold mb-2">
                {{ replaceTokens(component.data.questionLabel, { type: component.data.makeLabel }) }}
            </div>
            <div v-if="car.year">
                <div v-if="car.make === null" class="">
                    <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <PushButtonSpinner v-for="option of defaultMakes"
                                           :key="option.label"
                                           :theme="theme"
                                           @click="setMake(option.value)">
                            <span class="flex justify-center items-center pb-2">
                                <img v-if="option.image"
                                     :src="option.image"
                                     class="m-w-12 h-12"
                                     :alt="`${ option.label } logo`">
                                <Icon v-else icon="mdi:car-sports" class="w-12 h-12" alt="Generic Vehicle"/>
                            </span>
                            <span>
                                {{ option.label }}
                            </span>
                        </PushButtonSpinner>
                        <PushButtonSpinner v-if="!showingAllMakes" :theme="theme" @click="showAllMakes">
                            <span class="flex justify-center items-center pb-2">
                                <Icon icon="mdi:car-search-outline" class="w-12 h-12"/>
                            </span>
                            <span>
                                {{ component.data.otherLabel }} {{ component.data.makeLabel }}
                            </span>
                        </PushButtonSpinner>
                        <span v-else>
                            <FormSelectNative v-model="car.make"
                                              class="w-full h-full"
                                              :aria-label="component.data.makeLabel"
                                              :options="makesSelect"
                                              :placeholder="`${ component.data.selectLabel } ${ component.data.makeLabel }`"/>
                        </span>
                    </div>
                </div>
                <!--            Make Selected Display -->
                <div v-else>
                    <PushButtonSpinner :theme="theme"
                                       @click="setMake(null)">
                        <span class="flex">
                            {{ car.make }}
                            <Icon icon="mdi:close"
                                  class="cursor-pointer ml-2 pt-1 text-xl"
                                  :aria-label="component.data.resetLabel"/>
                        </span>
                    </PushButtonSpinner>
                </div>
            </div>
            <div v-else>
                <PushButtonSpinner :state="PushButtonState.Disabled" :theme="theme">
                    {{ replaceTokens(component.data.pleaseSelectLabel, { type: component.data.yearLabel }) }}
                </PushButtonSpinner>
            </div>
        </div>
        <!-- Model Panel -->
        <div id="model_panel" class="pt-4">
            <div class="text-xl font-semibold mb-2">
                {{ replaceTokens(component.data.questionLabel, { type: component.data.modelLabel }) }}
            </div>
            <div v-if="car.make">
                <div v-if="!car.model">
                    <span class="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <PushButtonSpinner v-for="option of recommendedModels"
                                           :key="option.label"
                                           :theme="theme"
                                           @click="setModel(option.value)">

                            {{ option.label }}
                        </PushButtonSpinner>
                        <PushButtonSpinner v-if="!showingAllModels"
                                           :theme="theme"
                                           @click="showAllModels">
                            {{ component.data.otherLabel }} {{ component.data.modelLabel }}
                        </PushButtonSpinner>
                        <span v-else>
                            <FormSelectNative v-model="car.model"
                                              class="w-full h-full"
                                              :aria-label="component.data.modelLabel"
                                              :options="allModelsSelect"
                                              :placeholder="`${ component.data.selectLabel } ${ component.data.modelLabel }`"/>
                        </span>
                    </span>
                </div>
                <!--            Model Selected Display -->
                <div v-else>
                    <PushButtonSpinner :theme="theme"
                                       @click="setModel(null)">
                        <span class="flex">
                            {{ car.model }}
                            <Icon icon="mdi:close"
                                  class="cursor-pointer ml-2 pt-1 text-xl"
                                  :aria-label="component.data.resetLabel"/>
                        </span>
                    </PushButtonSpinner>
                </div>
            </div>
            <div v-else>
                <PushButtonSpinner :state="PushButtonState.Disabled" :theme="theme">
                    {{ replaceTokens(component.data.pleaseSelectLabel, { type: component.data.makeLabel }) }}
                </PushButtonSpinner>
            </div>
        </div>
    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType, ref, watch } from 'vue'
    import PushButtonSpinner from '../buttons/PushButtonSpinner.vue'
    import { CarType, CarYearMakeModel } from '../../types/Components'
    import { PushButtonState, PushButtonTheme } from '../../lib/enums/PushButton'
    import { Api } from '../../lib/Api'
    import { Icon } from '@iconify/vue'
    import FormSelectNative from '../form/FormSelectNative.vue'
    import { replaceTokens } from '../../lib/Helpers'

    export default defineComponent({
        name: 'StepCarYearMakeModel',
        components: { FormSelectNative, PushButtonSpinner, Icon },
        props: {
            component: { type: Object as PropType<CarYearMakeModel>, required: true },
        },
        setup({ component }) {

            const api = new Api()
            const loading = ref(false)
            const makes = ref([])
            const recommendedModels = ref([])
            const allModels = ref([])
            const showingAllMakes = ref(false)
            const showingAllModels = ref(false)
            const showingAllYears = ref(false)
            const theme = ref(PushButtonTheme.White)

            const car = ref<CarType>({
                make: null,
                model: null,
                year: null,
            })

            /**
             * Computed properties...
             */
            const yearRange = computed(() => Array.from({ length: component.data.endYear - component.data.startYear + 1 }, (_, index) => component.data.startYear + index).reverse())

            const yearsSelect = computed(() => Array.from({ length: (new Date().getFullYear()) + 1 - 1901 + 1 }, (_, index) => 1901 + index).reverse().map(year => ({
                name: year,
                value: year,
            })),
            )

            const defaultMakes = computed(() => makes.value.filter(make => component.data.defaultMakes.includes(make.label)))

            const makesSelect = computed(() => makes.value.map(({ label, value }) => ({
                name: label,
                value,
            })))

            const allModelsSelect = computed(() => allModels.value.map(({ label, value }) => ({
                name: label,
                value,
            })))

            /**
             * Lifecycle Hooks:
             */

            watch(() => car.value.year,
                  year => {

                      if (year !== null) {
                          getYearMakesData(year)
                      }

                  },
            )

            watch(() => car.value.make,
                  make => {

                      if (make === null || car.value.year === null) {
                          return
                      }

                      getRecommendedModelData(make, car.value.year, component.data.modelLimit)
                      getAllModelData(make, car.value.year)

                  },
            )

            /**
             * Methods...
             */
            async function getYearMakesData(year: number) {

                loading.value = true

                const response = await api.getCarMakesForYear(year)

                if (response.isErr()) {
                    loading.value = false
                } else {

                    loading.value = false
                    makes.value = response.value.data

                }

            }

            async function getRecommendedModelData(make: null | string, year: number, limit = 10) {

                loading.value = true

                if (make === null) {

                    showingAllModels.value = false
                    loading.value = false
                    recommendedModels.value = []

                    return

                }

                const response = await api.getRecommendedCarModelsByMakeAndYear(make, year, limit)

                if (response.isErr()) {
                    loading.value = false
                } else {

                    loading.value = false
                    recommendedModels.value = response.value.data

                }

            }

            async function getAllModelData(make: string, year: number | string) {

                loading.value = true

                const response = await api.getCarModelsByMakeAndYear(make, year)

                if (response.isErr()) {
                    loading.value = false
                } else {

                    loading.value = false
                    allModels.value = response.value.data

                }

            }

            async function showAllMakes() {

                if (car.value.year === null) {
                    return
                }

                await getYearMakesData(car.value.year)
                showingAllMakes.value = true

            }

            function showAllYears() {
                showingAllYears.value = true
            }

            async function showAllModels() {

                if (car.value.make === null || car.value.year === null) {
                    return
                }

                await getAllModelData(car.value.make, car.value.year)
                showingAllModels.value = true

            }

            function setMake(make: null | string) {

                car.value.make = make

                if (make === null) {

                    showingAllMakes.value = false
                    setModel(null)

                }

            }

            function setModel(model: null | string) {
                car.value.model = model
            }

            function setYear(year: null | number) {

                if (year === null) {

                    setMake(null)
                    car.value.year = null

                    return

                }

                car.value.year = year.toString()

            }

            function isValid(): boolean {
                return car.value.make !== null && car.value.model !== null && car.value.year !== null
            }

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = car.value
            }

            return {
                PushButtonState,
                PushButtonTheme,
                showingAllMakes,
                showingAllModels,
                showingAllYears,
                defaultMakes,
                makesSelect,
                allModelsSelect,
                yearsSelect,
                car,
                makes,
                recommendedModels,
                yearRange,
                theme,
                setMake,
                setModel,
                setYear,
                isValid,
                fill,
                showAllMakes,
                showAllModels,
                showAllYears,
                replaceTokens,

            }

        },

    })

</script>

<template>

    <div class="mt-16 w-full" data-cy="sb-ty">

        <StoryblokComponent v-if="story"
                            :blok="story.content"
                            class="px-0"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { useStoryblok } from '@storyblok/vue'
    import { isDebugMode } from '../../types/State'

    export default defineComponent({
        name: 'StoryBlok',
        props: {
            data: { type: Object, required: true },
        },
        async setup({ data }) {

            if (isDebugMode) {

                return {
                    story: await useStoryblok(
                        data.story.full_slug,
                        { language: location.pathname.startsWith('/es') ? 'es' : 'default', version: 'draft' },
                        { customParent: window.location.href.split('?')[ 0 ] },
                    ),
                }

            }

            return {
                story: data.story,
            }

        },
    })

</script>

<template>

    <TransitionBottomToTop>

        <button v-if="showTeaser"
                type="button"
                class="video-teaser fixed z-10 right-0 bottom-0 px-4 pb-4 lg:pb-8 lg:px-8 group"
                @click="openVideoModal">

            <div class="w-40 h-[12.75rem] lg:w-56 lg:h-72 rounded-[1.25rem] overflow-hidden bg-white p-2 flex shadow-lg">

                <div class="relative bg-gray-700 flex-1 flex flex-col justify-end items-center rounded-2xl overflow-hidden pb-4">

                    <div class="relative z-10 w-full px-2 lg:px-4">

                        <div>

                            <div v-if="blok.heading.length" class="relative w-full flex justify-center py-0.5">

                                <span class="font-nunito leading-0 text-sm lg:text-lg font-semibold text-[#1E40AF]">
                                    {{ blok.heading }}
                                </span>

                                <div class="absolute inset-0 bg-[#E0EAFB] -skew-x-[20deg] -z-10"/>

                            </div>

                        </div>

                    </div>

                    <div class="relative z-10 mt-4">

                        <div class="rounded-full p-1 bg-white/20">

                            <div class="rounded-full p-1 bg-white/20">

                                <div class="rounded-full w-10 h-10 lg:w-[54px] lg:h-[54px] bg-white flex items-center justify-center group-hover:bg-blue-50 transition duration-500">

                                    <PlayIcon class="h-5 w-5 text-[#DC2647] group-hover:text-red-500 transition"/>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="absolute inset-0 mt-0 z-0 rounded-2xl overflow-hidden">

                        <img class="h-full w-auto object-cover pointer-events-none"
                             :src="sbCdnUrl(blok.teaserGif.filename)">

                    </div>

                </div>

            </div>

        </button>

    </TransitionBottomToTop>

    <VideoModal v-if="showVideoModal"
                :blok="blok"
                :opened-on-page-load="openedOnPageLoad"
                @close="closeVideoModal"/>

</template>

<script lang="ts">

    import { defineComponent, onMounted, PropType, nextTick, ref, watch } from 'vue'
    import { delay } from '../../../../lib/Helpers'
    import { VideoComponent } from '../../../../../client/types/Landing/Components'
    import PlayIcon from '../../../icons/PlayIcon.vue'
    import TransitionBottomToTop from '../../../transitions/TransitionBottomToTop.vue'
    import VideoModal from '../../../modals/VideoModal.vue'
    import { sbCdnUrl } from '../../../../lib/Helpers'

    export default defineComponent({
        name: 'VideoBlock',
        components: { PlayIcon, TransitionBottomToTop, VideoModal },
        props: {
            blok: { type: Object as PropType<VideoComponent>, required: true },
        },
        setup({ blok }) {

            const showTeaser = ref<boolean>(false)
            const showVideoModal = ref<boolean>(false)
            const openedOnPageLoad = ref(blok.fullScreenOnPageLoad || false)

            onMounted(async() => {

                if (blok.fullScreenOnPageLoad) {
                    return openVideoModal()
                }

                await delay(1000)
                showTeaser.value = showVideoModal.value === false

            })

            watch(() => showVideoModal.value, isOpened => {

                showTeaser.value = !isOpened
                document.body.classList.toggle('overflow-hidden', isOpened)

                if (isOpened) {
                    window.scrollTo({ top: 0 })
                }

            })

            function openVideoModal() {

                nextTick(() => {
                    showVideoModal.value = true
                })

            }

            function closeVideoModal() {

                openedOnPageLoad.value = false

                nextTick(() => {
                    showVideoModal.value = false
                })

            }

            return {
                showTeaser,
                showVideoModal,
                openVideoModal,
                closeVideoModal,
                sbCdnUrl,
                openedOnPageLoad,
            }

        },
    })

</script>

<template>
    <div class="inline-flex gap-4"
         :class="isUserMessage ? 'flex-row-reverse' : 'flex-row'">
        <Avatar :is-user-message="isUserMessage"/>
        <TransitionScaleIn appear enter-active="transition ease-in-out delay-50 duration-50">
            <div class="relative rounded-lg max-w-lg px-4 py-3 border border-[#E5E7EB] bubble sm:shadow-lg"
                 :class="[
                     hasError ? 'bg-red-50 text-red-500' : isUserMessage ? 'bg-[#4A65BE] text-white' : 'bg-white text-black',
                     isUserMessage ? 'self-end user' : 'self-start assistant',
                 ]"
                 v-html="message.content"/>
        </TransitionScaleIn>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import { ChatRole } from '../../lib/enums/ChatRole'
    import { ChatMessage } from '../../types/Chat'
    import Avatar from './Avatar.vue'
    import TransitionScaleIn from '../transitions/TransitionScaleIn.vue'

    export default defineComponent({
        name: 'ChatMessage',
        components: { TransitionScaleIn, Avatar },
        props: {
            message: { type: Object as PropType<ChatMessage>, required: true },
        },

        setup(props) {

            const isUserMessage = props.message.role === ChatRole.User
            const isAssistantMessage = props.message.role === ChatRole.Assistant
            const hasError = props.message.hasError

            return {
                isUserMessage,
                isAssistantMessage,
                hasError,
            }

        },

    })
</script>

<style scoped>
    .bubble::before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        top: auto;
    }

    .bubble::after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        top: auto;
    }

    .assistant.bubble::before {
        left: -20px;
        right: auto;
        bottom: -1px;
        border: 18px solid;
        border-color: transparent transparent #E5E7EB transparent;
    }

    .assistant.bubble::after {
        left: -18px;
        right: auto;
        bottom: 0;
        border: 18px solid;
        border-color: transparent transparent white transparent;
    }

    .user.bubble::before {
        left: auto;
        right: -20px;
        bottom: -1px;
        border: 18px solid;
        border-color: transparent transparent #E5E7EB transparent;
    }

    .user.bubble::after {
        left: auto;
        right: -18px;
        bottom: 0;
        border: 18px solid;
        border-color: transparent transparent #4A65BE transparent;
    }
</style>

<template>

    <a :href="blok.link.url" target="_self" rel="noopener" class="bg-gray-100 w-full text-center py-6 rounded-lg  font-medium text-lg text-gray-500 md:bg-transparent md:w-auto md:py-0 md:text-gray-600 md:text-base">

        {{ blok.label }}

    </a>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { Url } from '../interfaces/Url'

    interface Block {
        label: string,
        link: Url,
        color: string,
    }

    export default defineComponent({
        name: 'FooterLink',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
    })

</script>

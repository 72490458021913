<template>

    <div v-editable="blok"
         class="w-full"
         :class="[ blok.marginTop, blok.marginBottom, blok.maxWidth ]">

        <Vimeo :data="data"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { VimeoComponent } from '../../../../../client/types/Landing/Components'
    import Vimeo from '../../Dynamic/Components/Vimeo.vue'

    export default defineComponent({
        name: 'DynamicVimeoBlock',
        components: {
            Vimeo,
        },
        props: {
            blok: { type: Object as PropType<VimeoComponent>, required: true },
        },
        setup({ blok }) {

            return {
                data: blok,
            }

        },
    })

</script>

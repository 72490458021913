export enum InputComponent {
    ButtonGroup = 'button_group',
    Text = 'text',
    SpouseChildren = 'spouse_children',
    TickBox = 'tick_box',
    Select = 'select',
    Zipcode = 'zipcode',
    DateOfBirth = 'date_of_birth',
    PhoneNumber = 'phone_number',
    HiddenInput = 'hidden_input',
    SmartyAddress = 'smarty_address',
    DynamicTimes = 'dynamic_times',
    CarYearMakeModel = 'car_ymm',
    AppointmentConfirmation = 'appointment_confirmation',
}

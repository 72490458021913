<template>

    <Text :data="data" class="space-y-4" :content="data.text"/>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { ParagraphComponent } from '../../../../types/Landing/Components'
    import Text from './Text.vue'

    export default defineComponent({
        name: 'Paragraph',
        components: { Text },
        props: {
            data: { type: Object as PropType<ParagraphComponent>, required: true },
        },
    })

</script>

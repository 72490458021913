export class AudioPlayer {

    /**
     * @param {string} id
     */
    constructor(id) {

        this.audioElement = this.createAudioElement(id)
        this.hasPlayed = false

    }

    /**
     * @param {string} id
     */
    createAudioElement(id) {

        const audioElement = document.createElement('audio')

        audioElement.id = id

        const audioContainer = document.getElementById('audio_players')

        audioContainer.appendChild(audioElement)

        return audioElement

    }

    /**
     * @param {?string} audioUrl
     * @param {boolean} loop
     */
    autoplay(audioUrl, loop = false) {

        if (audioUrl === undefined || audioUrl === null) {

            this.audio = null

            return

        }

        this.loadAudio(audioUrl)
        this.registerListeners()
        this.audioElement.loop = loop
        this.play()

    }

    /**
     * @param {string} audioUrl
     */
    loadAudio(audioUrl) {

        this.audioElement.src = audioUrl
        this.hasPlayed = false
        this.audioElement.load()

    }

    play() {

        if (this.hasPlayed) {
            return
        }

        if (this.isPlaying()) {
            return
        }

        const promise = this.audioElement.play()

        promise
            .then(
                () => {

                    this.destroyListeners()
                    this.hasPlayed = true

                },
            ).catch(() => {
                console.log('Audio playback was prevented by the browser')
            })

    }

    async pause() {

        if (this.isPlaying()) {
            await this.audioElement.pause()
        }

    }

    async stop() {

        await this.pause()
        this.audioElement.currentTime = 0

    }

    /**
     * @returns {boolean}
     */
    isPlaying() {
        return !this.audioElement.paused && this.audioElement.currentTime > 0
    }

    registerListeners() {

        document.addEventListener('click', () => this.play())
        document.addEventListener('scroll', () => this.play())
        document.addEventListener('keydown', () => this.play())
        document.addEventListener('touchstart', () => this.play())
        document.addEventListener('touchend', () => this.play())

    }

    destroyListeners() {

        document.removeEventListener('click', () => this.play())
        document.removeEventListener('scroll', () => this.play())
        document.removeEventListener('keydown', () => this.play())
        document.removeEventListener('touchstart', () => this.play())
        document.removeEventListener('touchend', () => this.play())

    }

}

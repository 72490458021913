<template>

    <TransitionScaleIn appear>

        <a :href="phoneNumber.link"
           class="mx-auto text-white shadow-lg relative max-w-md w-full flex animate-throb"
           @click="phoneNumber.onClick">

            <div class="w-full flex items-center lg:space-x-4 justify-center py-4 px-8 button-bg-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:button-ring rounded-lg"
                 :style="{ backgroundColor: backgroundColor }">

                <Icon icon="mdi:phone-in-talk" class="w-12 h-12 lg:w-16 lg:h-16"/>

                <div class="flex flex-col items-center justify-center whitespace-nowrap">

                    <span class="text-2xl lg:text-4xl font-bold">{{ phoneNumber.dashed }}</span>

                    <div class="lg:tracking-widest font-semibold uppercase" v-html="text"/>

                </div>

            </div>

        </a>

    </TransitionScaleIn>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import { Icon } from '@iconify/vue'
    import { Ringba } from '../../lib/Ringba'
    import TransitionScaleIn from '../transitions/TransitionScaleIn.vue'

    export default defineComponent({
        name: 'ClickToCall',
        components: { TransitionScaleIn, Icon },
        props: {
            text: { type: String, default: 'Click to call toll free' },
            backgroundColor: { type: String, default: null },
        },
        setup() {

            const phoneNumber = new Ringba().getPhoneNumber()

            return {
                phoneNumber,
            }

        },
    })

</script>

<template>

    <div class="max-w-2xl mx-auto text-center text-gray-600" data-cy="standard-ty">

        <div class="bg-white p-6 border rounded-lg shadow-md">

            <div>

                <div v-if="data.header.name" class="font-bold mb-6 text-gray-800">
                    <span>{{ data.header.name }}</span>
                    <span>{{ data.header.greeting }}</span>
                </div>

                <div class="mb-4 lg:mb-8">
                    <PhoneAware :text="data.copy.top"/>
                </div>

            </div>

            <ClickToCall :text="data.button"/>

            <div class="mt-4 lg:mt-8 md:flex-row space-y-1 md:space-x-1 md:space-y-0 justify-center">
                <PhoneAware :text="data.copy.bottom"/>
            </div>

        </div>

        <div v-if="!isPast && data.timerActive && timerEndDate" class="mt-8 max-w-md mx-auto">

            <h1 class="uppercase tracking-widest text-gray-800 text-lg font-semibold">
                {{ data.timerTitle }}
            </h1>

            <div class="border-t border-gray-300 my-2"/>

            <FormatCountDown :date="timerEndDate"/>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import FormatCountDown from '../format/FormatCountDown.vue'
    import { StandardTemplate } from '../../types/resources/ThankYouPageResource'
    import { addMinutes, isPast, parseISO } from 'date-fns'
    import ClickToCall from '../buttons/ClickToCall.vue'
    import { TimerType } from '../../lib/enums/TimerType'
    import PhoneAware from '../format/PhoneAware.vue'

    export default defineComponent({
        name: 'Standard',
        components: { PhoneAware, ClickToCall, FormatCountDown },
        props: {
            data: { type: Object as PropType<StandardTemplate['data']>, required: true },
        },
        setup({ data }) {

            let timerEndDate: Date | null = null

            if (data.timerType === TimerType.Fixed) {
                timerEndDate = parseISO(data.timerEndDate)
            }

            if (data.timerType === TimerType.Recurring) {
                timerEndDate = addMinutes(new Date(), data.timerDuration)
            }

            return {
                timerEndDate,
                isPast: timerEndDate ? isPast(timerEndDate) : null,
            }

        },
    })

</script>

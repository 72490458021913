export enum RouteKey {
    Landing = 'landing',
    LandingPreview = 'landing_preview',
    WebForm = 'web_form',
    Chat = 'chat',
    InlineWebForm = 'inline_web_form',
    Loading = 'loading',
    ThankYou = 'thank_you',
    ThankYouPreview = 'thank_you_preview',
}

<template>

    <div data-cy="chat-data-of-birth">

        <ChatInputComponent :component="component"
                            :active-step="activeStep"
                            :disabled="disabled"
                            :prevent-submit="preventSubmit"
                            :input-properties="inputProperties"
                            @toggle-tcpa-modal="toggleTcpaModal"
                            @submit="submit">

            <template #input-icon>

                <CalendarIcon/>

            </template>

        </ChatInputComponent>

    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType, ref } from 'vue'
    import { ChatInputProperties, DateOfBirth } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import ChatInputComponent from './ChatInputComponent.vue'
    import CalendarIcon from '../icons/CalendarIcon.vue'

    export default defineComponent({
        name: 'ChatDateOfBirth',
        components: {
            ChatInputComponent,
            CalendarIcon,
        },
        props: {
            component: { type: Object as PropType<DateOfBirth>, required: true },
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            disabled: { type: Boolean, required: false },
            preventSubmit: { type: Boolean, required: false },
        },
        emits: [ 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            const inputProperties = ref<ChatInputProperties>({
                required: props.component.data.required,
                mask: '##/##/####',
                placeholder: 'MM/DD/YYYY',
                inputMode: 'numeric',
            })

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            /**
             * Check if the date is in DD/MM/YYYY format
             * @param dateString
             */
            function isValidDateFormat(dateString: string): boolean {

                const dateRegex = /^(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])(19|20)\d{2}$/

                return dateRegex.test(dateString)

            }

            function convertYYYYMMDDtoDDMMYYYYwithMasks(dateString: string): string {

                const parts = dateString.split('/')

                if (parts.length === 3) {

                    const y1 = parts[ 0 ].padStart(2, '0')
                    const y2 = parts[ 1 ].padStart(2, '0')
                    const monthday = parts[ 2 ].padStart(4, '0')
                    const month = monthday.substring(0, 2)
                    const day = monthday.substring(2, 4)

                    return `${ month }/${ day }/${ y1 }${ y2 }`

                }

                return dateString

            }

            function submit(value: string) {

                if (isValidDateFormat(value)) {
                    emit('submit', value)
                } else {

                    /**
                     * Check if the value is in YYYY/MM/DD format or YYYY/M/DD format  (value will be in YY/YY/MMDD format due to mask)
                     */
                    const isYYYYMMDDFormat = /^(19|20)\/\d{2}\/\d{3,4}$/.test(value)

                    if (isYYYYMMDDFormat) {

                        submit(convertYYYYMMDDtoDDMMYYYYwithMasks(value))

                        return

                    }

                    /**
                     * At this point we have neither DD/MM/YYYY or YYYY/MM/DD format...
                     * We will continue submitting this value as is and let the backend handle the validation
                     */
                    emit('submit', value)

                }

            }

            return {
                inputProperties,
                submit,
                toggleTcpaModal,
            }

        },
    })
</script>

<template>

    <div class="border px-4 py-3 rounded relative"
         :style="{ backgroundColor: data.backgroundColor, borderColor: data.borderColor }">

        <Text :data="data" :content="data.text"/>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { HighlightComponent } from '../../../../types/Landing/Components'
    import Text from './Text.vue'

    export default defineComponent({
        name: 'Highlight',
        components: { Text },
        props: {
            data: { type: Object as PropType<HighlightComponent>, required: true },
        },
    })

</script>

<template>

    <div class="flex relative md:shadow-2xl rounded-xl overflow-hidden">

        <div class="w-5/12 md:w-2/5 flex-shrink-0">
            <img class="h-full w-full object-cover" :src="sbCdnUrl(blok.image.filename)">
        </div>

        <div class="flex-1 p-4 bg-white h-full flex flex-col">

            <div class="space-y-2">

                <div v-if="domain" class="text-sm leading-5 text-gray-500 uppercase tracking-wider">
                    {{ domain.hostname }}
                </div>

                <div class="text-base md:text-xl leading-6 font-semibold text-gray-900 line-clamp-3 md:line-clamp-2">

                    <a :href="blok.url.url" target="_blank">
                        {{ blok.title }}
                        <span class="absolute inset-0"/>
                    </a>

                </div>

                <div class="text-sm leading-6 text-gray-500 line-clamp-3 md:line-clamp-2">
                    {{ blok.description }}
                </div>

            </div>

        </div>

    </div>

</template>

<script lang="ts">

    import { computed, defineComponent, PropType } from 'vue'
    import { Asset } from '../../interfaces/Asset'
    import { Url } from '../../interfaces/Url'
    import { sbCdnUrl } from '../../../../../lib/Helpers'

    interface Block {
        image: Asset,
        title: string,
        description: string,
        url: Url,
    }

    export default defineComponent({
        name: 'SlimMediaCard',
        props: {
            blok: { type: Object as PropType<Block>, required: true },
        },
        setup(props) {

            const domain = computed(() => props.blok.url?.url?.length ? new URL(props.blok.url.url) : null)

            return {
                domain,
                sbCdnUrl,
            }

        },
    })

</script>

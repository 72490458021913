<template>

    <div data-cy="chat-text">

        <ChatInputComponent :component="component"
                            :active-step="activeStep"
                            :disabled="disabled"
                            :prevent-submit="preventSubmit"
                            :input-properties="inputProperties"
                            @toggle-tcpa-modal="toggleTcpaModal"
                            @submit="submit"/>

    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType, ref } from 'vue'
    import { Text } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import ChatInputComponent from './ChatInputComponent.vue'
    import { ChatInputProperties } from '../../types/ChatComponents'

    export default defineComponent({
        name: 'ChatText',
        components: {
            ChatInputComponent,
        },
        props: {
            component: { type: Object as PropType<Text>, required: true },
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            disabled: { type: Boolean, required: false },
            preventSubmit: { type: Boolean, required: false },
        },
        emits: [ 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            const inputProperties = ref<ChatInputProperties>({
                placeholder: props.component.data.inputPlaceholder,
                required: props.component.data.required,
                inputMode: 'text',
            })

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            function submit(value: string) {
                emit('submit', value)
            }

            return {
                inputProperties,
                submit,
                toggleTcpaModal,
            }

        },
    })
</script>

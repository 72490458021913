export class TrustedForm {

    private fieldName = 'xxTrustedFormCertUrl'

    public createScript() {

        const existingScripts = document.getElementsByTagName('script')[ 0 ]
        const trustedFormScript: HTMLScriptElement = document.createElement('script')

        trustedFormScript.type = 'text/javascript'
        trustedFormScript.async = true
        trustedFormScript.src = this.buildScriptSource()

        if (existingScripts.parentNode) {
            existingScripts.parentNode.insertBefore(trustedFormScript, existingScripts)
        } else {

            // Fallback to appending to the body
            document.body.appendChild(trustedFormScript)

        }

    }

    public getTrustedFormUrl(): string | null {

        const certificateInput: HTMLInputElement | null = document.querySelector(`input[name=${ this.fieldName }]`)

        if (certificateInput) {
            return certificateInput.value
        }

        return null

    }

    private buildScriptSource(): string {

        const timestamp: number = new Date().getTime()
        const randomNumber: number = Math.random()

        return `//api.trustedform.com/trustedform.js?provide_referrer=${ false }&field=${ this.fieldName }&l=${ timestamp }${ randomNumber }`

    }

}

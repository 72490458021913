<template>

    <div class="mb-4">

        <div class="mb-4">

            <div class="flex justify-between mb-2">

                <div class="primary-text font-medium">
                    {{ title }}
                </div>

                <button type="button" @click="$emit('remove')">
                    <Icon icon="mdi:close" class="w-5 h-5 text-gray-700"/>
                </button>

            </div>

            <div class="mb-1 font-medium text-gray-700">
                Gender:
            </div>

            <div class="flex">

                <PushButton :theme="PushButtonTheme.White"
                            :state="gender === 'M' ? PushButtonState.Selected : PushButtonState.Active"
                            class="flex-1 text-gray-500 uppercase rounded-r-none focus:z-20"
                            :class="{ 'primary-border z-10 font-bold': gender === 'M' }"
                            @click="$emit('update:gender', 'M')">

                    <div class="w-full text-center">
                        M
                    </div>

                </PushButton>

                <PushButton :theme="PushButtonTheme.White"
                            :state="gender === 'F' ? PushButtonState.Selected : PushButtonState.Active"
                            class="flex-1 -ml-px text-gray-500 uppercase rounded-l-none focus:z-20"
                            :class="{ 'primary-border z-10 font-bold': gender === 'F' }"
                            @click="$emit('update:gender', 'F')">

                    <div class="w-full text-center">
                        F
                    </div>

                </PushButton>

            </div>

        </div>

        <div class="mb-4">

            <div class="mb-1 font-medium text-gray-700">
                Tobacco Use:
            </div>

            <div class="flex">

                <PushButton :theme="PushButtonTheme.White"
                            :state="tobaccoUse === 'N' ? PushButtonState.Selected : PushButtonState.Active"
                            class="flex-1 text-gray-500 uppercase rounded-r-none focus:z-20"
                            :class="{ 'primary-border z-10 font-bold': tobaccoUse === 'N' }"
                            @click="$emit('update:tobacco-use', 'N')">

                    <div class="w-full text-center">
                        N
                    </div>

                </PushButton>

                <PushButton :theme="PushButtonTheme.White"
                            :state="tobaccoUse === 'Y' ? PushButtonState.Selected : PushButtonState.Active"
                            class="flex-1 -ml-px text-gray-500 uppercase rounded-l-none focus:z-20"
                            :class="{ 'primary-border z-10 font-bold': tobaccoUse === 'Y' }"
                            @click="$emit('update:tobacco-use', 'Y')">

                    <div class="w-full text-center">
                        Y
                    </div>

                </PushButton>

            </div>

        </div>

        <div>

            <div class="mb-1 font-medium text-gray-700">
                Date of Birth:
            </div>

            <StepDateOfBirthText ref="birthdayElement"
                                 :component="{
                                     type: InputComponent.DateOfBirth,
                                     data: {
                                         required: true,
                                         asTextInput: true,
                                         placeholder: 'MM/DD/YYYY',
                                     },
                                 }"
                                 :birthday="birthday"
                                 @changed="birthdayChanged"/>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType, ref } from 'vue'
    import PushButton from '../buttons/PushButton.vue'
    import { PushButtonState, PushButtonTheme } from '../../lib/enums/PushButton'
    import StepDateOfBirthText from './StepDateOfBirthText.vue'
    import { InputComponent } from '../../lib/enums/components/InputComponent'
    import { SpouseChildrenItemType } from '../../types/Components'
    import { Icon } from '@iconify/vue'

    export default defineComponent({
        name: 'StepSpouseChildrenItem',
        components: { PushButton, StepDateOfBirthText, Icon },
        props: {
            title: { type: String, required: true },
            gender: { type: String as PropType<SpouseChildrenItemType[ 'gender' ]>, required: true },
            tobaccoUse: { type: String as PropType<SpouseChildrenItemType[ 'tobacco_use' ]>, required: true },
            birthday: { type: String as PropType<SpouseChildrenItemType[ 'birthday' ]>, required: true },
        },
        emits: [ 'update:gender', 'update:tobacco-use', 'update:birthday', 'remove' ],
        setup(_, { emit }) {

            const birthdayElement = ref<InstanceType<typeof StepDateOfBirthText>>()

            function birthdayChanged(): void {
                emit('update:birthday', birthdayElement.value?.isValid() ? birthdayElement.value.getValue() : undefined)
            }

            return {
                birthdayElement,
                birthdayChanged,
                PushButtonState,
                PushButtonTheme,
                InputComponent,
            }

        },
    })

</script>

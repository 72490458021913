<template>

    <div class="flex flex-col items-center w-full" :class="{ '-mt-16': !isThankYouPage }">

        <div v-for="(component, index) of data.components"
             :key="index"
             class="w-full"
             :class="[ component.data.marginTop, component.data.marginBottom, component.data.maxWidth ]">

            <Component :is="components[ component.type ]" :data="component.data"/>

        </div>

    </div>

</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import { DynamicLandingPage } from '../../types/LandingPage'
    import Title from './Dynamic/Components/Title.vue'
    import Paragraph from './Dynamic/Components/Paragraph.vue'
    import Image from './Dynamic/Components/Image.vue'
    import { LandingDynamicComponent } from '../../lib/enums/LandingDynamicComponent'
    import Button from './Dynamic/Components/Button.vue'
    import ButtonGroup from './Dynamic/Components/ButtonGroup.vue'
    import Vimeo from './Dynamic/Components/Vimeo.vue'
    import Highlight from './Dynamic/Components/Highlight.vue'
    import ClickToCall from './Dynamic/Components/ClickToCall.vue'
    import RingbaTagOverride from './Dynamic/Components/RingbaTagOverride.vue'
    import InlineWebForm from './Dynamic/Components/InlineWebForm.vue'
    import { useRoute } from 'vue-router'
    import { RouteKey } from '../../lib/enums/RouteKey'

    type Components =
        typeof Title
        | typeof Paragraph
        | typeof Image
        | typeof Button
        | typeof ButtonGroup
        | typeof Vimeo
        | typeof Highlight
        | typeof ClickToCall
        | typeof RingbaTagOverride
        | typeof InlineWebForm

    const components: Record<LandingDynamicComponent, Components> = {
        [ LandingDynamicComponent.Title ]: Title,
        [ LandingDynamicComponent.Paragraph ]: Paragraph,
        [ LandingDynamicComponent.Image ]: Image,
        [ LandingDynamicComponent.Button ]: Button,
        [ LandingDynamicComponent.ButtonGroup ]: ButtonGroup,
        [ LandingDynamicComponent.Vimeo ]: Vimeo,
        [ LandingDynamicComponent.Highlight ]: Highlight,
        [ LandingDynamicComponent.ClickToCall ]: ClickToCall,
        [ LandingDynamicComponent.RingbaTagOverride ]: RingbaTagOverride,
        [ LandingDynamicComponent.InlineWebForm ]: InlineWebForm,
    }

    export default defineComponent({
        name: 'Dynamic',
        props: {
            data: { type: Object as PropType<DynamicLandingPage['data']>, required: true },
        },
        setup() {

            const route = useRoute()
            const isThankYouPage = route.name === RouteKey.ThankYou

            if (isThankYouPage) {
                route.meta.center.value = false
            }

            return {
                isThankYouPage,
                components,
            }

        },
    })

</script>

<template>

    <ModalBase @close="destroy">

        <slot/>

    </ModalBase>

</template>

<script lang="ts">

    import { defineComponent } from 'vue'
    import ModalBase from './ModalBase.vue'

    export default defineComponent({
        name: 'ModalTcpa',
        components: { ModalBase },
        emits: {
            close: () => true,
        },
        setup(props, { emit }) {

            function destroy(): void {
                emit('close')
            }

            return {
                destroy,
            }

        },
    })

</script>

<template>

    <div class="flex items-center justify-center space-x-4 pb-4">

        <FormSelectNative :ref="element => references.month = element"
                          v-model="fields.month"
                          placeholder="Month"
                          :options="monthOptions"/>

        <FormSelectNative :ref="element => references.day = element"
                          v-model="fields.day"
                          placeholder="Day"
                          :options="dayOptions"/>

        <FormSelectNative :ref="element => references.year = element"
                          v-model="fields.year"
                          placeholder="Year"
                          :options="yearOptions"/>

    </div>

</template>

<script lang="ts">

    import FormSelectNative, { SelectOption } from '../form/FormSelectNative.vue'
    import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue'
    import { isBlank, isFilled, range, restoreState, toDecimalAge } from '../../lib/Helpers'
    import { DateOfBirth } from '../../types/Components'
    import { format, getDate, getDaysInMonth, getMonth, getYear, isValid as isValidDate, parse as parseDate } from 'date-fns'
    import { enUS } from 'date-fns/locale'

    function options(ranges: number[] | string[]): SelectOption[] {

        return ranges.map((display, index) => ({
            name: display,
            value: typeof display === 'string' ? index + 1 : display,
        }))

    }

    export default defineComponent({
        name: 'StepDateOfBirth',
        components: { FormSelectNative },
        props: {
            component: { type: Object as PropType<DateOfBirth>, required: true },
        },
        setup({ component }) {

            const dateFormat = 'yyyy-MM-dd'
            const references: Record<string, InstanceType<typeof FormSelectNative>> = {}
            const fields = reactive<Record<string, string | number>>({ year: '', day: '', month: '' })
            const days = range(1, 31)
            const years = range(1920, 1995).reverse()
            const yearOptions = options(years)
            const monthOptions = options(range(0, 11).map(month => enUS.localize?.month(month)))

            const dayOptions = computed(() => {

                if (fields.month && fields.year) {

                    const date = new Date(+fields.year, +fields.month - 1)

                    return options(
                        range(1, getDaysInMonth(date)),
                    )

                }

                return options(days)

            })

            const incomplete = computed<string[]>(() => Object.keys(fields).filter(value => isBlank(fields[ value ])))

            onMounted(() => {

                const currentDate = ref()

                restoreState(currentDate, [ 'data', component.data.name ])

                if (currentDate.value) {

                    const date = parseDate(currentDate.value, dateFormat, new Date())

                    if (date) {

                        fields.year = getYear(date)
                        fields.month = getMonth(date) + 1
                        fields.day = getDate(date)

                    }

                }

            })

            function getParsedDate(): Date | null {

                const [ year, month, day ] = [ fields.year, fields.month, fields.day ].filter(isFilled)

                if (year && month && day) {
                    return parseDate(`${ year }-${ month }-${ day }`, dateFormat, new Date())
                }

                return null

            }

            async function fill(formData: Record<string, unknown>): Promise<void> {

                const date = getParsedDate()

                if (date) {

                    formData[ component.data.name ] = format(date, dateFormat)
                    formData[ `${ component.data.name }_age` ] = toDecimalAge(date)

                }

            }

            function focus(): void {

                for (const name of incomplete.value) {
                    references[ name ].input?.focus()
                }

            }

            function isValid(): boolean {

                const date = getParsedDate()

                if (date) {
                    return isValidDate(date)
                }

                return component.data.required === false

            }

            return {
                references,
                fields,
                dayOptions,
                monthOptions,
                yearOptions,
                fill,
                focus,
                isValid,
            }

        },
    })

</script>

import { Hook } from '../lib/enums/Hook'
import { ThankYouPageResource } from '../types/resources/ThankYouPageResource'
import { HookToFunctionMap } from './DomainHooks'

type HookObject = { hook: Hook, callback: (...parameters: unknown[]) => void }

export type Hooks = HookObject[]

export class HookRunner {

    private hooks: Hooks = window.__HOOKS ?? []

    public run(action: Hook.AfterFormSubmission, formData: Record<string, unknown>, metadata: Record<string, unknown>): void
    public run(action: Hook.BeforeFormSubmission, formData: Record<string, unknown>, metadata: Record<string, unknown>): void
    public run(action: Hook.AfterStepSubmission, formData: Record<string, unknown>, metadata: Record<string, unknown>): void
    public run(action: Hook.BeforeStepSubmission, formData: Record<string, unknown>, metadata: Record<string, unknown>): void
    public run(action: Hook.AfterEnterThankYouPage, metadata: ThankYouPageResource['data']): void
    public run(action: Hook.OnWebFormStart, metadata: Record<string, unknown>): void
    public run(action: Hook.VueReady, metadata: Record<string, unknown>): void
    public run(action: Hook.DOMContentLoaded, metadata: Record<string, unknown>): void
    public run<T extends unknown[]>(action: Hook, ...parameters: T) {

        // Find the related local hook function for the action and run it before the CRM hook is run
        const hookFunction = HookToFunctionMap[ action ]

        if (hookFunction) {

            try {
                hookFunction(...parameters)
            } catch (error) {
                console.log(error)
            }

        }

        for (const { callback } of this.hooks.filter(({ hook }) => hook === action)) {

            try {
                callback(...parameters)
            } catch (error) {
                console.log(error)
            }

        }

    }

}

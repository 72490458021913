<template>

    <div data-cy="zip-code-input">

        <ChatInputComponent :component="component"
                            :active-step="activeStep"
                            :disabled="disabled"
                            :prevent-submit="preventSubmit"
                            :input-properties="inputProperties"
                            @toggle-tcpa-modal="toggleTcpaModal"
                            @submit="submit">

            <template #input-icon>

                <GlobeIcon/>

            </template>

        </ChatInputComponent>

    </div>

</template>

<script lang="ts">
    import { defineComponent, PropType, ref } from 'vue'
    import { ZipCode } from '../../types/ChatComponents'
    import { WebFormStep } from '../../types/State'
    import ChatInputComponent from './ChatInputComponent.vue'
    import GlobeIcon from '../icons/GlobeIcon.vue'
    import { ChatInputProperties } from '../../types/ChatComponents'

    export default defineComponent({
        name: 'ChatZipCode',
        components: {
            ChatInputComponent,
            GlobeIcon,
        },
        props: {
            component: { type: Object as PropType<ZipCode>, required: true },
            activeStep: { type: Object as PropType<WebFormStep>, required: true },
            disabled: { type: Boolean, required: false },
            preventSubmit: { type: Boolean, required: false },
        },
        emits: [ 'submit', 'toggle-tcpa-modal' ],
        setup(props, { emit }) {

            const inputProperties = ref<ChatInputProperties>({
                placeholder: props.component.data.inputPlaceholder,
                required: props.component.data.required,
                mask: '#####',
                inputMode: 'numeric',
                name: 'zip',
                autocomplete: 'postal-code',
            })

            function toggleTcpaModal() {
                emit('toggle-tcpa-modal')
            }

            function submit(value: string) {
                emit('submit', value)
            }

            return {
                inputProperties,
                submit,
                toggleTcpaModal,
            }

        },
    })
</script>

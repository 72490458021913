<template>

    <label>

        <div class="mb-2">

            <label class="font-medium text-gray-700">
                {{ component.data.label }}
            </label>

        </div>

        <select ref="inputReference"
                v-model="model"
                :name="component.data.name"
                class="
                    shadow-sm focus:ring-indigo-500 focus:border-indigo-500
                    block w-full border-gray-300 rounded-md py-4
                    text-gray-500 text-base
                ">

            <option :value="undefined" disabled selected>
                {{ component.data.placeholder }}
            </option>

            <option v-for="(option, key) of component.data.options" :key="key" :value="option.value">
                {{ option.label }}
            </option>

        </select>

    </label>

</template>

<script lang="ts">

    import { defineComponent, PropType, ref } from 'vue'
    import { TickBox } from '../../types/Components'

    export default defineComponent({
        name: 'StepSelect',
        props: {
            component: { type: Object as PropType<TickBox>, required: true },
        },
        setup({ component }) {

            const inputReference = ref<HTMLSelectElement>()
            const model = ref<string | undefined>(undefined)

            async function fill(formData: Record<string, unknown>): Promise<void> {
                formData[ component.data.name ] = model.value
            }

            function focus(): void {
                inputReference.value?.focus()
            }

            function isValid(): boolean {

                if (model.value) {
                    return true
                }

                return component.data.required === false

            }

            return {
                inputReference,
                model,
                fill,
                focus,
                isValid,
            }

        },
    })

</script>

export enum FlowCircleState {
    Complete = 'complete',
    Active = 'active',
    Incomplete = 'incomplete',
}

export enum FlowCircleSize {
    Base = 'base',
    Large = 'large',
}
